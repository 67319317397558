import React from 'react'

interface FlyerBuildetPaginationProps {
	isActive: boolean
	children?: React.ReactNode
	onClick: () => void
}
const FlyerBuildetPagination = ({ isActive = false, onClick, children }: FlyerBuildetPaginationProps) => {
	return (
		<div className={`flex cursor-pointer items-center justify-center w-8 h-8 text-sm rounded-full ${isActive ? 'bg-msc-blue text-white' : 'text-msc-blue'}`} onClick={onClick}>
			{children}
		</div>
	)
}

export { FlyerBuildetPagination }
