import React from 'react'
import clsx from 'clsx'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

export interface TagProps {
	children?: React.ReactNode
	variant?: 'countDown' | 'standard'
	className?: string
	disabled?: boolean
	active?: boolean
	value?: string
	label?: string
	onClick?: (...args: any) => void
}

const Tag = ({ children, variant = 'standard', className, disabled, active, onClick }: TagProps) => {
	const sitecoreStore = useSitecoreState((state) => state)

	const handleClick = () => {
		if (onClick && !disabled) {
			onClick()
		}
	}

	const classNames = clsx(
		'rounded flex flex-col justify-center px-2 h-8 transition-all',
		{
			'bg-orange text-neutral-50 text-xsmall': variant === 'countDown',
			'bg-background text-light-purple': variant === 'standard',
			'cursor-pointer': onClick && !disabled,
			'bg-secondary text-white': active,
			'cursor-not-allowed opacity-50': disabled,
		},
		className
	)
	const timeLeft = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_TIME_LEFT', 'Time left:')

	return (
		<div className={classNames} onClick={handleClick}>
			{variant === 'countDown' && <span className="text-xxs">{timeLeft}</span>}
			<p className={clsx({ 'text-xs': variant === 'countDown', 'text-sm': variant !== 'countDown' }, 'font-semibold')}>{children}</p>
		</div>
	)
}

export { Tag }
