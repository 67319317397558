type ActionModule = Record<string, (...args: any[]) => any>
type ProxyType = <T extends object>(targetObject: T) => T
type Service = { modules: ActionModule[]; proxies: ProxyType[] }

export function createProxiedActions<T extends ActionModule>(service: Service): T {
	if (!service.modules || service.modules.length === 0) return {} as T

	return service.modules.reduce((acc, module) => {
		const proxiedModule = createProxyFunction(module, service.proxies)
		return { ...acc, ...proxiedModule }
	}, {}) as T
}

function createProxyFunction(module: ActionModule, proxies: ProxyType[]) {
	let result: ActionModule = module
	proxies.forEach((proxy) => (result = proxy(result)))
	return result
}
