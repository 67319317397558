interface NotificationLabel {
	variant?: 'over'
	children: number
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const NotificationLabel = ({ variant, children }: NotificationLabel) => {
	return (
		<div className={`flex items-center justify-center bg-primary rounded-lg w-6 h-5 ${variant === 'over' ? 'absolute top-0 right-0 translate-x-2/3 -translate-y-1/2' : ''}`}>
			<p className="text-sm text-white">{children}</p>
		</div>
	)
}
