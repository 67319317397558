import { CurrentMarket } from '@/features/markets/models/market'

/**
 * Check if a feature is enabled for the current market.
 * @param configuration Comma separated list of country codes, with optional negation. Example: "ITA,!ESP,DEU,FRA,!USA"
 * @param market The Current Market
 */
export const _isFeatureEnabled = (configuration: string, currentMarket: CurrentMarket): boolean => {
	// disabled by default
	if (configuration === '') return false

	// enabled for all markets
	if (configuration.toUpperCase() === 'ALL') return true

	const isBlackList = configuration.trim().startsWith('NOT=')
	const normalizedConfiguration = configuration.replace('NOT=', '')

	const markets = normalizedConfiguration.toUpperCase().split(',')
	const isCurrentMarketInConfiguration = markets.find((market) => market === currentMarket.code.toUpperCase()) !== undefined

	return (!isBlackList && isCurrentMarketInConfiguration) || (isBlackList && !isCurrentMarketInConfiguration)
}
