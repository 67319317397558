import React from 'react'

interface PromoIconInterface {
	color?: string
}

export const PromoIcon = ({ color = '#000033' }: PromoIconInterface) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g mask="url(#mask0_4073_1801)">
				<path d="M13.075 21.4C12.675 21.8 12.2 22 11.65 22C11.1 22 10.625 21.8 10.225 21.4L3.075 14.25C2.69167 13.8667 2.5 13.3958 2.5 12.8375C2.5 12.2792 2.69167 11.8083 3.075 11.425L11.875 2.6C12.0583 2.41667 12.275 2.27083 12.525 2.1625C12.775 2.05417 13.0417 2 13.325 2H20.475C21.025 2 21.4958 2.19583 21.8875 2.5875C22.2792 2.97917 22.475 3.45 22.475 4V11.15C22.475 11.4333 22.4208 11.7 22.3125 11.95C22.2042 12.2 22.0583 12.4167 21.875 12.6L13.075 21.4ZM17.975 8C18.3917 8 18.7458 7.85417 19.0375 7.5625C19.3292 7.27083 19.475 6.91667 19.475 6.5C19.475 6.08333 19.3292 5.72917 19.0375 5.4375C18.7458 5.14583 18.3917 5 17.975 5C17.5583 5 17.2042 5.14583 16.9125 5.4375C16.6208 5.72917 16.475 6.08333 16.475 6.5C16.475 6.91667 16.6208 7.27083 16.9125 7.5625C17.2042 7.85417 17.5583 8 17.975 8Z" fill={color} />
			</g>
		</svg>
	)
}
