import React from 'react'

interface CalendarRangeIconInterface {
	color?: string
}

export const CalendarRangeIcon = ({ color = '#000033' }: CalendarRangeIconInterface) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.75 7.5H5.25V9H6.75V7.5ZM9.75 7.5H8.25V9H9.75V7.5ZM12.75 7.5H11.25V9H12.75V7.5ZM14.25 2.25H13.5V0.75H12V2.25H6V0.75H4.5V2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C14.6478 15.75 15.0294 15.592 15.3107 15.3107C15.592 15.0294 15.75 14.6478 15.75 14.25V3.75C15.75 3.35217 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25ZM14.25 14.25H3.75V6H14.25V14.25Z" fill={color} />
		</svg>
	)
}
