import React from 'react'

interface DiscountIconInterface {
	color?: string
}

export const DiscountIcon = ({ color = '#4892E0' }: DiscountIconInterface) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.4943 8.49426V3.50574H15.5163L11.9788 0L8.49426 3.50574H3.50574V8.49426L0 11.9788L3.50574 15.5163V20.4943H8.49426L11.9788 24L15.5163 20.4943H20.4943V15.5163L24 11.9788L20.4943 8.49426ZM8.62136 6.7361C9.65931 6.7361 10.496 7.57282 10.496 8.62136C10.496 9.11855 10.2985 9.59538 9.94695 9.94695C9.59538 10.2985 9.11855 10.496 8.62136 10.496C7.57282 10.496 6.7361 9.65931 6.7361 8.62136C6.7361 7.57282 7.57282 6.7361 8.62136 6.7361ZM15.3892 17.2745C14.3513 17.2745 13.5146 16.4272 13.5146 15.3892C13.5146 14.892 13.7121 14.4152 14.0636 14.0636C14.4152 13.7121 14.892 13.5146 15.3892 13.5146C16.4272 13.5146 17.2745 14.3513 17.2745 15.3892C17.2745 15.8892 17.0759 16.3688 16.7223 16.7223C16.3688 17.0759 15.8892 17.2745 15.3892 17.2745ZM8.27184 17.3063L6.68314 15.7176L15.7176 6.68314L17.3063 8.27184L8.27184 17.3063Z" fill={color} />
		</svg>
	)
}
