'use client'

import { GroupIcon, HelpIcon, HomeIcon, LoggedNavigationButton, PromoIcon, TicketIcon, UserIcon } from '@/components/uiComponents'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'

export const LoggedNavigation = () => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const provideUrl = useUrlProvider()

	// translation labels
	const sitecoreStore = useSitecoreState((state) => state)

	const individualReservationLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-INDIVIDUAL_BOOKING', 'Individiual Booking')
	const allPromotionsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_ALL_PROMOTIONS', 'All Promotions')
	const GroupReservationLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-GROUP_BOOKING', 'Group Reservation')
	const manageBookingLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-BOOKING_SEARCH', 'Manage Booking')
	const overviewLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-QUICK_OVERVIEW', 'Overview')
	const needHelpLabel = sitecoreStore.getLabel(TranslationBundles.CUSTOMER_SUPPORT, 'MSC-CUSTOMERSUPPORT_HELP_CENTER', 'Need help?')

	// Links
	const allPromotionsLink = provideUrl('all-promotions')
	const idividualReservationLink = provideUrl('search-cruise', { noSearch: true })
	const groupBookingLink = provideUrl('group-booking')
	const manageBookingLink = provideUrl('search-booking')
	const helpCenterLink = provideUrl('help-center')

	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendVerticalMenuCtaGA4Event = (labelClicked: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'verticalmenu',
			eventName: 'vertical_menu',
			...staticParams,
			label_clicked: labelClicked,
		})
	}

	return (
		<div className="xl:min-w-24 2xl:min-w-32 min-h-full bg-white overflow-x-scroll xl:overflow-x-hidden">
			<div className="flex flex-row xl:flex-col xl:fixed justify-between xl:top-18 xl:left-0 w-full xl:w-24 2xl:w-32 xl:h-[calc(100%-72px)] bg-white xl:py-4 2xl:py-8">
				<LoggedNavigationButton icon={<HomeIcon />} link="#" isActive={true} labelClicked="Menu" sendVerticalMenuCtaGA4Event={sendVerticalMenuCtaGA4Event}>
					{overviewLabel}
				</LoggedNavigationButton>
				<LoggedNavigationButton icon={<PromoIcon />} link={allPromotionsLink} labelClicked="All Promotions" sendVerticalMenuCtaGA4Event={sendVerticalMenuCtaGA4Event}>
					{allPromotionsLabel}
				</LoggedNavigationButton>
				<LoggedNavigationButton icon={<UserIcon />} link={idividualReservationLink} labelClicked="Individual Reservation" sendVerticalMenuCtaGA4Event={sendVerticalMenuCtaGA4Event}>
					{individualReservationLabel}
				</LoggedNavigationButton>
				{user?.authorizations.manageGroups && (
					<LoggedNavigationButton icon={<GroupIcon />} link={groupBookingLink} labelClicked="Group Reservation" sendVerticalMenuCtaGA4Event={sendVerticalMenuCtaGA4Event}>
						{GroupReservationLabel}
					</LoggedNavigationButton>
				)}
				<LoggedNavigationButton icon={<TicketIcon />} link={manageBookingLink} labelClicked="Manage Booking" sendVerticalMenuCtaGA4Event={sendVerticalMenuCtaGA4Event}>
					{manageBookingLabel}
				</LoggedNavigationButton>
				<LoggedNavigationButton icon={<HelpIcon />} link={helpCenterLink} labelClicked="Need Help" customClassNames="xl:mt-auto" sendVerticalMenuCtaGA4Event={sendVerticalMenuCtaGA4Event}>
					{needHelpLabel}
				</LoggedNavigationButton>
			</div>
		</div>
	)
}
