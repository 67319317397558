'use client'

import React from 'react'

interface TabButtonProps {
	variant?: 'full'
	isActive: boolean
	onClick: () => void
	children: React.ReactNode
}

export const TabButton = ({ variant, isActive, onClick, children }: TabButtonProps) => {
	return (
		<button onClick={onClick} className={`${variant === 'full' ? 'flex-1 text-center' : ''} text-msc-blue border-b-3 ${isActive ? ' border-msc-blue' : 'border-transparent hover:text-gray-600 hover:border-gray-300'} pb-2`}>
			<div className={`flex ${variant === 'full' ? 'justify-center text-sm font-semibold whitespace-nowrap' : ''} items-center gap-2`}>{children}</div>
		</button>
	)
}
