import HydrationWrapper from '@/components/atoms/HydrationWrapper'
import { HeaderButtons, MobileHeader, NavMenu, SuperUserBar } from '@/components/molecules/_exporter'
import AuthService from '@/features/auth/auth-service'
import { createConfigForHeaderMenu } from '@/features/caching/lib/tanstack'
import MarketService from '@/features/markets/markets-service'
import { getQueryClient } from '@/utils/lib/get-query-client'
import { dehydrate } from '@tanstack/react-query'
import { CountryFlag } from '../atoms/CountryFlag'
import { MscLogo } from '../uiComponents'

export async function Header() {
	const session = await AuthService.session()
	const market = await MarketService.getCurrentMarket()
	const isSuperUser = session?.user?.surname === 'superuser' && session.user?.name?.includes('@')

	// Prefetching the header menu only if the user is not logged (in order to avoid flickering)
	// NextJS here uses Redis (or local) cache
	const queryClient = getQueryClient()
	if (!session?.user) {
		await queryClient.prefetchQuery(createConfigForHeaderMenu({ cache: true }))
	}
	const countryFlag = <CountryFlag countryCode={market.countryCode} />

	return (
		<HydrationWrapper state={dehydrate(queryClient)}>
			<div className={'fixed z-30 bg-msc-blue w-full'}>
				{isSuperUser && <SuperUserBar />}
				<div className="flex h-72 justify-between items-center lg:px-14 text-white desktop-sm:px-6 desktop-xsm:px-3">
					<div className="flex justify-center items-center gap-16 desktop-xsm:gap-10 desktop-l:gap-40">
						<MscLogo />
					</div>
					<NavMenu />
					<HeaderButtons countryFlag={countryFlag} />
					<MobileHeader countryFlag={countryFlag} />
				</div>
			</div>
		</HydrationWrapper>
	)
}
