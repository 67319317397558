'use client'

import { KeysOverride } from '@/features/sitecore/models/keys-override'
import Select from 'react-select'
import { customStyles } from '@/components/atoms/SelectWrapper'
import { DevTools } from '../configs/devToolsConfigs'
import { useDevToolsState } from '../store/useDevToolsState'

export const SitecoreKeysOverride = () => {
	const options = Object.keys(KeysOverride).map((name) => ({ value: name, label: name }))
	const store = useDevToolsState()

	const changeValue = async (value: string | undefined) => {
		if (!value) return
		store.set(DevTools.SITECORE_KEYS_OVERRIDE, value)
	}

	return (
		<div className="flex gap-3">
			<Select options={options} placeholder="Override the sitecore values" onChange={(option) => changeValue(option?.value)} styles={customStyles} defaultValue={options.find((option) => option.value === store.sitecoreKeysOverride)} />
		</div>
	)
}
