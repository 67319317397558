import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as ElasticFilterActions from './actions/elastic-filter-actions'

const ElasticFilterService = createProxiedActions<typeof ElasticFilterActions>({
	modules: [
		ElasticFilterActions,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default ElasticFilterService
