import React from 'react'

interface ExpandCircleRightIconInterface {
	color?: string
}

export const ExpandCircleRightIcon = ({ color = '#000033' }: ExpandCircleRightIconInterface) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_3240_9180" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
				<rect x="0.333984" width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3240_9180)">
				<path d="M13.009 12L9.93398 15.075L11.334 16.5L15.834 12L11.334 7.5L9.93398 8.925L13.009 12ZM12.334 22C10.9507 22 9.65065 21.7375 8.43398 21.2125C7.21732 20.6875 6.15898 19.975 5.25898 19.075C4.35898 18.175 3.64648 17.1167 3.12148 15.9C2.59648 14.6833 2.33398 13.3833 2.33398 12C2.33398 10.6167 2.59648 9.31667 3.12148 8.1C3.64648 6.88333 4.35898 5.825 5.25898 4.925C6.15898 4.025 7.21732 3.3125 8.43398 2.7875C9.65065 2.2625 10.9507 2 12.334 2C13.7173 2 15.0173 2.2625 16.234 2.7875C17.4507 3.3125 18.509 4.025 19.409 4.925C20.309 5.825 21.0215 6.88333 21.5465 8.1C22.0715 9.31667 22.334 10.6167 22.334 12C22.334 13.3833 22.0715 14.6833 21.5465 15.9C21.0215 17.1167 20.309 18.175 19.409 19.075C18.509 19.975 17.4507 20.6875 16.234 21.2125C15.0173 21.7375 13.7173 22 12.334 22ZM12.334 20C14.5673 20 16.459 19.225 18.009 17.675C19.559 16.125 20.334 14.2333 20.334 12C20.334 9.76667 19.559 7.875 18.009 6.325C16.459 4.775 14.5673 4 12.334 4C10.1007 4 8.20898 4.775 6.65898 6.325C5.10898 7.875 4.33398 9.76667 4.33398 12C4.33398 14.2333 5.10898 16.125 6.65898 17.675C8.20898 19.225 10.1007 20 12.334 20Z" fill={color} />
			</g>
		</svg>
	)
}
