import { Bundle } from '../actions/services/sitecore-bundle'
import { KeysOverride } from '../models/keys-override'

const longtext = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus viverra, est quis gravida facilisis, lorem neque pulvinar risus, eget pellentesque neque metus eu sapien. Sed eget lobortis nibh. Nam volutpat ullamcorper ornare. Nulla id dictum justo. Sed elementum diam eu ornare mattis. Aenean aliquet tellus ac ipsum rutrum rutrum. In libero tellus, dignissim sit amet pellentesque quis, laoreet quis magna. Nulla tincidunt elementum urna. Fusce quam lorem, lobortis id velit vitae, laoreet feugiat risus. Nunc ut nunc odio. Integer mattis eleifend lobortis. Fusce porta porttitor ante, nec vehicula felis consequat at.'

export function getOverridedLabelText(sitecoreKeysOverride: string, label: string): string | null {
	if (sitecoreKeysOverride === KeysOverride.KEYNAME) return `???${label}???`
	if (sitecoreKeysOverride === KeysOverride.LONGTEXT) return longtext
	if (sitecoreKeysOverride === KeysOverride.EMPTY) return ''
	return null
}

export function getOverridedBundle(sitecoreKeysOverride: string, labels: Bundle): Bundle | null {
	const overrided: Bundle = {}
	Object.keys(labels).forEach((key) => {
		const value = getOverridedLabelText(sitecoreKeysOverride, key)
		if (value) overrided[key] = value
	})
	return overrided
}
