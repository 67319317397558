'use client'
import React, { forwardRef, useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import clsx from 'clsx'

export type AutoComplete = 'username' | 'current-password' | 'new-password' | 'email'
export type InputVariant = 'filled' | 'outlined'

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
	label: string
	errorMessage?: string
	customClasses?: string
	variant?: InputVariant
	autoComplete?: AutoComplete
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ type = 'text', name, label, required = false, value = '', onChange, onBlur, errorMessage, customClasses, variant = 'filled', autoComplete, ...props }, ref) => {
	const [showPassword, setShowPassword] = useState(false)

	const togglePasswordVisibility = () => {
		setShowPassword((prev) => !prev)
	}

	const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type

	const variantClasses = clsx({
		'text-light-purple bg-white border-light-grey': variant === 'filled',
		'text-white bg-transparent border-white': variant === 'outlined',
	})

	const inputClasses = clsx(
		'w-full pt-4 pb-3 px-5 text-sm border focus:outline-none rounded-sm',
		'placeholder-transparent peer focus:ring-3 focus:ring-focus-blue',
		{
			'ring-3 ring-error': errorMessage,
		},
		variantClasses,
		customClasses
	)

	const isLabelLong = label.length > 35
	const labelClasses = clsx('absolute left-5 top-2 pointer-events-none transform -translate-y-0', 'text-xxs transition-all duration-200 ease-in-out', {
		'text-dark': variant === 'filled',
		'text-white': variant === 'outlined',
		'peer-placeholder-shown:top-2 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:text-sm': true,
		'peer-placeholder-shown:text-gray-500': variant === 'filled',
		'peer-placeholder-shown:text-white': variant === 'outlined',
		'peer-focus:top-2 peer-focus:transform peer-focus:-translate-y-0 peer-focus:text-xxs': true,
		'peer-focus:text-dark': variant === 'filled',
		'peer-focus:text-white': variant === 'outlined',
		'peer-placeholder-shown:text-smallEnough': isLabelLong,
	})

	return (
		<div className={clsx('w-full relative', customClasses)}>
			<input {...props} className={inputClasses} type={inputType} name={name} required={required} placeholder=" " value={value} onChange={onChange} onBlur={onBlur} ref={ref} autoComplete={autoComplete} />
			{type === 'password' && (
				<button type="button" onClick={togglePasswordVisibility} className="absolute right-5 top-6 transform -translate-y-1/2" tabIndex={-1} aria-label={showPassword ? 'Hide password' : 'Show password'}>
					{showPassword ? <FaRegEyeSlash className="text-light-purple" /> : <FaRegEye className="text-light-purple" />}
				</button>
			)}
			<label className={labelClasses}>
				{label}
				{required && <span className="text-error">*</span>}
			</label>
			{errorMessage && (
				<p className="text-sm text-error mt-2" role="alert">
					{errorMessage}
				</p>
			)}
		</div>
	)
})

Input.displayName = 'Input'

export { Input }
