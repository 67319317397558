'use client'
import React from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import Select from 'react-select'

interface Options {
	value: string
	label: string
}

interface SelectWrapperProps {
	options: Options[]
	placeholder: string
	required: boolean
	field: ControllerRenderProps<FieldValues, string>
}

export const customStyles = {
	control: (provided: any) => ({
		...provided,
		padding: '5px 10px',
		border: '1px solid rgb(223 228 234)',
	}),
	placeholder: (provided: any) => ({
		...provided,
		fontFamily: 'ui-sans-serif, system-ui, sans-serif',
		color: 'rgb(108 117 125)',
		fontSize: '14px',
	}),
}

const SelectWrapper = ({ options, placeholder, required, field }: SelectWrapperProps) => {
	return (
		<Select
			value={options.find((option) => option.value === field.value) || null}
			ref={field.ref}
			options={options}
			placeholder={`${placeholder}${required ? '*' : ''} `}
			required={required}
			styles={customStyles}
			onChange={(selectedOption) => {
				field.onChange(selectedOption ? selectedOption.value : null)
			}}
		></Select>
	)
}

export { SelectWrapper }
