'use client'

import React from 'react'

interface TabListProps {
	children: React.ReactNode
}

export const TabList = ({ children }: TabListProps) => {
	return <div className="flex flex-wrap gap-y-2 gap-x-6 mb-5">{children}</div>
}
