'use client'

import React, { useMemo } from 'react'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'

const LocalizedCountryName = () => {
	const market = useCurrentMarket()
	const sitecore = useSitecoreState((state) => state)

	const changeCountryPopoverLabel = sitecore.getLabel(TranslationBundles.HEADER, 'MSC-HEADER-CHANGE_COUNTRY', 'Change country')

	const localLanguageCountryName = useMemo(() => {
		try {
			const langValue = market?.language?.value || ''
			const langCode = langValue.split('_')[0]?.toLowerCase()

			const pathValue = market?.language?.path || ''
			const twoDigitCountryCode = pathValue.split('-')[0]?.toUpperCase()

			if (!langCode || !twoDigitCountryCode) {
				return null
			}

			const localLanguage = new Intl.DisplayNames([langCode], { type: 'region' })
			return localLanguage.of(twoDigitCountryCode)
		} catch (error) {
			console.error('Error geting the localized country name: ', error)
			return null
		}
	}, [market?.language?.value, market?.language?.path])

	if (!localLanguageCountryName) return null

	return (
		<div
			role="tooltip"
			area-label="click the flag to change country"
			className="absolute top-[210%] left-[77%] transform -translate-x-1/2 
         w-fit rounded-lg bg-white 
         px-2 py-1 shadow-md text-[#1a1a1a] 
         text-sm font-normal leading-6 text-center 
         border border-[#000033] z-[1000]"
		>
			<span
				style={{
					whiteSpace: 'nowrap',
				}}
			>
				{localLanguageCountryName} - {changeCountryPopoverLabel}
			</span>
		</div>
	)
}
export { LocalizedCountryName }
