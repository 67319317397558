'use client'

import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import Link from 'next/link'
import { DynamicPicture } from '../uiComponents'

const FooterSocialLinks = () => {
	const sitecoreStore = useSitecoreState((state) => state)

	const socialLogo = sitecoreStore.getConfig(TranslationBundles.FOOTER, 'MSC-FOOTER-LOGO-1', '')
	if (!socialLogo) return <></>

	const [image, link, target] = socialLogo.replace(/\\u00a7/g, '§').split('§')

	return (
		<Link prefetch={false} href={link || '#'} target={target === 'true' ? '_blank' : '_self'} className="mt-2">
			<DynamicPicture image={image} basepath="websphere-images" alt="social-logo" className="w-8 h-8" width={30} height={30} />
		</Link>
	)
}

export { FooterSocialLinks }
