'use client'
import { useState, useEffect, useMemo, ReactElement } from 'react'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

interface TimeLeft {
	days: number
	hours: number
	minutes: number
	seconds: number
}

interface CountdownProps {
	targetDate: string | Date
}

export const useCalcCountdown = (targetDate: CountdownProps['targetDate'], isBoxedDate?: boolean): string | ReactElement => {
	const sitecoreStore = useSitecoreState((state) => state)

	const labels = useMemo(() => {
		const daysLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-COUNTDOWN-DAY', 'days')
		const hoursLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-COUNTDOWN-HOUR', 'hours')
		const minutesLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-COUNTDOWN-MINUTES', 'minutes')

		return {
			daysLabel,
			daysLabelShort: daysLabel.slice(0, 1),
			hoursLabel,
			hoursLabelShort: hoursLabel.slice(0, 1),
			minutesLabel,
			minutesLabelShort: minutesLabel.slice(0, 1),
		}
	}, [sitecoreStore])

	const calculateTimeLeft = (): TimeLeft => {
		const now = new Date()
		const target = new Date(targetDate)
		const difference = target.getTime() - now.getTime()

		let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 }

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
				minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
				seconds: Math.floor((difference % (1000 * 60)) / 1000),
			}
		}

		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft())

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)

		return () => clearInterval(interval)
	}, [targetDate])

	if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0) {
		return 'countdownExpired'
	} else {
		if (isBoxedDate) {
			const untilOfferEndsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-COUNTDOWN-UNTIL_PROMO_END', 'Before the end of the offer')
			return (
				<div>
					<div className="flex gap-4 mt-2">
						<div className="flex flex-col w-16 h-16 align-center justify-center bg-primary rounded-md">
							<span className="text-center text-white text-lg">{timeLeft.days}</span>
							<p className="text-white text-sm text-center">{labels.daysLabel}</p>
						</div>
						<div className="flex flex-col w-16 h-16 align-center justify-center bg-primary rounded-md">
							<span className="text-center text-white text-lg">{timeLeft.hours}</span>
							<p className="text-white text-sm text-center">{labels.hoursLabel}</p>
						</div>
						<div className="flex flex-col w-16 h-16 align-center justify-center bg-primary rounded-md">
							<span className="text-center text-white text-lg">{timeLeft.minutes}</span>
							<p className="text-white text-sm text-center">{labels.minutesLabel}</p>
						</div>
					</div>
					<p className="text-sm text-white font-medium mt-2">{untilOfferEndsLabel}</p>
				</div>
			)
		} else {
			return `${timeLeft.days}${labels.daysLabelShort}, ${timeLeft.hours}${labels.hoursLabelShort}, ${timeLeft.minutes}${labels.minutesLabelShort}`
		}
	}
}
