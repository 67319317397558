'use client'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React from 'react'

export const SuccessfulRequestModalTemplate = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const successMessage = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-SUBMIT_SUCCESS', 'Your request was successfully sent !')
	return <div>{successMessage}</div>
}
