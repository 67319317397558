'use client'

import { DevTools, DevToolsStyling } from '@/features/dev-tools/configs/devToolsConfigs'
import { useDevToolsState } from '@/features/dev-tools/store/useDevToolsState'
import Link from 'next/link'
import { DynamicPicture } from './DynamicPicture'

interface TextLinkProps {
	bundleName?: string
	href: string
	imageUrl: string
	text: string
	className?: string
	onClick?: (event: any) => void
}

const TextLink = ({ bundleName, href, imageUrl, text, className, onClick }: TextLinkProps) => {
	const devToolsState = useDevToolsState()

	return (
		<Link prefetch={false} href={href} className={`flex items-center gap-2 ${className}`} data-bundle-name={bundleName} onClick={onClick}>
			<DynamicPicture alt="" image={imageUrl} width={16} height={12} />
			<div className="flex flex-col">
				{devToolsState.showBundleNames && <span className={DevToolsStyling[DevTools.SHOW_BUNDLE_NAMES]}>{bundleName}</span>}
				<span className="text-md text-black font-semibold line-clamp-3">{text}</span>
			</div>
		</Link>
	)
}

export { TextLink }
