'use client'

import AgentService from '@/features/agent/agent-service'
import { UPDATE_AGENT_EMAIL_COOKIE } from '@/features/agent/model/cookies'
import { Sitecore } from '@/features/sitecore/components/Sitecore'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { setTextCookie } from '@/utils/cookies/actions/text-cookies'
import { useModalState } from '@/utils/hooks/useModalState'
import { SubmitHandler, useForm } from 'react-hook-form'
import FormFactory, { SchemaItem } from '../factory/formFactory'
import { Button } from '../uiComponents'

interface UpdateEmailFormInput {
	agentEmail: string
}

export const UpdateEmailModalTemplate = () => {
	const modal = useModalState()
	const form = useForm<UpdateEmailFormInput>({ mode: 'all' })

	const schema: SchemaItem[] = [{ type: 'email', name: 'agentEmail', label: 'Email', required: true, autoComplete: 'email' }]

	const handleConfirm: SubmitHandler<UpdateEmailFormInput> = async (data) => {
		const success = await AgentService.updateEmail(data.agentEmail)
		await setTextCookie(UPDATE_AGENT_EMAIL_COOKIE.name, UPDATE_AGENT_EMAIL_COOKIE.defaultValue)

		if (success.result === 'success') return modal.close()

		modal.openInfoModal({
			variant: 'ok',
			children: success.message,
		})
	}

	const handleCancel = async () => {
		await setTextCookie(UPDATE_AGENT_EMAIL_COOKIE.name, UPDATE_AGENT_EMAIL_COOKIE.defaultValue)
		modal.close()
	}

	return (
		<form className="flex flex-col gap-6 w-full" noValidate onSubmit={form.handleSubmit(handleConfirm)}>
			{schema.map((schemaItem, index) => (
				<div className="w-full" key={index}>
					<FormFactory control={form.control} errors={form.formState.errors} {...schemaItem} />
				</div>
			))}
			<div className="flex flex-row items-center gap-3">
				<Button type="button" bgColor="secondary" className="w-full" variant="outlined" onClick={handleCancel}>
					<Sitecore bundle={TranslationBundles.ACCOUNT} keyName="MSC-ACCOUNT-MANAGE_REPRESENTATIVE-UPDATE_EMAIL_REPRESENTATIVE_CLOSE">
						Close
					</Sitecore>
				</Button>
				<Button type="submit" bgColor="secondary" className="w-full" isSubmitting={form.formState.isSubmitting}>
					<Sitecore bundle={TranslationBundles.ACCOUNT} keyName="MSC-ACCOUNT-MANAGE_REPRESENTATIVE-UPDATE_EMAIL_REPRESENTATIVE">
						Confirm
					</Sitecore>
				</Button>
			</div>
		</form>
	)
}
