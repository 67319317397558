import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as PreLogon from './actions/pre-logon'
import * as AuthActions from './actions/session-management'
import * as SessionUpdater from './actions/session-updater'
import * as AuthWebsphere from './actions/websphere-auth'

const AuthService = createProxiedActions<typeof AuthActions & typeof AuthWebsphere & typeof PreLogon & typeof SessionUpdater>({
	modules: [
		AuthActions,
		AuthWebsphere,
		PreLogon,
		SessionUpdater,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default AuthService
