'use client'

import { MscLogoIcon } from '../uiComponents'

const HeaderSimple = () => {
	return (
		<div className="fixed z-30 bg-msc-blue w-full">
			<div className="flex h-72 justify-between items-center lg:px-14 text-white desktop-sm:px-6 desktop-xsm:px-3">
				<div className="flex flex-1 justify-center items-center gap-16 desktop-xsm:gap-10 desktop-l:gap-40">
					<MscLogoIcon />
				</div>
			</div>
		</div>
	)
}

export { HeaderSimple }
