'use client'
import React, { useState, ReactNode } from 'react'
import { TabList, TabPanels, TabButton, TabPanelWrapper } from '../uiComponents' // Correctly import components

interface TabsProps {
	variant?: 'full'
	children: ReactNode
}

interface TabContentProps {
	key?: any
	children: ReactNode
	label: ReactNode
}

export const Tabs = ({ variant, children }: TabsProps) => {
	const [activeIndex, setActiveIndex] = useState(0)

	const tabs = React.Children.toArray(children)

	return (
		<div className="flex flex-col w-full h-full">
			{/* Tab Navigation */}
			<TabList>
				{tabs.map((tab, index) => {
					const tabElement = tab as React.ReactElement<TabContentProps>
					return (
						<TabButton key={index} variant={variant} isActive={activeIndex === index} onClick={() => setActiveIndex(index)}>
							{tabElement.props.label} {/* Render the label with icon */}
						</TabButton>
					)
				})}
			</TabList>

			{/* Tab Panels */}
			<TabPanels activeIndex={activeIndex}>
				{tabs.map((tab, index) => (
					<TabPanelWrapper key={index}>{tab}</TabPanelWrapper>
				))}
			</TabPanels>
		</div>
	)
}

export const TabContent = ({ children }: TabContentProps) => {
	return <div className="h-full">{children}</div>
}
