import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as DevToolsConfigAccessor from './actions/dev-tools-config-accessor'

const DevToolsService = createProxiedActions<typeof DevToolsConfigAccessor>({
	modules: [
		DevToolsConfigAccessor,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default DevToolsService
