'use client'

import React from 'react'

interface TabPanelsProps {
	activeIndex: number
	children: React.ReactNode[]
}

export const TabPanels = ({ activeIndex, children }: TabPanelsProps) => {
	return <div className="h-full">{children[activeIndex]}</div>
}
