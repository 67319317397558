import React from 'react'
import { FlyerBuilderActions, FlyerBuilderPreview, FlyerBuilderStepper, FlyerBuilderStepperControls } from '../uiComponents'
import { ElasticFilterProvider } from '@/features/search-cruise/store/ElasticFilterProvider'
import { elasticFilterInitialState } from '@/features/search-cruise/store/elastic-filter-store-utils'

const FlyerBuilderWrapper = () => {
	const builderSteps = ['destination', 'itinerary', 'promotions', 'agentDetails', 'notes', 'flyer'] // Needs sitecore key

	return (
		<section className="flex flex-col w-full h-full-page bg-white">
			<FlyerBuilderStepper>{builderSteps}</FlyerBuilderStepper>
			<div className="flex h-full">
				<div className="flex flex-col flex-3 bg-background gap-6 p-6">
					<FlyerBuilderPreview />
					<FlyerBuilderStepperControls>{builderSteps}</FlyerBuilderStepperControls>
				</div>
				<div className="flex-1">
					<ElasticFilterProvider initialState={elasticFilterInitialState}>
						<FlyerBuilderActions stepsList={builderSteps} />
					</ElasticFilterProvider>
				</div>
			</div>
		</section>
	)
}

export { FlyerBuilderWrapper }
