import React from 'react'
import { Button } from '../../uiComponents'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const FlyerStep = () => {
	const handleDownload = async () => {
		const firstFlyerPage = document.getElementById('first-flyer-page')
		const secondFlyerPage = document.getElementById('second-flyer-page')
		const thirdFlyerPage = document.getElementById('third-flyer-page')
		if (!firstFlyerPage || !secondFlyerPage || !thirdFlyerPage) return
		const firstInitialScale = firstFlyerPage.style.scale
		const secondInitialScale = secondFlyerPage.style.scale
		const thirdInitialScale = thirdFlyerPage.style.scale

		firstFlyerPage.style.scale = '1'
		secondFlyerPage.style.scale = '1'
		thirdFlyerPage.style.scale = '1'

		const secondFlyerPageSectionsLength = secondFlyerPage?.querySelectorAll('section').length
		const thirdFlyerPageSectionsLength = thirdFlyerPage?.querySelectorAll('section').length

		const pdf = new jsPDF()

		const firstCanvas = await html2canvas(firstFlyerPage, { scale: 1, useCORS: true })
		const secondCanvas = secondFlyerPageSectionsLength > 0 ? await html2canvas(secondFlyerPage, { scale: 1, useCORS: true }) : undefined
		const thirdCanvas = thirdFlyerPageSectionsLength > 0 ? await html2canvas(thirdFlyerPage, { scale: 1, useCORS: true }) : undefined

		const firstImgDate = firstCanvas.toDataURL('image/png')
		const secondImgDate = secondCanvas ? secondCanvas.toDataURL('image/png') : undefined
		const thirdImgDate = thirdCanvas ? thirdCanvas.toDataURL('image/png') : undefined

		const imgProps = pdf.getImageProperties(firstImgDate)
		const pdfWidth = pdf.internal.pageSize.getWidth()
		const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

		let position = 0

		pdf.addImage(firstImgDate, 'PNG', 0, position, pdfWidth, pdfHeight)
		pdf.addPage()

		firstFlyerPage.style.scale = firstInitialScale

		if (secondImgDate) {
			position = 0
			pdf.addImage(secondImgDate, 'PNG', 0, position, pdfWidth, pdfHeight)
			pdf.addPage()

			secondFlyerPage.style.scale = secondInitialScale
		}

		if (thirdImgDate) {
			position = 0
			pdf.addImage(thirdImgDate, 'PNG', 0, position, pdfWidth, pdfHeight)
			pdf.addPage()

			thirdFlyerPage.style.scale = thirdInitialScale
		}

		pdf.save('Title_of_the_flyer.pdf')
	}

	return (
		<div>
			<h2 className="text-sm font-bold uppercase">Download flyer</h2> {/* Needs sitecore key */}
			<p className="mt-8">Title_of_the_flyer.pdf</p>
			<Button className="w-full mt-8" onClick={handleDownload}>
				Download flyer {/* Needs sitecore key */}
			</Button>
		</div>
	)
}

export { FlyerStep }
