import React from 'react'

interface DownloadIconInterface {
	color?: string
}

export const DownloadIcon = ({ color = '#000033' }: DownloadIconInterface) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.30664 12L3.30664 7L4.70664 5.55L7.30664 8.15V0H9.30664V8.15L11.9066 5.55L13.3066 7L8.30664 12ZM2.30664 16C1.75664 16 1.28581 15.8042 0.894141 15.4125C0.502474 15.0208 0.306641 14.55 0.306641 14V11H2.30664V14H14.3066V11H16.3066V14C16.3066 14.55 16.1108 15.0208 15.7191 15.4125C15.3275 15.8042 14.8566 16 14.3066 16H2.30664Z" fill={color} />
		</svg>
	)
}
