'use client'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import Link from 'next/link'
import { useState } from 'react'
import { LocalizedCountryName } from '../uiComponents'

interface CountrySelectionLinkProps {
	onClick?: () => void
	children: React.ReactNode
}

export function CountrySelectionLink(props: CountrySelectionLinkProps) {
	const user = useLoggedUser()
	const environmentState = useEnvironmentState()
	const [isMouseOver, setIsMouseOver] = useState(false)

	const handleOnMouseEnter = () => {
		setIsMouseOver(true)
	}
	const handleOnMouseLeave = () => {
		setIsMouseOver(false)
	}
	const endpoint = environmentState.getString('COUNTRY_SELECTION_ENDPOINT', '#')

	if (user || endpoint === '#') return props.children

	return (
		<div>
			<Link prefetch={false} onClick={props.onClick} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} href={endpoint} className="block h-full flex align-center relative">
				{isMouseOver && <LocalizedCountryName />}
				{props.children}
			</Link>
		</div>
	)
}
