'use client'

import FormFactory, { SchemaItem } from '@/components/factory/formFactory'
import { AgentInformations } from '@/features/auth/models/authentication'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { Form, FormProps } from '@/utils/components/Form'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Alert, Button } from '../uiComponents'

import AgentService from '@/features/agent/agent-service'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'

interface ChangePasswordFormInputs {
	oldPassword: string
	newPassword: string
}

export interface ChangePasswordModalTemplateProps {
	agentInfo: AgentInformations
}

export const ChangePasswordModalTemplate = ({ agentInfo }: ChangePasswordModalTemplateProps) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const generic = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-GENERIC-ERROR', 'A generic error has occured during the login process')
	const passwordNotComplient = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-PASSWORD-NOT-COMPLIANT', 'Password not compliant')
	const invalidOldPassword = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-INVALID-OLD-PASSWORD', 'Invalid old password')
	const OldPassword = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-OLD-PASSWORD', 'Old Password')
	const submitButton = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-CHANGE-PASSWORD', 'Change Password')

	const market = useCurrentMarket()
	const provideUrl = useUrlProvider()
	const [updatePasswordCommerceFormProps, setUpdatePasswordCommerceFormProps] = useState<FormProps | null>(null)
	const [error, setError] = useState<string | undefined>(undefined)
	const consentUrl = provideUrl('consent')

	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm<ChangePasswordFormInputs>({ mode: 'all' })

	const loginSchema: SchemaItem[] = [
		{ type: 'password', name: 'oldPassword', label: OldPassword, required: true },
		{ type: 'confirm_password', name: 'newPassword', required: true },
	]

	const changePassword = async (data: ChangePasswordFormInputs) => {
		const { agency, agent, password } = agentInfo
		const response = await AgentService.invokePersonalAreaChange(agency, agent, password, data.oldPassword, data.newPassword)
		const ssoUrl = `/api/${market.locale}/sso?username=${agent}&password=${data.newPassword}&nextUrl=home`

		if (!response.success || !response.data) {
			if (response.error === 'PASSWORD_NOT_COMPLIANT') return setError(passwordNotComplient)
			if (response.error === 'INVALID_OLD_PASSWORD') return setError(invalidOldPassword)
			setError(generic)
			return
		}

		setUpdatePasswordCommerceFormProps({
			action: `/shop/PersonChangeServicePasswordReset`,
			params: {
				logonId: agent,
				reLogonURL: 'ChangePassword',
				Relogon: 'Update',
				errorViewName: 'ChangePassword',
				fromOrderId: '*',
				toOrderId: '.',
				deleteIfEmpty: '*',
				continue: '1',
				createIfEmpty: '1',
				calculationUsageId: '-1',
				updatePrices: '0',
				consentStatus: response.data.result == 'consent_to_accept' ? 'MUST_TO_BE_ACCEPTED' : 'OK',
				urlConsent: consentUrl,
				pwdForConsent: response.data.passwordForConsent,
				passwordForConsent: response.data.passwordForConsent,
				URL: ssoUrl,
				URLAfterConsent: ssoUrl,
				myAcctMain: '1',
				logonPasswordOld: data.oldPassword,
				logonPassword: data.newPassword,
				logonPasswordVerify: data.newPassword,
			},
		})
	}

	const resetPasswordSubmit: SubmitHandler<ChangePasswordFormInputs> = async (data: ChangePasswordFormInputs) => {
		try {
			await changePassword(data)
		} catch (e: any) {
			if (e.message === 'PASSWORD_NOT_COMPLIANT') return setError(passwordNotComplient)
			if (e.message === 'INVALID_OLD_PASSWORD') return setError(invalidOldPassword)
			setError(generic)
		}
	}

	return (
		<>
			{updatePasswordCommerceFormProps && <Form {...updatePasswordCommerceFormProps} />}
			<form className="flex flex-col gap-6 w-full" noValidate onSubmit={handleSubmit(resetPasswordSubmit)}>
				{loginSchema.map((schemaItem, index) => (
					<div className="w-full" key={index}>
						<FormFactory control={control} errors={errors} {...schemaItem} />
					</div>
				))}
				<Button type="submit" className="w-full mt-4" isSubmitting={isSubmitting}>
					{submitButton}
				</Button>
				{error && <Alert text={error} />}
			</form>
		</>
	)
}
