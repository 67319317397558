'use client'

import { createContext, useContext, useRef } from 'react'
import { StoreApi, useStore } from 'zustand'
import { createEnvironmentStore, EnvironmentState, EnvironmentVariablesState } from './environment-state'

interface EnvironmentProviderProps {
	children: React.ReactNode
	initialState: EnvironmentVariablesState
}

const EnvironmentStateContext = createContext<StoreApi<EnvironmentState> | null>(null)

export function EnvironmentProvider(props: EnvironmentProviderProps) {
	const storeRef = useRef<StoreApi<EnvironmentState> | null>(null)

	if (!storeRef.current) {
		storeRef.current = createEnvironmentStore(props.initialState)
	}

	return <EnvironmentStateContext.Provider value={storeRef.current}>{props.children}</EnvironmentStateContext.Provider>
}

export function useEnvironmentState() {
	const store = useContext(EnvironmentStateContext)

	if (!store) throw new Error('useEnvironmentState must be used within EnvironmentProvider')

	return useStore(store)
}
