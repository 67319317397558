import { CurrentMarket } from '@/features/markets/models/market'
import { create } from 'zustand'
import { Environment } from '../configs/environments'
import { _isFeatureEnabled } from '../feature-flags'

// These variables are visible on client side
enum EnvironmentVariables {
	WEBSPHERE_BASE_ENDPOINT,
	COUNTRY_SELECTION_ENDPOINT,
	ENABLE_DEV_TOOLS,
	CHECK_WEBSPHERE_SESSION_INTERVAL,
	CURRENT_ENVIRONMENT,
	ENABLE_NOTIFICATIONS,
	ENABLE_SHOW_AGENCY_TYPE_DIRECT,
	USA_DIDOMI_REDIRECT_LINK,
}

enum DerivedEnvironmentVariables {
	WEBSPHERE_ENDPOINT,
	WEBSPHERE_IMAGES_ENDPOINT,
}

type EnvironmentVariable = keyof typeof EnvironmentVariables
type DerivedEnvironmentVariable = keyof typeof DerivedEnvironmentVariables
export type EnvironmentVariablesState = { [K in EnvironmentVariable]: string | undefined }
type DerivedEnvironmentVariablesState = { [K in DerivedEnvironmentVariable]: string | undefined }

export interface EnvironmentState {
	variables: EnvironmentVariablesState & DerivedEnvironmentVariablesState
	getStringOrThrow: (key: EnvironmentVariable | DerivedEnvironmentVariable) => string
	getString: (key: EnvironmentVariable | DerivedEnvironmentVariable, def: string) => string
	getNumber: (key: EnvironmentVariable | DerivedEnvironmentVariable, def: number) => number
	getBoolean: (key: EnvironmentVariable | DerivedEnvironmentVariable, def: boolean) => boolean
	isFeatureEnabled: (key: EnvironmentVariable, market: CurrentMarket) => boolean
	getCurrentEnvironment: () => Environment
}

export const createEnvironmentStore = (environmentBundle: EnvironmentVariablesState) => {
	const derivedVariables: DerivedEnvironmentVariablesState = {
		WEBSPHERE_ENDPOINT: `${environmentBundle.WEBSPHERE_BASE_ENDPOINT}/shop`,
		WEBSPHERE_IMAGES_ENDPOINT: `${environmentBundle.WEBSPHERE_BASE_ENDPOINT}/images/sdl`,
	}

	return create<EnvironmentState>((set, get) => ({
		variables: { ...environmentBundle, ...derivedVariables },

		getString: (key, def) => get().variables[key] || def,
		getNumber: (key, def) => (!isNaN(+(get().variables[key] ?? 'NaN')) ? +(get().variables[key] ?? NaN) : def),
		getBoolean: (key, def) => get().variables[key]?.toLowerCase() === 'true' || def,
		getStringOrThrow: (key) => {
			const value = get().variables[key]
			if (!value) throw new Error(`Environment variable ${key} not found`)
			return value
		},
		isFeatureEnabled: (key, market) => _isFeatureEnabled(get().variables[key] ?? '', market),
		getCurrentEnvironment: () => get().variables.CURRENT_ENVIRONMENT as Environment,
	}))
}

export const filterEnvironmentBundle = (environmentBundle: { [key: string]: string | undefined }): EnvironmentVariablesState => {
	return Object.keys(environmentBundle).reduce((acc, key) => {
		if (key in EnvironmentVariables) (acc as any)[key] = environmentBundle[key]
		return acc
	}, {} as EnvironmentVariablesState)
}
