'use client'
import '@/app/globals.scss'
import React, { useEffect } from 'react'
import { Button, HeaderSimple } from '@/components/uiComponents'

interface ErrorTemplateProps {
	error: Error & { digest?: string }
	reset: () => void
}

const ErrorTemplate = ({ error, reset }: ErrorTemplateProps) => {
	useEffect(() => {
		console.log('error: ', error)
		console.log('error.digest: ', error.digest)
	}, [error])

	return (
		<body>
			<div className="w-full">
				<HeaderSimple />
				<div className="container pt-18 mx-auto w-full h-full-page">
					<div className="flex flex-col h-full items-center justify-center text-center">
						<h1 className="text-xl text-msc-blue font-bold">Oh no! Something went wrong!</h1>
						<h2 className="text-lg mb-10">{error.message}</h2>
						<Button variant="filled" onClick={() => reset()}>
							Refresh the page
						</Button>
					</div>
				</div>
			</div>
		</body>
	)
}

export { ErrorTemplate }
