'use client'
import { Notification } from '@/features/notification/model/notification'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { NotificationItem, NotificationLabel, TabContent, Tabs } from '../uiComponents'

interface NotificationsOverlay {
	notificationsList: Notification[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NotificationsOverlay = ({ notificationsList }: NotificationsOverlay) => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const sitecoreStore = useSitecoreState((state) => state)

	const tabsLabels = sitecoreStore.getLabel(TranslationBundles.NOTIFICATIONS, 'NOTIFICATION-ENABLED-TABS', 'all').split(',')
	const noNotificationsLabel = sitecoreStore.getLabel(TranslationBundles.NOTIFICATIONS, 'NOTIFICATION-EMPTY', 'No notifications')

	// Calculate the notification counts for each tab
	const notificationCounts = tabsLabels.reduce(
		(acc: { [key: string]: number }, type: string) => {
			const count = notificationsList.filter((notification) => (type === 'all' || type === notification.type) && !notification.isRemoved).length
			acc[type] = count
			return acc
		},
		{} as { [key: string]: number }
	)

	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendPrivateNotificationGA4Event = (labelClicked: string, ctaClicked: string, section: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'privatenotification',
			eventName: 'private_notification',
			...staticParams,
			label_clicked: labelClicked,
			cta_clicked: ctaClicked,
			section: section,
		})
	}

	return (
		<div className="absolute w-full md:w-auto left-0 md:left-auto top-100 flex justify-center cursor-default" onClick={(e) => e.stopPropagation()}>
			<div className="hidden md:block absolute top-0 z-20 w-2 h-2 bg-white -translate-y-1/2 rotate-45"></div>
			<div className="w-full md:min-w-96 md:max-w-xl min-h-44 bg-white rounded-md border border-background py-4 px-6">
				<Tabs variant="full">
					{tabsLabels.map((type: string, index: number) => {
						const renderedNotificationsIndex = notificationCounts[type] || 0
						const tabLabel = sitecoreStore.getLabel(TranslationBundles.NOTIFICATIONS, `NOTIFICATION-TAB-${type.toUpperCase()}`, type.toUpperCase())
						return (
							<TabContent
								key={index}
								label={
									<div onClick={() => sendPrivateNotificationGA4Event(type, type, type)}>
										<div className="flex gap-1">
											{tabLabel.toUpperCase()} <NotificationLabel>{renderedNotificationsIndex}</NotificationLabel>
										</div>
									</div>
								}
							>
								<ul className="flex flex-col max-h-96 md:max-h-60 h-full gap-4 overflow-y-scroll">
									{notificationsList.map((notification, index) => {
										if ((type === 'all' || type === notification.type) && !notification.isRemoved) {
											return <NotificationItem key={index} notification={notification} tabLabel={type} sendPrivateNotificationGA4Event={sendPrivateNotificationGA4Event} />
										}
									})}
									{renderedNotificationsIndex === 0 && (
										<div key={index} className="flex flex-col justify-center w-full h-full text-center">
											<p className="text-light-purple">{noNotificationsLabel}</p>
										</div>
									)}
								</ul>
							</TabContent>
						)
					})}
				</Tabs>
			</div>
		</div>
	)
}
