'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useModalState } from '@/utils/hooks/useModalState'
import { disabledScrollBehavior } from '@/utils/lib/utils'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { IoClose } from 'react-icons/io5'

export const ModalWrapper = (props: PropsWithChildren) => {
	const isOpen = useModalState((state) => state.isOpen)
	return isOpen ? props.children : null
}

const Modal = () => {
	const state = useModalState()
	const modalRef = useRef<HTMLDivElement>(null)
	const sitecoreStore = useSitecoreState((state) => state)
	const user = useLoggedUser()
	const market = useCurrentMarket()

	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && !state.options.preventClose) {
				state.close()
			}
		}

		if (state.isOpen) {
			document.addEventListener('keydown', handleEscape)
			if (modalRef.current) {
				modalRef.current.focus()
			}
			disabledScrollBehavior()
		}

		return () => {
			document.removeEventListener('keydown', handleEscape)
		}
	}, [state.isOpen])

	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendModalLoginGA4Event = (labelClicked: string, event: string, eventName: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: event,
			eventName: eventName,
			...staticParams,
			label_clicked: labelClicked,
		})
	}

	const handleOnClose = () => {
		if (state.options.modalType === 'loginModal') {
			sendModalLoginGA4Event('Close btn', 'modalogin', 'modal_login_btn')
		}
		state.close()
	}

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:items-center justify-center z-50" role="dialog" aria-modal="true" aria-labelledby="modal-title" onMouseDown={!state.options.preventClose ? state.close : undefined}>
			<div
				className={`bg-white block rounded-sm shadow-lg ${state.options.size?.modalSize === 'md' ? 'max-w-950' : 'max-w-2xl'} max-h-80dvh w-full overflow-y-auto overflow-x-hidden outline-none py-6 md:py-10 px-8 md:px-12`}
				//style={{ height: `${state.options?.size ? state.options?.size.height : 'auto'}` }}
				role="document"
				ref={modalRef}
				tabIndex={-1}
				onClick={(e) => e.stopPropagation()}
				onMouseDown={(e) => e.stopPropagation()}
			>
				<div className="flex justify-between items-center">
					<h3 className="text-2xl font-bold text-msc-blue" id="modal-title">
						{state.title && sitecoreStore.getLabel(state.title.bundle, state.title.keyName, state.title.defaultValue)}
					</h3>
					{!state.options.preventClose && (
						<button className="text-gray-500 hover:text-gray-700" onClick={handleOnClose} aria-label="Close modal">
							<IoClose className="text-black w-6 h-6" />
						</button>
					)}
				</div>
				<div className="flex h-full pt-12 pb-4 px-1 overflow-auto" style={{ height: '-webkit-fill-available' }} id="modalBody">
					{state.template}
				</div>
			</div>
		</div>
	)
}

export { Modal }
