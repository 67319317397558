'use client'
import React from 'react'
import { NavItemsWrapper } from './NavItemsWrapper'
import { usePersistentHeaderMenu } from '@/features/header-menu/hooks/usePersistentHeaderMenu'

const NavMenu = () => {
	const menu = usePersistentHeaderMenu()
	if (!menu) return null

	return <div className="hidden desktop-min-1200px:flex items-center justify-center gap-6 desktop-sm:text-sm desktop-sm:gap-3 desktop-xsm:text-sm desktop-xsm:gap-6">{menu.subMenu.length > 0 && menu.subMenu.map((data: any) => <NavItemsWrapper key={data.label} navMenuItem={data.label} headerLinks={data.subMenu} navMenuItemId={data.flags?.[0]} />)}</div>
}

export { NavMenu }
