import React from 'react'

interface PriceIntervalIconInterface {
	color?: string
}

export const PriceIntervalIcon = ({ color = '#000033' }: PriceIntervalIconInterface) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 -960 960 960">
			<path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" fill={color}></path>
		</svg>
	)
}
