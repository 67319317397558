import React from 'react'

interface InfoIconInterface {
	size?: string
	color?: string
}

export const InfoIcon = ({ size = '18', color = '#FFFFFF' }: InfoIconInterface) => {
	return (
		<svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.0001 0.506348C4.30322 0.506348 0.506348 4.30322 0.506348 9.0001C0.506348 13.697 4.30322 17.522 9.0001 17.522C13.697 17.522 17.522 13.697 17.522 9.0001C17.522 4.30322 13.697 0.506348 9.0001 0.506348ZM9.0001 16.2563C5.00635 16.2563 1.77197 12.9938 1.77197 9.0001C1.77197 5.00635 5.00635 1.77197 9.0001 1.77197C12.9938 1.77197 16.2563 5.03447 16.2563 9.02822C16.2563 12.9938 12.9938 16.2563 9.0001 16.2563Z" fill={color} />
			<path d="M10.1251 7.6499H7.87515C7.53765 7.6499 7.22827 7.93115 7.22827 8.29678V13.9218C7.22827 14.2593 7.50952 14.5687 7.87515 14.5687H10.1251C10.4626 14.5687 10.772 14.2874 10.772 13.9218V8.29678C10.772 7.93115 10.4626 7.6499 10.1251 7.6499ZM9.5064 13.2749H8.52202V8.91553H9.5064V13.2749Z" fill={color} />
			<path d="M9.00015 3.45947C8.0439 3.45947 7.22827 4.24697 7.22827 5.23135C7.22827 6.21572 8.01577 7.00322 9.00015 7.00322C9.98452 7.00322 10.772 6.21572 10.772 5.23135C10.772 4.24697 9.9564 3.45947 9.00015 3.45947ZM9.00015 5.70947C8.7189 5.70947 8.4939 5.48447 8.4939 5.20322C8.4939 4.92197 8.7189 4.69697 9.00015 4.69697C9.2814 4.69697 9.5064 4.92197 9.5064 5.20322C9.5064 5.48447 9.2814 5.70947 9.00015 5.70947Z" fill={color} />
		</svg>
	)
}
