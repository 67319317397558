import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as MarketsActions from './actions/utils'

const MarketService = createProxiedActions<typeof MarketsActions>({
	modules: [
		MarketsActions,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default MarketService
