// Add here custom properties
export enum DevTools {
	SITECORE_KEYS_OVERRIDE = 'sitecoreKeysOverride',
	HIGHLIGHT_LABELS = 'highlightLabels',
	SHOW_BUNDLE_NAMES = 'showBundleNames',
}

export const DevToolsStyling = {
	[DevTools.HIGHLIGHT_LABELS]: 'bg-green-500',
	[DevTools.SHOW_BUNDLE_NAMES]: 'bg-cyan-500',
}
