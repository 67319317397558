import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { Button, FlyerBuilderStepperProps } from '../uiComponents'

const FlyerBuilderStepperControls = ({ children }: FlyerBuilderStepperProps) => {
	const { activeStep, setActiveStep } = useFlyerBuilderState()
	const isLastStep = activeStep >= children.length

	return (
		<div className="container">
			<div className="flex flex-col justify-center py-6 gap-6">
				<div className="flex justify-end gap-4 ml-auto">
					{activeStep !== 1 && (
						<Button variant="outlined" onClick={() => setActiveStep(activeStep - 1)}>
							Back {/* Replace this with a translated label */}
						</Button>
					)}
					<Button variant="filled" disabled={isLastStep} onClick={() => !isLastStep && setActiveStep(activeStep + 1)}>
						Next {/* Replace this with a translated label */}
					</Button>
				</div>
			</div>
		</div>
	)
}

export { FlyerBuilderStepperControls }
