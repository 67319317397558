import { unstable_noStore as noStore } from 'next/cache'
import { Environment } from './configs/environments'

export const getEnv = (name: string, def?: string): string | undefined => {
	noStore()

	const result = process.env[`APPSETTING_${name}`] ?? process.env[name]

	if (!result) console.warn(`Environment variable ${name} not found, using default value ${def}`)

	return result ?? def
}

export const getEnvNumeric = (name: string, def: number): number => {
	const env = getEnv(name)
	return env && !isNaN(+env) ? +env : def
}

export const getEnvBoolean = (name: string, def: boolean): boolean => {
	const env = getEnv(name)
	return env ? env.toLowerCase() === 'true' : def
}

export function getProcessEnv(): { [key: string]: string | undefined } {
	const result: { [key: string]: string | undefined } = {}

	for (const key in process.env) {
		if (key.startsWith('APPSETTING_')) {
			const strippedKey = key.replace('APPSETTING_', '')
			result[strippedKey] = getEnv(strippedKey)
		} else {
			result[key] = getEnv(key)
		}
	}

	return result!
}

export function getCurrentEnvironment(): Environment {
	const env = getEnv('CURRENT_ENVIRONMENT', 'LOCAL')
	return env as Environment
}
