// Define the new cabin class configuration

export const MSC_CABIN_CLASSES = {
	INS: ['I1', 'I2', 'I3', 'IF1', 'IW', 'I1S', 'SPL', 'IP1', 'IB', 'IM1', 'IM2', 'IS', 'IR1', 'IR2', 'IL1', 'IX1'],
	OUT: ['O1', 'O2', 'OF1', 'OW', 'OB', 'OM1', 'OM2', 'OR1', 'OR2', 'OL1', 'OS', 'OL2', 'OL3', 'OO', 'VL1', 'VL2', 'VLA'],
	OUB: ['B1', 'B2', 'B3', 'BW', 'BP', 'BP2', 'BB', 'BM1', 'BS', 'BR1', 'BR2', 'BR3', 'BA', 'BM2', 'BL1', 'BL2', 'BL3', 'BR4', 'PV', 'PR1', 'PR2', 'PR3', 'BGA'],
	SUI: ['S2', 'S3', 'SP3', 'SD3', 'SE3', 'SEW', 'SJ3', 'SP2', 'SUI', 'SUIB', 'D3', 'SR1', 'SR2', 'SLJ', 'SXJ', 'SRS', 'SLS', 'SX', 'SRP', 'SLP', 'SM', 'SXT', 'SL1', 'SL2', 'SLW', 'SD', 'SLT'],
	YCB: ['YC1', 'YC2', 'YC3', 'YCW', 'YH1', 'YIN', 'YC4', 'YCP', 'YCD', 'YJD'],
	FAM: ['FPO', 'FPB', 'FMO', 'FMB', 'FLP', 'FLO', 'FLA', 'FAM'],
	BIN: ['BIN', 'BOU', 'BBL', 'SBG'],
}

// Create a reverse mapping for quick lookups
export const CABIN_CODE_TO_CLASS = Object.entries(MSC_CABIN_CLASSES).reduce(
	(acc, [className, codes]) => {
		codes.forEach((code) => {
			acc[code] = className
		})
		return acc
	},
	{} as { [key: string]: string }
)

// Define the cabin class to letter mapping
export const CABIN_CLASS_TO_LETTER: { [key: string]: string } = {
	INS: 'I',
	OUT: 'O',
	OUB: 'B',
	SUI: 'S',
	YCB: 'Y',
	FAM: 'F',
}
