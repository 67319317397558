import React from 'react'
import { FaLock } from 'react-icons/fa6'
import { AiOutlineLeft } from 'react-icons/ai'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { useModalState } from '@/utils/hooks/useModalState'

interface MobileNavMenuLinksProps {
	headerLinks: any
	label: string
	handleHideLinks: () => void
}

const MobileNavMenuLinks = ({ headerLinks, label, handleHideLinks }: MobileNavMenuLinksProps) => {
	const user = useLoggedUser()
	const userLogged = user !== undefined

	const openLoginModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		useModalState.getState().openLoginModal()
	}
	return (
		<div className="flex flex-col">
			<div onClick={handleHideLinks} className="flex gap-4 px-4 items-center pb-8">
				<AiOutlineLeft color="white" />
				<li className="text-white">{label}</li>
			</div>
			{headerLinks.map((value: any, index: number) => (
				<div key={index} className="inline-block px-4 py-2 text-white">
					<div className="font-bold mb-4 pb-1 border-b">{value?.label}</div>
					<div>
						{value?.subMenu?.map((item: any, index: number) => (
							<li key={index} className="py-1 flex items-center pb-6">
								<div className={`w-4 h-4 flex items-center justify-center`}>{!userLogged && item.logged && <FaLock size={14} className="pr-1" />}</div>
								<a href={item.url} onClick={!userLogged && item.logged ? openLoginModal : undefined} className={`block text-white hover:text-gray-300 text-sm`}>
									{item.label}
								</a>
							</li>
						))}
					</div>
				</div>
			))}
		</div>
	)
}

export { MobileNavMenuLinks }
