import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as ChangePassword from './actions/change-password'
import * as Consent from './actions/consent'
import * as ContactUs from './actions/contact-us'
import * as Coopera from './actions/coopera'
import * as ForgotPassword from './actions/forgot-password'
import * as Registration from './actions/registration-actions'
import * as UpdateAgent from './actions/update-agent'
import * as VoyagerCard from './actions/voyager-card/voyager-card-actions'

// prettier-ignore
// eslint-disable-next-line prettier/prettier
const AgentService = createProxiedActions<
	typeof ForgotPassword &
	typeof ChangePassword &
	typeof UpdateAgent &
	typeof Consent &
	typeof Registration &
	typeof VoyagerCard &
	typeof Coopera &
	typeof ContactUs
>({
	modules: [
		ForgotPassword,
		ChangePassword,
		UpdateAgent,
		Consent,
		Registration,
		VoyagerCard,
		Coopera,
		ContactUs,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default AgentService
