'use client'

import React, { createContext, useContext, useRef } from 'react'
import { StoreApi, useStore } from 'zustand'
import { createSitecoreStore, SitecoreState, SitecoreStoreInitialState } from '@/features/sitecore/store/sitecore-state'
import { useDevToolsState } from '@/features/dev-tools/store/useDevToolsState'

interface SitecoreProviderProps {
	children: React.ReactNode
	initialState: SitecoreStoreInitialState
}

const SitecoreStoreContext = createContext<StoreApi<SitecoreState> | null>(null)

export function SitecoreProvider(props: SitecoreProviderProps) {
	const storeRef = useRef<StoreApi<SitecoreState> | null>(null)
	const devToolsState = useDevToolsState()

	if (!storeRef.current) {
		storeRef.current = createSitecoreStore({ ...props.initialState, devToolsState })
	}

	return <SitecoreStoreContext.Provider value={storeRef.current}>{props.children}</SitecoreStoreContext.Provider>
}

export function useSitecoreState<T>(selector: (state: SitecoreState) => T): T {
	const store = useContext(SitecoreStoreContext)

	if (!store) throw new Error('useSitecoreState must be used within SitecoreProvider')

	return useStore(store, selector)
}
