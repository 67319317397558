interface StaticParams {
	agency_id: string | null
	network_id: string | null
	market_code: string | null
	user_id: string | null
	pageSection: string | null
}

export const staticGA4ParamsFromUser = (user: any, market: any): StaticParams => {
	const staticParams: StaticParams = {
		agency_id: user?.agency || '',
		network_id: user?.consortiumCode || '',
		market_code: market?.code || '',
		user_id: user?.agency || '',
		pageSection: user ? 'Private' : 'Public',
	}

	return staticParams
}

export const calculateTimeLeft = (targetDate: string): any => {
	const now = new Date()
	const target = new Date(targetDate)
	const difference = target.getTime() - now.getTime()

	let timeLeft: any = { days: 0, hours: 0, minutes: 0, seconds: 0 }

	if (difference > 0) {
		timeLeft = {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
			minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
			seconds: Math.floor((difference % (1000 * 60)) / 1000),
		}
	}

	return `${timeLeft.days}D, ${timeLeft.hours}H, ${timeLeft.minutes}M`
}
