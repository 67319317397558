'use client'
import { ExploraLink, FooterLinks, SeparatorText, FooterSocialLinks } from '../uiComponents'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { Sitecore } from '@/features/sitecore/components/Sitecore'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'

const Footer = () => {
	const user = useLoggedUser()
	const loggedInFooterCss = !user ? '' : 'xl:ml-24 2xl:ml-32'
	const mscGroupLabel = (
		<Sitecore bundle={TranslationBundles.FOOTER} keyName="MSC-FOOTER-MSC-GROUP-HEADER-TEXT">
			MSC Group
		</Sitecore>
	)
	return (
		<div className={`flex flex-col justify-center items-center bg-msc-blue text-white pt-5 pb-5 ${loggedInFooterCss}`}>
			<FooterLinks />
			<SeparatorText>{mscGroupLabel}</SeparatorText>
			<ExploraLink />
			<FooterSocialLinks />
		</div>
	)
}

export { Footer }
