'use client'
import React from 'react'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

const ConversionRateSection = () => {
	const sitecoreStore = useSitecoreState((state) => state)

	const titleRate = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CONVERSION_RATE_QO', '')

	const currentRate = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CURRENT_CONVERSION_RATE_QO', '')

	const currencySymbol = sitecoreStore.getLabel(
		TranslationBundles.CHECKOUT, // Changed from CONVERSION_RATE to CHECKOUT as per JSP
		'CURRENCY_SYMBOL_ARS',
		'ARS'
	)

	const currencyExchangeRate = sitecoreStore.getLabel(TranslationBundles.CONVERSION_RATE, 'USD-ARS', '')

	const calculateExchangeRate = () => {
		if (!currencyExchangeRate.includes('???')) {
			try {
				const rate = parseFloat(currencyExchangeRate)
				if (!isNaN(rate)) {
					// Calculate inverse rate as per JSP
					const convRate = 1 / rate
					return new Intl.NumberFormat('es-AR', {
						minimumFractionDigits: 2, // Changed to match JSP's "0.00" pattern
						maximumFractionDigits: 2,
					}).format(convRate)
				}
			} catch (e) {
				console.error('Error formatting exchange rate:', e)
			}
		}

		return sitecoreStore.getLabel(TranslationBundles.CONVERSION_RATE, 'EXCHANGE_USD_ARS', '')
	}

	const formattedExchangeRate = calculateExchangeRate()

	return (
		<section className="flex w-full border-t-2 border-msc-blue rounded-sm bg-white px-6 py-4">
			<p className="text-lg text-msc-blue">
				{`${titleRate.toUpperCase()} ${currentRate}`}
				<strong className="font-bold pl-1">{`USD 1,00 = ${currencySymbol} ${formattedExchangeRate}`}</strong>
			</p>
		</section>
	)
}

export { ConversionRateSection }
