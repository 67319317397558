import { CurrentMarket } from '@/features/markets/models/market'
import { fullFetch } from './fetch'
import { getWebsphereCookiesAsString } from '@/utils/cookies/actions/websphere-cookies'

/**
 * Interface defining the options object used for WebSphere API calls.
 *
 * @property command The name of the WebSphere command to be invoked.
 * @property market (optional) The market information for the request, current means that it gonna use the actual market from locale
 * @property params (optional) An object containing additional parameters for the request.
 */
export interface FetchOptions {
	command: string
	market?: 'current' | CurrentMarket
	request?: { [key: string]: any }
}

/**
 * Fetches data from a WebSphere API endpoint using a GET request.
 *
 * **Warning:** passing market = undefined is not allowed inside an "unstable_cache" because there is no possible to access the headers.
 *
 * @param command The name of the WebSphere command to be invoked.
 * @param params (optional) An object containing additional parameters for the request.
 * @param market (optional) The market information for the request. If not provided or undefined, the current market will be fetched, if null then will be not sent to websphere.
 * @returns A Promise that resolves to the parsed response body.
 */
export async function get<T>(options: FetchOptions): Promise<T> {
	const response = await fullFetch({
		method: 'GET',
		sendCookies: await getWebsphereCookiesAsString(),
		...options,
	})
	return response.body as T
}

/**
 * Sends data to a WebSphere API endpoint using a POST request.
 *
 * **Warning:** passing market = undefined is not allowed inside an "unstable_cache" because there is no possible to access the headers.
 *
 * @param command The name of the WebSphere command to be invoked.
 * @param params (optional) An object containing additional parameters for the request.
 * @param market (optional) The market information for the request. If not provided or undefined, the current market will be fetched, if null then will be not sent to websphere.
 * @returns A Promise that resolves to the parsed response body.
 */
export async function post<T>(options: FetchOptions): Promise<T> {
	const response = await fullFetch({
		method: 'POST',
		sendCookies: await getWebsphereCookiesAsString(),
		...options,
	})
	return response.body as T
}
