'use client'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useModalState } from '@/utils/hooks/useModalState'
import { FaUser } from 'react-icons/fa6'
import { ChangeLanguage } from '../atoms/ChangeLanguage'
import { CountryFlag } from '../atoms/CountryFlag'
import { Button, CountrySelectionLink } from '../uiComponents'

const img = <FaUser color="white" size={18} />

interface MobileNavMenuButtonsProps {
	countryFlag: React.ReactElement<typeof CountryFlag>
}

const MobileNavMenuButtons = (props: MobileNavMenuButtonsProps) => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const sitecoreStore = useSitecoreState((state) => state)

	const registerYourAgencyLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-REGISTER-BUTTON-LABEL', 'Register your agency')
	const loginLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-LOGIN-BUTTON-LABEL', 'Login')

	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendHeaderButtonsGA4Event = (labelClicked: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'menuClick',
			eventName: 'menu_click',
			...staticParams,
			label_clicked: labelClicked,
		})
	}
	return (
		<div className="flex flex-col gap-6 px-6 pb-11">
			<ChangeLanguage className="flex lg:hidden" />
			{!user && (
				<>
					<CountrySelectionLink onClick={() => sendHeaderButtonsGA4Event('Flag')}>{props.countryFlag}</CountrySelectionLink>
					<Button
						icon={img}
						variant="filled"
						bgColor="primary"
						className="flex justify-center items-center gap-2 w-full"
						onClick={() => {
							useModalState.getState().openLoginModal()
							sendHeaderButtonsGA4Event('Login access')
						}}
					>
						{loginLabel}
					</Button>
					<Button
						isIconRight={false}
						variant="outlinedSecondary"
						className="text-white border-2 border-[#EE732F] px-3 py-2 flex justify-center items-center w-full"
						onClick={() => {
							useModalState.getState().openRegisterYourAgencyModal()
							sendHeaderButtonsGA4Event('Registration')
						}}
					>
						{registerYourAgencyLabel}
					</Button>
				</>
			)}
		</div>
	)
}

export { MobileNavMenuButtons }
