'use client'
import React from 'react'

export interface RadioProps {
	id: string
	label: string
	checked: boolean
	onChange: () => void
}

const Radio = ({ id, label, checked, onChange, ...props }: RadioProps) => {
	return (
		<div className="flex gap-2">
			<input checked={checked} {...props} onChange={onChange} type="radio" id={id} name={label} value="" />
			<label className="whitespace-nowrap" htmlFor={id}>
				{label}
			</label>
		</div>
	)
}

export { Radio }
