import React from 'react'

interface HomeIconInterface {
	color?: string
}

export const HomeIcon = ({ color = '#000033' }: HomeIconInterface) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" overflow="visible">
			<path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill={color} />
		</svg>
	)
}
