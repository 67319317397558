import { DevTools, DevToolsStyling } from '@/features/dev-tools/configs/devToolsConfigs'
import { DevToolsState } from '@/features/dev-tools/store/dev-tools-state'
import { Bundle } from '@/features/sitecore/actions/services/sitecore-bundle'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { getOverridedLabelText } from '@/features/sitecore/lib/labels-override'
import React from 'react'
import { create } from 'zustand'

export interface SitecoreState {
	bundles: Record<string, Bundle>
	getLabel: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => string
	getConfig: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => string
	getMultiKeyLabelAsString: (
		keysAndDefaults: Array<{
			bundle: TranslationBundles
			key: string
			default?: string
			replacement?: string[]
		}>
	) => Record<string, string>
	getNumber: (bundle: TranslationBundles, key: string, defaultNumber: number, allowedValues?: number[]) => number
	getSpan: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => React.ReactElement
	getBundle: (bundle: TranslationBundles) => Bundle
}

export interface SitecoreStoreInitialState {
	bundles: { [key: string]: Bundle }
}

export const createSitecoreStore = (props: { devToolsState: DevToolsState; bundles: Record<string, Bundle> }) => {
	return create<SitecoreState>((set, get) => ({
		...props,

		getLabel: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => {
			const fullBundle = get().bundles[bundle]
			if (!fullBundle) {
				console.warn(`Bundle ${bundle} not found`)
				return defaultLabel
			}

			let label = getOverridedLabelText(props.devToolsState.sitecoreKeysOverride, key) ?? fullBundle[key] ?? defaultLabel
			if (!fullBundle[key]) console.warn(`Key ${key} not found in bundle ${bundle}`)

			if (fullBundle[key] === `???${key}???`) {
				console.warn(`Key ${key} in bundle ${bundle} has not been set`)
				return defaultLabel
			}

			replacements?.forEach((replacement, index) => {
				label = label.replace(`$${index}`, replacement)
			})
			return label
		},

		getConfig: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => {
			const fullBundle = get().bundles[bundle]
			if (!fullBundle) {
				console.warn(`Bundle ${bundle} not found`)
				return defaultLabel
			}

			let label = fullBundle[key] ?? defaultLabel
			if (!fullBundle[key]) console.warn(`Key ${key} not found in bundle ${bundle}`)

			if (fullBundle[key] === `???${key}???`) {
				console.warn(`Key ${key} in bundle ${bundle} has not been set`)
				return defaultLabel
			}

			replacements?.forEach((replacement, index) => {
				label = label.replace(`$${index}`, replacement)
			})
			return label
		},

		getMultiKeyLabelAsString: (keysAndDefaults) => {
			return keysAndDefaults.reduce(
				(acc, { bundle, key, default: defaultValue = '', replacement }) => {
					acc[key] = get().getLabel(bundle, key, defaultValue, replacement)
					return acc
				},
				{} as Record<string, string>
			)
		},

		getNumber: (bundle: TranslationBundles, key: string, defaultNumber: number, allowedValues?: number[]) => {
			const labelValue = get().getLabel(bundle, key, defaultNumber.toString())
			if (!labelValue) return defaultNumber

			const numericValue = Number(labelValue)

			if (isNaN(numericValue)) {
				console.warn(`Invalid numeric value for key ${key} in bundle ${bundle}`)
				return defaultNumber
			}

			if (allowedValues && !allowedValues.includes(numericValue)) {
				console.warn(`Value ${numericValue} not in allowed values for key ${key} in bundle ${bundle}`)
				return defaultNumber
			}

			return numericValue
		},

		getSpan: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => {
			const label = get().getLabel(bundle, key, defaultLabel, replacements)
			return (
				<span data-bundle={props.devToolsState.enabled ? bundle : undefined} data-key={props.devToolsState.enabled ? key : undefined} title={props.devToolsState.enabled ? `bundle: ${bundle} key: ${key}` : undefined} className={props.devToolsState.highlightLabels ? DevToolsStyling[DevTools.HIGHLIGHT_LABELS] : undefined}>
					{label}
				</span>
			)
		},

		getBundle: (bundle: TranslationBundles) => get().bundles[bundle],
	}))
}
