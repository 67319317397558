import React from 'react'
import { SearchBooking, SearchCruises } from '../uiComponents'
import { ElasticFilterProvider } from '@/features/search-cruise/store/ElasticFilterProvider'
import { elasticFilterInitialState } from '@/features/search-cruise/store/elastic-filter-store-utils'

const SearchSection = () => {
	return (
		<section className="flex flex-col w-full xl:flex-row xl:items-start mx-auto gap-6 z-10">
			<div className="w-full z-20">
				<ElasticFilterProvider initialState={elasticFilterInitialState}>
					<SearchCruises />
				</ElasticFilterProvider>
			</div>
			<div className="xl:min-w-96 xl:max-w-80 max-h-69 2xl:max-h-69">
				<SearchBooking />
			</div>
		</section>
	)
}

export { SearchSection }
