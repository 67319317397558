'use client'
import React from 'react'
import { IoClose } from 'react-icons/io5'

export interface ChipProps {
	children: string
	onDelete?: () => void
}

const Chip = ({ children, onDelete }: ChipProps) => {
	return (
		<div className="flex gap-4 w-fit items-center py-1 px-2 bg-secondary rounded-md">
			<p className="text-xs text-white">{children}</p> <IoClose onClick={onDelete} className="text-white w-4 h-4 cursor-pointer" />
		</div>
	)
}

export { Chip }
