import React from 'react'

interface UserIconInterface {
	color?: string
}

export const UserIcon = ({ color = '#000033' }: UserIconInterface) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path d="M12.5 12C11.4 12 10.4583 11.6083 9.675 10.825C8.89167 10.0417 8.5 9.1 8.5 8C8.5 6.9 8.89167 5.95833 9.675 5.175C10.4583 4.39167 11.4 4 12.5 4C13.6 4 14.5417 4.39167 15.325 5.175C16.1083 5.95833 16.5 6.9 16.5 8C16.5 9.1 16.1083 10.0417 15.325 10.825C14.5417 11.6083 13.6 12 12.5 12ZM4.5 20V17.2C4.5 16.6333 4.64583 16.1125 4.9375 15.6375C5.22917 15.1625 5.61667 14.8 6.1 14.55C7.13333 14.0333 8.18333 13.6458 9.25 13.3875C10.3167 13.1292 11.4 13 12.5 13C13.6 13 14.6833 13.1292 15.75 13.3875C16.8167 13.6458 17.8667 14.0333 18.9 14.55C19.3833 14.8 19.7708 15.1625 20.0625 15.6375C20.3542 16.1125 20.5 16.6333 20.5 17.2V20H4.5Z" fill={color} />
			</g>
		</svg>
	)
}
