import { createConfigForSearchCruiseMinPrice } from '@/features/caching/lib/tanstack'
import { useElasticFilterState } from '@/features/search-cruise/store/useElasticFilterState'
import { useQuery } from '@tanstack/react-query'

const ItineraryStep = () => {
	const ships = useElasticFilterState((state) => state.options.ship)
	const priceB2bParams = { departureDateFrom: '28/11/2024', departureDateTo: '30/11/2024', shipCd: 'FA' }
	const minimumPriceWithHSC = useQuery(createConfigForSearchCruiseMinPrice(priceB2bParams))

	return (
		<div>
			<h2 className="text-sm font-bold uppercase">Select an itinerary</h2>
			{
				<div className="mt-4 flex flex-col gap-2">
					{ships.map((ship, index) => {
						return (
							<div key={index}>
								<div>{ship.label}</div>
							</div>
						)
					})}
				</div>
			}
			<div>{minimumPriceWithHSC.data?.success ? <div>{minimumPriceWithHSC.data!.data}</div> : <div>Loading...</div>}</div>
		</div>
	)
}

export { ItineraryStep }
