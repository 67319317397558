'use client'
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'

interface VideoProps {
	src: string
	srcTab?: string
	srcMob?: string
}

const CustomVideo = forwardRef<HTMLVideoElement, VideoProps>(({ src, srcTab, srcMob }, ref) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const [currentSrc, setCurrentSrc] = useState<string | null>(null)
	const sitecoreStore = useSitecoreState((state) => state)
	const noBrowserSupportLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_BROWSER_NO_SUPPORT', 'Your browser does not support the video tag.')

	// Forward the internal videoRef to the parent ref
	useImperativeHandle(ref, () => videoRef.current as HTMLVideoElement)

	useEffect(() => {
		const updateVideoSource = () => {
			const width = window.innerWidth
			let newSrc = src

			if (width < 768) {
				newSrc = srcMob || srcTab || src
			} else if (width < 1024) {
				newSrc = srcTab || src
			}

			if (newSrc !== currentSrc) {
				setCurrentSrc(newSrc)
				localStorage.setItem('videoSrc', newSrc)
			}
		}

		// Check if video source is already cached
		const cachedSrc = localStorage.getItem('videoSrc')
		if (cachedSrc) {
			setCurrentSrc(cachedSrc)
		} else {
			updateVideoSource()
		}

		window.addEventListener('resize', updateVideoSource)

		return () => {
			window.removeEventListener('resize', updateVideoSource)
		}
	}, [src, srcTab, srcMob, currentSrc])

	useEffect(() => {
		if (!currentSrc) return
		const endpointUrl = currentSrc ? `/api/video?src=${currentSrc}` : ''
		if (videoRef.current && currentSrc) {
			videoRef.current.src = endpointUrl
			videoRef.current.load()
		}
	}, [currentSrc])

	return (
		<div className="absolute w-full h-full">
			<video ref={videoRef} className="absolute inset-0 object-cover object-center w-full h-full" autoPlay muted loop playsInline aria-label="Video player">
				{noBrowserSupportLabel}
			</video>
		</div>
	)
})

CustomVideo.displayName = 'custom-video'

export { CustomVideo }
