import React from 'react'
import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { AgentDetailsStep, DestinationStep, FlyerStep, ItineraryStep, NotesStep, PromotionsStep } from '../uiComponents'

interface FlyerBuilderActionsProps {
	stepsList: string[]
}

const FlyerBuilderActions = ({ stepsList }: FlyerBuilderActionsProps) => {
	const { activeStep } = useFlyerBuilderState()
	const stepName = stepsList[activeStep - 1]
	let activeComponent

	if (stepName === 'destination') {
		activeComponent = <DestinationStep />
	} else if (stepName === 'itinerary') {
		activeComponent = <ItineraryStep />
	} else if (stepName === 'promotions') {
		activeComponent = <PromotionsStep />
	} else if (stepName === 'agentDetails') {
		activeComponent = <AgentDetailsStep />
	} else if (stepName === 'notes') {
		activeComponent = <NotesStep />
	} else if (stepName === 'flyer') {
		activeComponent = <FlyerStep />
	}

	return <div className="h-full py-8 px-6 shadow-md">{activeComponent}</div>
}

export { FlyerBuilderActions }
