import { CurrentMarket } from '@/features/markets/models/market'
import { User } from 'next-auth'
import pino, { DestinationStream, LoggerOptions, Logger as PinoLogger } from 'pino'
import { getEnv, getEnvBoolean } from '../environment/env'

type CustomLogger = PinoLogger<'error' | 'warn' | 'info' | 'debug', true>

const pinoConfig: DestinationStream | LoggerOptions<'info' | 'error' | 'warn' | 'debug', boolean> | undefined = {
	level: getEnv('PINO_LOG_LEVEL', 'info'),
	base: undefined,
	customLevels: {
		error: 50,
		warn: 40,
		info: 30,
		debug: 20,
	},
	useOnlyCustomLevels: true,
	formatters: {
		level: (label: any) => ({ level: label }),
	},
}

if (!getEnvBoolean('PINO_LOG_JSON', false))
	pinoConfig.transport = {
		target: 'pino-pretty',
		options: {
			colorize: true,
			ignore: 'pid,hostname',
			singleLine: true,
		},
	}

class PinoLoggerSingleton {
	private static instance: CustomLogger
	public static getInstance(): CustomLogger {
		if (!PinoLoggerSingleton.instance) PinoLoggerSingleton.instance = pino(pinoConfig) as CustomLogger
		return PinoLoggerSingleton.instance
	}
}

export class Writer {
	protected _logger: CustomLogger

	constructor(logger: CustomLogger) {
		this._logger = logger
	}

	public error(message: string) {
		this._logger.error(message)
	}

	public warn(message: string) {
		this._logger.warn(message)
	}

	public info(message: string) {
		this._logger.info(message)
	}

	public debug(message: string) {
		this._logger.debug(message)
	}
}

export class Logger extends Writer {
	constructor(filename: string) {
		const logger = PinoLoggerSingleton.getInstance()
		const child = logger.child({ filename }) as CustomLogger
		super(child)
	}

	of(market: CurrentMarket, user?: User | string): Writer {
		const specificLogger = this._logger.child({
			market: market.locale,
			agentId: typeof user === 'string' ? user : user?.agentId,
		})
		return new Writer(specificLogger as CustomLogger)
	}

	service(data: { url: string; method: string; request: any; response: { status: number; body: any } }) {
		const specificLogger = this._logger.child(data)
		const logger = new Writer(specificLogger as CustomLogger)
		logger.info('service')
	}
}
