import React from 'react'
import Link from 'next/link'

interface LoggedNavigationButtonProps {
	icon: React.ReactElement
	customClassNames?: string
	isActive?: boolean
	link: string
	children: React.ReactElement | string
	sendVerticalMenuCtaGA4Event: (labelClick: string) => void
	labelClicked: string
}
export const LoggedNavigationButton = ({ icon, customClassNames, isActive, link, children, sendVerticalMenuCtaGA4Event, labelClicked }: LoggedNavigationButtonProps) => {
	const updatedIcon = React.cloneElement(icon, {
		color: isActive ? '#4892E0' : '#000033',
	})

	return (
		<Link
			prefetch={false}
			href={link}
			className={`min-w-32 xl:min-w-full flex flex-col items-center justify-center gap-2 2xl:gap-4 py-4 xl:py-5 2xl:py-7 px-2 xl:px-2 2xl:px-4 border-b-2 border-background text-center cursor-pointer
				${isActive ? 'bg-background' : ''}
				hover:bg-background
				transition-all
				${customClassNames ? customClassNames : ''}`}
			onClick={(e) => {
				e.preventDefault()
				sendVerticalMenuCtaGA4Event(labelClicked)
				setTimeout(() => {
					window.location.href = link
				}, 300)
			}}
		>
			{updatedIcon}
			<p className={`text-xs 2xl:text-sm ${isActive ? 'text-secondary' : 'text-msc-blue'}`}>{children}</p>
		</Link>
	)
}
