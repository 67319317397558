'use client'

import FormFactory, { OptionsProps, SchemaItem } from '@/components/factory/formFactory'
import AgentService from '@/features/agent/agent-service'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { MSCOfficeMarketEmail } from '@/features/websphere/db2_dump/MSCOFFICEMARKETEMAIL'
import WebsphereService from '@/features/websphere/websphere-service'
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect'
import { useModalState } from '@/utils/hooks/useModalState'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../uiComponents'

export interface ContactUsFormInputs {
	agencyCode: number
	agencyName: string
	email: string
	reasonForContact: { value: string; label: string }
	issueDescription: string
}

export const ContactUsModalTemplate = () => {
	const [emailsOptions, setEmailsOptions] = useState<OptionsProps[]>([])
	const sitecoreStore = useSitecoreState((state) => state)
	const market = useCurrentMarket()

	const agencyCodeLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-CODE_AGENCY', 'Agency code')
	const agencyNameLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-AGENCY_NAME', 'Agency name')
	const emailLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-EMAIL_FROM', 'Email')
	const customerSupportReasonLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-SELECT_CONTACT_REASON_PERSONALIZED', 'Reason')
	const problemDescriptionLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-PROBLEM_DESCRIP', 'Problem description')
	const requiredLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-REQUIRED_FIELDS', 'Required')
	const sendLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-SEND', 'Send')
	const technicalSupportLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-MSC_CLUB_PERSONALIZED', 'Send')
	const bookingInquiriesLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-MSC_ONLINE_SUPPORT_PERSONALIZED', 'Send')
	const bookingManagementLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-SUGGESTION_COMPLAINT_PERSONALIZED', 'Send')
	const bookingCancellationLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-GENERAL_INFO_PERSONALIZED', 'Send')
	const informationRequestsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-LOST_AND_FOUND_PERSONALIZED', 'Send')

	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm<ContactUsFormInputs>()

	const emailsLabels = {
		HELPDESKREQUEST: technicalSupportLabel,
		HPR_CLUB: bookingInquiriesLabel,
		HPR_BOOK: bookingManagementLabel,
		HPR_COMMENT: bookingCancellationLabel,
		HPR_GENINFO: informationRequestsLabel,
	}

	useAsyncEffect(async () => {
		const emails = await WebsphereService.queryTable_MSCOFFICEMARKETEMAIL({
			select: ['HELPDESKREQUEST', 'HPR_CLUB', 'HPR_BOOK', 'HPR_COMMENT', 'HPR_GENINFO'],
			where: { OFFICE_MARKET: market.code },
		})

		if (emails.length === 0) return
		const options = Object.keys(emailsLabels).map((name) => {
			const email = emails[0][name as keyof MSCOfficeMarketEmail]
			return email ? { id: name, value: email, label: emailsLabels[name as keyof typeof emailsLabels] } : undefined
		})

		setEmailsOptions(options.filter((option) => option !== undefined) as OptionsProps[])
	}, [])

	const contactUsSchema: SchemaItem[] = [
		{ type: 'text', name: 'agencyCode', label: agencyCodeLabel, required: true },
		{ type: 'text', name: 'agencyName', label: agencyNameLabel, required: true },
		{ type: 'text', name: 'email', label: emailLabel, required: true },
		{
			type: 'select',
			name: 'reasonForContact',
			placeholder: customerSupportReasonLabel,
			required: true,
			options: emailsOptions,
		},
		{ type: 'textarea', name: 'issueDescription', label: problemDescriptionLabel, required: true },
	]

	return (
		<div className="flex flex-col gap-6 w-full">
			<form
				className="flex flex-col gap-6"
				onSubmit={handleSubmit(async (data) => {
					await AgentService.submitContactUsForm(data)
					useModalState.getState().openSuccessfulRequestModalTemplate()
				})}
			>
				{contactUsSchema.map((schemaItem, index) => (
					<div className="w-full" key={index}>
						<FormFactory control={control} errors={errors} {...schemaItem} />
					</div>
				))}
				<div className="flex flex-col gap-6 items-start">
					<Button type="submit" className="w-full normal-case" isSubmitting={isSubmitting}>
						{sendLabel}
					</Button>
					<div dangerouslySetInnerHTML={{ __html: requiredLabel }} />
				</div>
			</form>
		</div>
	)
}
