import React from 'react'

interface AngleRightCircleInterface {
	color?: string
}

export const AngleRightCircle = ({ color = '#000033' }: AngleRightCircleInterface) => {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g mask="url(#mask0_1_10831)">
				<path d="M12.675 12.7734L9.6 15.8484L11 17.2734L15.5 12.7734L11 8.27344L9.6 9.69844L12.675 12.7734ZM12 22.7734C10.6167 22.7734 9.31667 22.5109 8.1 21.9859C6.88333 21.4609 5.825 20.7484 4.925 19.8484C4.025 18.9484 3.3125 17.8901 2.7875 16.6734C2.2625 15.4568 2 14.1568 2 12.7734C2 11.3901 2.2625 10.0901 2.7875 8.87344C3.3125 7.65677 4.025 6.59844 4.925 5.69844C5.825 4.79844 6.88333 4.08594 8.1 3.56094C9.31667 3.03594 10.6167 2.77344 12 2.77344C13.3833 2.77344 14.6833 3.03594 15.9 3.56094C17.1167 4.08594 18.175 4.79844 19.075 5.69844C19.975 6.59844 20.6875 7.65677 21.2125 8.87344C21.7375 10.0901 22 11.3901 22 12.7734C22 14.1568 21.7375 15.4568 21.2125 16.6734C20.6875 17.8901 19.975 18.9484 19.075 19.8484C18.175 20.7484 17.1167 21.4609 15.9 21.9859C14.6833 22.5109 13.3833 22.7734 12 22.7734ZM12 20.7734C14.2333 20.7734 16.125 19.9984 17.675 18.4484C19.225 16.8984 20 15.0068 20 12.7734C20 10.5401 19.225 8.64844 17.675 7.09844C16.125 5.54844 14.2333 4.77344 12 4.77344C9.76667 4.77344 7.875 5.54844 6.325 7.09844C4.775 8.64844 4 10.5401 4 12.7734C4 15.0068 4.775 16.8984 6.325 18.4484C7.875 19.9984 9.76667 20.7734 12 20.7734Z" fill={color} />
			</g>
		</svg>
	)
}
