import { CurrentMarket } from '@/features/markets/models/market'
import { Result } from '../../../features/auth/models/authenticate-service-response'

type Websphere = 'forgot_password' | 'search-booking' | 'terms-and-conditions' | 'contact-us' | 'all-promotions' | 'group-booking' | 'help-center' | 'consent' | 'search-cruise' | 'user-account' | 'ta-portal-view' | 'ta-portal-external' | 'all-news-page' | 'news-page' | 'superuser-back-to-agency-selection' | 'logoff'

export type Name = Result | Websphere

export function _buildUrl(endpoint: string, market: CurrentMarket, params: { [key: string]: any } = {}, baseUrl: string): string {
	let stringUrl = baseUrl + endpoint
	stringUrl = resolvePlaceholders(stringUrl, market)
	return createUrl(stringUrl, params)
}

export function _provideUrl(name: Name, market: CurrentMarket, params: { [key: string]: any } = {}, baseUrl: string): string {
	let stringUrl = baseUrl + map(name)
	if (!stringUrl) throw new Error(`Redirection url for result "${name}" not found`)
	stringUrl = resolvePlaceholders(stringUrl, market)
	return createUrl(stringUrl, params)
}

function createUrl(url: string, params: { [key: string]: any }): string {
	const urlObject = new URL(url)
	Object.keys(params).forEach((paramName) => {
		if (params[paramName] === undefined) {
			console.warn(`Parameter ${paramName} is undefined for url ${url}`)
			return
		}

		urlObject.searchParams.append(paramName, params[paramName].toString())
	})
	return urlObject.toString()
}

function resolvePlaceholders(url: string, market: CurrentMarket): string {
	return url.replace('{defaults}', '{storeId}&{langId}&{marketCode}&catalogId=10001').replace('{storeId}', `storeId=${market.storeId}`).replace('{langId}', `langId=${market.language.langId}`).replace('{marketCode}', `marketCode=${market.code}`)
}

function map(name: string): string | null {
	if (name === 'forgot_password') return '/ResetPasswordGuestErrorView?{defaults}&state=forgetpassword'
	if (name === 'consent') return '/ConsentView?{defaults}'
	if (name === 'search-cruise') return '/SearchCruiseV3?{defaults}'
	if (name === 'search-booking') return `/BookingSearchView?{defaults}&fastSearchError=false`
	if (name === 'terms-and-conditions') return `/TermsAndConditions?{defaults}`
	if (name === 'contact-us') return `/Contacts?{defaults}`
	if (name === 'all-promotions') return `/PromotionView?{defaults}`
	if (name === 'group-booking') return `/GroupBookingView?{defaults}`
	if (name === 'help-center') return `/HelpCenterView?{defaults}`
	if (name === 'user-account') return `/UserAccountView?{defaults}`
	if (name === 'ta-portal-view') return `/TAPortalView?{defaults}`
	if (name === 'ta-portal-external') return `/TAPortalExternalView?{defaults}`
	if (name === 'all-news-page') return `/AllNewsPage?{defaults}`
	if (name === 'news-page') return `/NewsLandingPage?{defaults}`
	if (name === 'superuser-back-to-agency-selection') return '/SuperUserSetup?{defaults}'
	if (name === 'logoff') return '/Logoff?{defaults}&URL=WelcomePage'
	return null
}
