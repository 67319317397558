'use client'

import { User } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { createConfigForAuthSession } from '@/features/caching/lib/tanstack'

/**
 * Returns the current user or undefined
 */
export default function useLoggedUser(): User | undefined {
	const data = useQuery(createConfigForAuthSession())
	return data.data?.user
}
