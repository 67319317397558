// Handle "user already logged" modal

import WebsphereService from '@/features/websphere/websphere-service'
import { deleteWebsphereCookies } from '@/utils/cookies/actions/websphere-cookies'
import { Logger } from '@/utils/lib/logger-pino'

export interface PreLogonResponseType {
	loginEnforce: string
	loginSession: string
}

export interface ExistingSession {
	lastLoginDate: string
	lastLoginTime: string
}

const logger = new Logger('src/features/auth/actions/pre-logon')

export async function invokePreLogon(username: string, password: string): Promise<ExistingSession | null> {
	logger.debug('Deleting websphere cookies')
	await deleteWebsphereCookies()
	const response: PreLogonResponseType = await WebsphereService.get({
		command: 'PreLogonCmd',
		market: 'current',
		request: {
			logonId: username,
			logonPwd: password,
			myAcctMain: undefined,
			errorViewName: '',
			reLogonURL: 'LogonForm',
		},
	})

	logger.info(`PreLogon response: ${response.loginEnforce === '1' ? 'enforce' : 'no enforce'}`)
	if (response.loginEnforce === '1') {
		const [date, time] = response.loginSession.split(' ')
		return {
			lastLoginDate: date.replace(/-/g, '/'),
			lastLoginTime: time,
		}
	}

	return null
}
