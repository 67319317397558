'use client'
import { Button } from '@/components/uiComponents'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import Image from 'next/image'
import React, { useRef, useState } from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import delete_remove_backspace from '../../../../public/images/delete_remove_backspace.png'
import forward_arrow_white from '../../../../public/images/forward_arrow_white.png'
import infoIcon from '../../../../public/images/infoHoverIcon.png'

// -------------------------------------MOCK DATA---------------------------------------------------
const destinationsMock = ['Mediterraneo', 'Nord Europa', 'Caraibi e Antille', 'Dubai, Abu Dhabi & Qatar', 'Sud America', 'Sud Africa', 'Asia', 'Stati Uniti e Canada', 'MSC Grand Voyages', 'MSC World Cruise', 'Mini crociere', 'Long cruises']
const imageSrc = ['https://www.mscbook.com/images/sdl/B2B_PROMONEW_confidence_350x170_87-125742.jpg', 'https://www.mscbook.com/images/sdl/FUGA24-350X170_it_IT.jpg', 'https://www.mscbook.com/images/sdl/MSC-Book-OC-CAR-350x170_it_IT.jpg', 'https://www.mscbook.com/images/sdl/B2B_PROMONEW_confidence_350x170_87-125742.jpg', 'https://www.mscbook.com/images/sdl/FUGA24-350X170_it_IT.jpg', 'https://www.mscbook.com/images/sdl/MSC-Book-OC-CAR-350x170_it_IT.jpg']
// -------------------------------------MOCK DATA---------------------------------------------------

const DestinationStep = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const [destinationButtonClicked, setDestinationButtonClicked] = useState<number | undefined>(undefined)
	const [isMouseOverInfoIcon, setIsMouseOverInfoIcon] = useState<boolean>(false)
	const [textareaText, setTextareaText] = useState<string>('')
	const [isImageSelectionVisible, setIsImageSelectionVisible] = useState<boolean>(true)
	const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null)

	const swiperRef = useRef<SwiperCore | null>(null)
	const handlePrevSlide = () => {
		swiperRef.current?.slidePrev()
	}
	const handleNextSlide = () => {
		swiperRef.current?.slideNext()
	}

	const handleOnMouseEnter = () => {
		setIsMouseOverInfoIcon(true)
	}
	const handleOnMouseLeave = () => {
		setIsMouseOverInfoIcon(false)
	}

	const handleDestinationNameClick = (destinationName: string, index: number) => {
		const destinationElement = document.getElementById('choose-destination')
		if (destinationElement) {
			destinationElement.innerHTML = destinationName.toUpperCase()
			setDestinationButtonClicked(index)
		}
	}

	const handleSelectImageClick = (destinationIconSrc: string, index: number) => {
		const destinationImageElement = document.getElementById('flyer-visual-img') as HTMLImageElement
		if (destinationImageElement) {
			destinationImageElement.src = destinationIconSrc
			setActiveImageIndex((prevIndex) => (prevIndex === index ? null : index))
		}
	}

	const handleAdditionalParagraphOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const flyerDescription = document.getElementById('flyer-description')
		const newValue = event.target.value
		if (flyerDescription) {
			flyerDescription.innerHTML = newValue
			setTextareaText(newValue)
		}
	}

	const visibleTermsAndConditionsLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-BUILD_YOUR_FLYER-VISIBLE-TERMS-AND-CONDITIONS', 'I agree not to misuse or sell MSC Cruises trademarks, logos, or assets.')
	const addParagraphLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-BUILD_YOUR_FLYER-ADD-PARAGRAPH', 'Add an optional text paragraph')
	const termsAndConditionsTitleLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-BUILD_YOUR_FLYER-TERMS-AND-CONDITIONS-TITLE', 'Terms and conditions')
	const selectAnImageLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-BUILD_YOUR_FLYER-SELECT-AN-IMAGE', 'Select an image')
	const chooseADestinationLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-BUILD_YOUR_FLYER-CHOOSE-A-DESTINATION', 'Choose a destination')
	const termsAndConditionsBodyLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-BUILD_YOUR_FLYER-TERMS-AND-CONDITIONS-BODY', 'The MSC Cruises trademarks, the logo and all other distinguishing symbols of MSC Cruises S.A. are registered rights and therefore the exclusive property of MSC Cruises S.A. Any non-authorized use thereof is not permitted. The user undertakes not to sell or in any other way misuse the access to and/or the right to use the material and the visual assets included.​')

	const termsAndConditionsPopover = (
		<div
			role="tooltip"
			area-label="terms and conditions popover"
			className="p-4 w-80 absolute bottom-[165%] left-[70%] transform -translate-x-1/2 bg-white 
      shadow-md text-[#1a1a1a] text-sm font-normal leading-6 z-[1000] shadow-md"
		>
			<span className="font-bold shadow-2xl">{termsAndConditionsTitleLabel}</span>
			<p>{termsAndConditionsBodyLabel}</p>
			<div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white rotate-45 shadow-md"></div>
		</div>
	)

	return (
		<div className="flex flex-col gap-4">
			<h2 className="text-sm font-bold uppercase">{chooseADestinationLabel}</h2>
			<div className={`grid grid-cols-2 gap-3 ${destinationButtonClicked === undefined ? 'border-b border-[#BDBDBD] pb-6' : ''}`}>
				{destinationsMock?.map((destination, index) => {
					return (
						<Button
							key={index}
							onClick={() => {
								handleDestinationNameClick(destination, index)
							}}
							variant={destinationButtonClicked === index ? 'filledSecondary' : 'outlinedTertiary'}
						>
							<span className="text-sm leading-5">{destination}</span>
						</Button>
					)
				})}
			</div>
			<div className={destinationButtonClicked === undefined ? 'hidden' : 'flex flex-col gap-4'}>
				<div className="flex items-center justify-between">
					<h2 className="text-sm font-bold uppercase">{selectAnImageLabel}</h2>
					<MdOutlineKeyboardArrowRight
						onClick={() => setIsImageSelectionVisible(!isImageSelectionVisible)}
						className="cursor-pointer"
						size={22}
						style={{
							transform: isImageSelectionVisible ? 'rotate(90deg)' : 'rotate(270deg)',
							transition: 'transform 0.3s ease',
						}}
					/>
				</div>

				<div className={isImageSelectionVisible ? 'flex gap-2' : 'hidden'}>
					<Swiper
						onSwiper={(swiper) => (swiperRef.current = swiper)}
						slidesPerView={3}
						spaceBetween={8}
						style={{ maxWidth: '450px' }}
						navigation={{
							prevEl: '.custom-prev',
							nextEl: '.custom-next',
						}}
					>
						{imageSrc.map((src, index: number) => {
							return (
								<SwiperSlide key={index}>
									<Image width={150} src={src} alt="" className="cursor-pointer border-2 rounded" onClick={() => handleSelectImageClick(src, index)} />
									{activeImageIndex === index && <div className="absolute rounded border-4 border-black inset-0 bg-gradient-to-b from-transparent via-[rgba(0,0,0,0.197621)] to-[#000033] pointer-events-none z-0"></div>}
								</SwiperSlide>
							)
						})}
						<button onClick={handlePrevSlide} className="custom-prev absolute left-1 top-1/2 transform -translate-y-1/2 z-10">
							<Image src={forward_arrow_white} alt="Previous" className="w-8 h-8 rotate-180" />
						</button>

						<button onClick={handleNextSlide} className="custom-next absolute right-1 top-1/2 transform -translate-y-1/2 z-10">
							<Image src={forward_arrow_white} alt="Next" className="w-8 h-8" />
						</button>
					</Swiper>
				</div>

				<div className="flex gap-3">
					<input type="checkbox" />
					<span className="text-sm max-w-sm">
						{visibleTermsAndConditionsLabel}
						<span className="relative ">
							<Image src={infoIcon} alt="information icon" width={16} height={16} objectFit="contain" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className="inline-block ml-2 pb-1 cursor-pointer" layout="fixed"></Image>
							{isMouseOverInfoIcon && termsAndConditionsPopover}
						</span>
					</span>
				</div>

				<hr />

				<div className="flex items-center justify-between">
					<p>{addParagraphLabel}</p>
					<Image src={delete_remove_backspace} alt="delete remove backspace icon" width={24} height={24} objectFit="contain" layout="fixed"></Image>
				</div>
				<textarea className="border-2 p-2 border-customBlue focus:outline-none" value={textareaText} onChange={handleAdditionalParagraphOnChange}></textarea>
			</div>
		</div>
	)
}

export { DestinationStep }

// import { useElasticFilterState } from '@/features/search-cruise/store/useElasticFilterState'

// const DestinationStep = () => {
// 	const destinations = useElasticFilterState(state => state.options.area)

// 	return (
// 		<div>
// 			<h2 className="text-sm font-bold uppercase">Choose a destination</h2>
// 			{
// 				destinations.map( dest => (
// 					<div
// 						key={dest.value + dest.label}
// 						className="flex items-center gap-2 my-2"
// 					>
// 						<input type="checkbox" name={dest.label} id={dest.label} />
// 						<label htmlFor={dest.label}>{dest.label}</label>
// 					</div>

// 				))
// 			}
