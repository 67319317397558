import { IOption, IOptionGroup } from '@/features/search-cruise/store/types'
import { isIOptionArray, isIOptionGroup } from '@/features/search-cruise/lib/utils'
import { ElasticFilter } from '@/features/search-cruise/model/elastic-filter'
import IAttribute = ElasticFilter.Service.IAttribute
import { IElasticFilterLabels } from '@/features/search-cruise/store/elastic-filter-store-utils'

export function getCheckedOptions(options: IOption[] | IOptionGroup[]): IOption[] {
	if (isIOptionArray(options)) {
		return (options as IOption[]).filter((option) => option && option.checked)
	} else {
		return (options as IOptionGroup[]).flatMap((group) => group.options).filter((option) => option && option.checked)
	}
}
export function getCheckedOptionsValueConcatenated(options: IOption[] | IOptionGroup[]): string {
	const checkedOptions = getCheckedOptions(options)
	if (checkedOptions?.length > 1) {
		return checkedOptions.map((option) => option.value).join(',')
	} else {
		return checkedOptions[0]?.value || ''
	}
}
/**
 * Updates the options or option groups based on new data while preserving existing states.
 *
 * This function handles the following scenarios:
 * 1. Initializing options when there are no old options.
 * 2. Updating individual options.
 * 3. Updating option groups.
 *
 * It preserves the checked state of existing options, disables options that are no longer available,
 * and keeps previously checked options visible even if they're not in the new set.
 * It also handles highlighting of options based on the provided highlightedValues.
 *
 * @param oldOptions - The existing options or option groups, if any.
 * @param newOptions - The new set of options or option groups to update to.
 * @param highlightedValues - An array of option values that should be highlighted.
 * @returns An updated array of IOption or IOptionGroup objects.
 */
export function updateOptions(oldOptions: IOption[] | IOptionGroup[] | undefined, newOptions: IOption[] | IOptionGroup[], highlightedValues: string[]): IOption[] | IOptionGroup[] {
	// Creates a new option with default values and highlighting
	function createNewOption(option: IOption): IOption {
		return {
			...option,
			checked: option.checked || false,
			disabled: false,
			hidden: false,
			highlighted: highlightedValues.includes(option.value),
		}
	}

	// Updates a single option, preserving its checked state if it exists in new options
	function updateSingleOption(oldOption: IOption | undefined, newOptionsArray: IOption[]): IOption {
		const matchingNewOption = newOptionsArray.find((option) => option.value === oldOption?.value)

		if (matchingNewOption) {
			// If the option still exists, update it while preserving its checked state
			return {
				...createNewOption(matchingNewOption),
				checked: oldOption?.checked || false,
			}
		}

		if (oldOption) {
			// If the old option no longer exists in new options, disable it
			// Keep it visible if it was checked, otherwise hide it
			const opt = {
				...oldOption,
				disabled: true,
				hidden: !oldOption.checked,
				highlighted: highlightedValues.includes(oldOption.value),
			}

			return opt
		}

		// Fallback case (should not occur in normal operation)
		return createNewOption(newOptionsArray[0])
	}

	// Initializes options when there are no old options
	function initializeOptions(): IOption[] | IOptionGroup[] {
		if (isIOptionGroup(newOptions[0])) {
			// If dealing with option groups, initialize each group
			return (newOptions as IOptionGroup[]).map((group) => ({
				...group,
				options: group.options.map(createNewOption),
			}))
		}
		// If dealing with individual options, initialize each option
		return (newOptions as IOption[]).map(createNewOption)
	}

	// Updates option groups, handling cases where groups or options within groups may have changed
	function updateOptionGroups(oldGroups: IOptionGroup[]): IOptionGroup[] {
		return oldGroups.map((oldGroup) => {
			const matchingNewGroup = (newOptions as IOptionGroup[]).find((group) => group.groupName === oldGroup.groupName)

			if (matchingNewGroup) {
				// If the group still exists, update its options
				return {
					...oldGroup,
					options: oldGroup.options.map((oldOption) => updateSingleOption(oldOption, matchingNewGroup.options)),
				}
			}

			// If the group no longer exists, disable and potentially hide its options
			return {
				...oldGroup,
				options: oldGroup.options.map((oldOption) => ({
					...oldOption,
					disabled: true,
					hidden: !oldOption.checked, // Keep visible if it was checked
					highlighted: highlightedValues.includes(oldOption.value),
				})),
			}
		})
	}

	// Updates individual options when not dealing with option groups
	function updateIndividualOptions(oldOptions: IOption[]): IOption[] {
		return oldOptions.map((oldOption) => updateSingleOption(oldOption, newOptions as IOption[]))
	}

	// Main logic of the function
	if (!oldOptions || oldOptions.length === 0) {
		// If there are no old options, initialize with new options
		return initializeOptions()
	}

	if (isIOptionGroup(oldOptions[0])) {
		// If dealing with option groups, update groups
		return updateOptionGroups(oldOptions as IOptionGroup[])
	}

	// If dealing with individual options, update options
	return updateIndividualOptions(oldOptions as IOption[])
}
export function createOptions(list: IAttribute[]): IOption[] {
	return list.map((value) => ({
		label: value.attributeValue.toUpperCase(),
		value: value.attributeCode || '',
		checked: false,
		disabled: false,
	}))
}

export function createRatesOptions(labels: IElasticFilterLabels['rates'], values: IElasticFilterLabels['ratesToShow']): IOption[] {
	return values.map((value: string) => ({
		label: labels[value as keyof typeof labels],
		value,
		checked: false,
		disabled: false,
	}))
}

export function noChecks(options: IOption[] | IOptionGroup[]): boolean {
	if (isIOptionArray(options)) {
		return (options as IOption[]).every((option) => !option.checked)
	} else {
		return (options as IOptionGroup[]).every((option) => option.options.every((subOption) => !subOption.checked))
	}
}
