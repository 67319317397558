'use client'
import React from 'react'
import { Button } from '../uiComponents'
import { FaPlus, FaMinus } from 'react-icons/fa6'

interface NumberInputProps {
	value: number
	onIncrement: () => void
	onDecrement: () => void
	isDecrementDisabled?: boolean
	isIncrementDisabled?: boolean
}

const NumberInput = ({ value, onIncrement, onDecrement, isDecrementDisabled, isIncrementDisabled }: NumberInputProps) => (
	<div className="mt-1 flex items-center" onMouseDown={(e) => e.stopPropagation()}>
		<Button variant="incrementableInput" onClick={!isDecrementDisabled ? onDecrement : undefined} disabled={isDecrementDisabled}>
			<FaMinus color="#000033" size={12} />
		</Button>
		<span className="mx-2 w-4 text-center">{value}</span>
		<Button variant="incrementableInput" onClick={!isIncrementDisabled ? onIncrement : undefined} disabled={isIncrementDisabled}>
			<FaPlus color="#000033" size={12} />
		</Button>
	</div>
)

export { NumberInput }
