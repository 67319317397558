const mockedFlyerTemplate = `<main style="display: flex; flex-direction: column; width: 100%">
    <section style="display: flex; align-items: center; justify-content: center;width: 100%; background-color: #182541; padding: 24px 0">
        <p style="color: white">MSC LOGO</p>
    </section>
    <section id="flyer-visual" style="position: relative; width: 100%; height: 200px; background-color: #5ac5f3; background-size: cover; background-position: center center">
        <img id="flyer-visual-img" src='/' style="width: 100%; height: 200px; background-color: #5ac5f3; background-size: cover; background-position: center center"><img/>
        <p id="choose-destination" style="position: absolute; top: 12px; left: 50%; transform: translateX(-50%); margin: 0; text-align: center; color: black; z-index: 1; font-size: 16px; font-weight: 700;"></p>
    </section>
    <section style="text-align: center; margin: 8px 0">
        <p id="flyer-title" style="font-size: 20px; line-height: 1.1; color: #000033; max-width: 440px; margin: 8px auto; text-transform: uppercase">Saectecae verum con ero et enistrunt lab idendit asperibus.</p>
        <p id="flyer-description" style="line-height: 1.2; color: #000033; margin: 8px 0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </section>
    <!-- If only one itinerary -->
    <section id="flyer-itinerary-single">
        <div style="display: flex; gap: 16px">
            <div class="itinerary-image" style="flex: 1; height: 180px; border-radius: 8px; background-color: #d4edfc; background-size: cover; background-position: center center"></div>
            <div style="flex: 1">
                <p class="destination-text" style="color: #EE732F; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">Destination name</p>
                <p class="ship-text" style="color: #000033; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">MSC Ship name</p>
                <p style="font-size: 14px; line-height: 1.2;">Departure from <span class="departure-text">XXXXXXXXX</span></p>
                <p style="font-size: 14px; line-height: 1.2;">On <span class="xxxxxx-text">XXXXXXXXX</span></p>
                <p style="font-size: 14px; line-height: 1.2;">From <span class="from-text">month</span> to <span class="to-day-text">month</span>, <span class="to-year-text">Year</span></p>
                <p style="font-size: 14px; line-height: 1.2;"><span class="days-legth-text">XX</span> days - <span class="nights-length-text">XX</span> nights</p>
                <p style="color: #000033; font-weight: 700; text-transform: uppercase">Package included</p>
            </div>
        </div>
        <div style="display: flex; align-items: center; padding: 8px; margin-top: 24px; border-radius: 16px; border: 1px solid #000033;">
            <div style="flex: 1; padding: 0px 16px">
                <img src="" style="width: auto; height: 60px; background-color: #5ac5f3;" />
            </div>
            <div style="flex: 2; padding: 0px 16px; border-left: 1px solid #000033; border-right: 1px solid #000033">
                <p style="font-size: 13px; line-height: 1.1;">Starting from <span style="font-size: 20px; line-height: 1.1;">€0.000</span> p.p. + service charge €00 p.p.</p>
            </div>
            <div style="flex: 2; padding: 0px 16px; text-align: center">
                <p style="font-size: 13px; line-height: 1.1;; font-weight: 700">TOTAL PRICE FROM</p>
                <p style="font-weight: 700"><span style="font-size: 30px; line-height: 1.1;" id="total-price">€0.000</span> p.p.</p>
            </div>
        </div>
        <p style="font-size: 10px; line-height: 1.2; color: #000033; margin: 8px 0; text-align: center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget metus nec odio ultricies ultricies. Nullam at purus vitae metus ultricies ultricies. Sed eget metus nec odio ultricies ultricies. Nullam at purus vitae met</p>
    </section>
    <!-- If multiple itinerary -->
    <section id="flyer-itinerary-multiple-1" style="display: block;">
        <div style="display: flex; flex-direction: row; align-items: center; gap: 24px">
            <div class="itinerary-image" style="flex: 1; height: 180px; border-radius: 8px; background-color: #d4edfc; background-size: cover; background-position: center center"></div>
            <div style="flex: 1.5">
                <p class="destination-text" style="color: #EE732F; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">Destination name</p>
                <p class="ship-text" style="color: #000033; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">MSC Ship name</p>
                <p style="font-size: 14px; line-height: 1.2;"><span class="day-start">3 october 2025</span> for <span class="nights-length-text">7</span> nights</p>
                <div>
                    <p style="font-size: 14px; line-height: 1.2; font-weight: 700; text-transform: uppercase;"><span class="itinerary-from">Manchester</span> & <span class="itinerary-from">Dublin</span> flights included</p>
                </div>
                <div style="display: flex; align-items: center; padding: 8px; margin-top: 8px; border-radius: 16px; border: 1px solid #000033;">
                    <div style="flex: 1; padding: 0px 8px; border-right: 1px solid #000033">
                        <img src="" style="width: auto; height: 60px; background-color: #5ac5f3;" />
                    </div>
                    <div style="flex: 2; padding: 0px 16px; text-align: center">
                        <p style="font-size: 11px; line-height: 1; font-weight: 700; text-transform: uppercase">Starting from</p>
                        <p style="font-weight: 700"><span style="font-size: 30px; line-height: 1.1;" class="total-price">€0.000</span> p.p.</p>
                        <p style="font-size: 11px; line-height: 1;">Luggages included</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>`

export default mockedFlyerTemplate
