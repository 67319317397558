import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { Name, _provideUrl } from '../lib/url-provider'

export const useUrlProvider = () => {
	const market = useCurrentMarket()
	const environmentState = useEnvironmentState()
	const endpoint = environmentState.getStringOrThrow('WEBSPHERE_BASE_ENDPOINT') + '/shop'

	return (name: Name, params?: { [key: string]: any }) => {
		return _provideUrl(name, market, params, endpoint)
	}
}
