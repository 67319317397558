import React from 'react'

interface AgentsDetailsIconInterface {
	color?: string
}

export const AgentsDetailsIcon = ({ color = '#000033' }: AgentsDetailsIconInterface) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g mask="url(#mask0_2050_29312)">
				<path d="M11 20V11.85L8.4 14.45L7 13L12 8L17 13L15.6 14.45L13 11.85V20H11ZM4 9V6C4 5.45 4.19583 4.97917 4.5875 4.5875C4.97917 4.19583 5.45 4 6 4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V9H18V6H6V9H4Z" fill={color} />
			</g>
		</svg>
	)
}
