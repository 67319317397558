import { highlightedMockArea, highlightedMockCabin, highlightedMockDepPort, highlightedMockShip } from '@/features/search-cruise/configs/highlights'
import { OCCUPANCY_CONFIG } from '@/features/search-cruise/configs/occupancy'
import { ElasticFilter } from '@/features/search-cruise/model/elastic-filter'
import { SearchCruise } from '@/features/search-cruise/model/search-cruise'
import { AdditionalFilterData, FILTER_TYPE, IOption, IOptionGroup, OccupancyFilterType } from '@/features/search-cruise/store/types'
import { createCabinListOptions } from '@/features/search-cruise/store/utils/cabin-utils'
import { createOptions, updateOptions } from '@/features/search-cruise/store/utils/option-utils'
import IAttribute = ElasticFilter.Service.IAttribute
import IOccupancyTypes = SearchCruise.Occupancy.IOccupancyTypes
import IOccupancyRules = SearchCruise.Occupancy.IOccupancyRules

/**
 * Creates an array of IOption objects representing duration options based on a list of attributes.
 *
 * This function processes a list of duration attributes and converts them into a set of unique,
 * sorted IOption objects. It categorizes durations into three groups based on the durationChunks
 * defined in OCCUPANCY_CONFIG, plus an additional "any duration" option:
 * 1. Any duration (no filter)
 * 2. Durations less than the first chunk
 * 3. Durations between the first and second chunk
 * 4. Durations greater than the second chunk
 *
 * The resulting options are sorted by their numeric value, duplicates are removed,
 * and a common duration label is appended to each option (except the "any duration" option).
 *
 * @param list - An array of IAttribute objects, each containing a duration value.
 * @param anyDurationLabel - The label for the "any duration" option (e.g., "Any duration").
 * @param commonDurationLabel - A common label to append to duration options (e.g., "Nights").
 * @returns An array of IOption objects representing the unique, sorted duration options.
 */
export function createDurationOptions(list: IAttribute[], anyDurationLabel: string, commonDurationLabel: string): IOption[] {
	// Helper function to determine the label and value for a given duration
	const getDurationLabelAndValue = (value: string) => {
		const duration = parseInt(value)
		const [minDuration, maxDuration] = OCCUPANCY_CONFIG.durationChunks

		if (duration > maxDuration) {
			// For durations longer than the maximum chunk
			return {
				label: `${maxDuration + 1}+ ${commonDurationLabel}`,
				value: 2,
			}
		}
		if (duration < minDuration) {
			// For durations shorter than the minimum chunk
			return {
				label: `1-${minDuration - 1} ${commonDurationLabel}`,
				value: 0,
			}
		}
		// For durations between min and max chunks
		return {
			label: `${minDuration}-${maxDuration} ${commonDurationLabel}`,
			value: 1,
		}
	}

	// Create the "any duration" option
	const anyDurationOption: IOption = {
		label: anyDurationLabel,
		value: '',
		checked: true,
		disabled: false,
	}

	// Process the list of attributes into IOption objects
	const options = list
		.map((duration) => {
			const { label, value } = getDurationLabelAndValue(duration.attributeValue)
			return {
				label: label,
				value: value.toString(),
				checked: false,
				disabled: false,
			}
		})
		// Sort options by their numeric value
		.sort((a, b) => parseInt(a.value) - parseInt(b.value))
		// Remove duplicate options based on their value
		.filter((value, index, self) => self.findIndex((t) => t.value === value.value) === index)

	// Add the "any duration" option at the beginning of the array
	return [anyDurationOption, ...options]
}

export function createGroupedOptions(options: IOption[]): IOptionGroup[] {
	const groupedOptions: IOptionGroup[] = []
	options
		.filter((o) => o.label.includes(','))
		.forEach((option) => {
			const groupName = option.label.split(',')[1].trim()
			const group = groupedOptions.find((group) => group.groupName === groupName)
			option.label = option.label.split(',')[0].trim()
			if (group) {
				group.options.push(option)
			} else {
				groupedOptions.push({ groupName, options: [option] })
			}
		})

	return groupedOptions
}

interface MapToOptionsParams {
	fetchedResults: {
		shipList: ElasticFilter.Service.IAttribute[]
		departurePortList: ElasticFilter.Service.IAttribute[]
		departureMonthList: ElasticFilter.Service.IAttribute[]
		areaList: ElasticFilter.Service.IAttribute[]
		cabinCategory: ElasticFilter.Service.ICabinCategory[]
		durationList: ElasticFilter.Service.IAttribute[]
		departureList: ElasticFilter.Service.IAttribute[]
	}
	labels?: IElasticFilterLabels
}

export function mapToOptions({ fetchedResults, labels }: MapToOptionsParams) {
	const departurePortOptions: IOption[] = createOptions(fetchedResults.departurePortList)
	const areaOptions: IOption[] = createOptions(fetchedResults.areaList)
	const shipOptions: IOption[] = createOptions(fetchedResults.shipList)
	const departureMonthOptions: IOption[] = createOptions(fetchedResults.departureMonthList)
	const cabinListOptions: IOption[] = createCabinListOptions(fetchedResults.cabinCategory[0].cabinList, labels)

	const durationOptions: IOption[] = labels ? createDurationOptions(fetchedResults.durationList, labels.duration.anyDur!, labels.duration.nights!) : []

	//Process IOptionGroups from the fetched results
	const departurePortGroups: IOptionGroup[] = createGroupedOptions(departurePortOptions)
	const departureList: IOption[] = createOptions(fetchedResults.departureList)

	return {
		areaOptions,
		shipOptions,
		durationOptions,
		departureMonthOptions,
		cabinListOptions,
		departurePortGroups,
		departureList,
	}
}

export function canModifyOccupancy(currentOccupancy: IOccupancyTypes, occupantType: OccupancyFilterType, change: number, rules: IOccupancyRules): boolean {
	function isValidOccupancy(occupancy: IOccupancyTypes, rules: IOccupancyRules): boolean {
		const isCombinationAllowed = rules.allowedCombinations.some((allowedCombo) => allowedCombo.adults === occupancy.adults && allowedCombo.children === occupancy.children && allowedCombo.juniorChildren === occupancy.juniorChildren && allowedCombo.infants === occupancy.infants)

		if (!isCombinationAllowed) {
			return false
		}

		const { maxAdults, maxChildren, maxTotal, infantWithAdult } = rules.additionalRules
		const totalOccupants = occupancy.adults + occupancy.children + occupancy.juniorChildren + occupancy.infants

		return !(occupancy.adults > maxAdults || occupancy.children > maxChildren || totalOccupants > maxTotal || (infantWithAdult && occupancy.infants > 0 && occupancy.adults === 0))
	}

	const newOccupancy = { ...currentOccupancy }
	newOccupancy[occupantType] += change

	// Ensure occupancy values don't go below zero
	if (Object.values(newOccupancy).some((value) => value < 0)) {
		return false
	}

	return isValidOccupancy(newOccupancy, rules)
}

export const formatDate = (date: Date | undefined): string | undefined => {
	if (!date) return undefined
	const day = date.getDate().toString().padStart(2, '0')
	const month = (date.getMonth() + 1).toString().padStart(2, '0')
	const year = date.getFullYear()
	return `${day}/${month}/${year}`
}

// eslint-disable-next-line prettier/prettier
export function processUpdateFilters(fetchedResults: ElasticFilter.Service.IResponse['results'], currentState: ElasticFilter.IView, labels?: IElasticFilterLabels, additionalData?: AdditionalFilterData, typeChanged?: FILTER_TYPE): ElasticFilter.ISelectedFilter {
	manipulateServiceResponse(fetchedResults, additionalData)
	const options = mapToOptions({ fetchedResults, labels })

	const newState: ElasticFilter.ISelectedFilter = {
		...currentState.options,
		embkPort: updateOptions(currentState.options.embkPort, options.departurePortGroups, currentState.highlighted.embkPort || []) as IOptionGroup[],
		area: updateOptions(currentState.options.area, options.areaOptions, currentState.highlighted.area || []) as IOption[],
		ship: updateOptions(currentState.options.ship, options.shipOptions, currentState.highlighted.ship || []) as IOption[],
		duration: updateOptions(currentState.options.duration, options.durationOptions, []) as IOption[],
		departureMonth: updateOptions(currentState.options.departureMonth, options.departureMonthOptions, []) as IOption[],
		departureList: options.departureList || [],
		cabinCategory: updateOptions(currentState.options.cabinCategory, options.cabinListOptions, currentState.highlighted.cabinCategory || []) as IOption[],
	}

	if (typeChanged) {
		newState[typeChanged] = currentState.options[typeChanged] as never
	}
	return newState
}

export const elasticFilterInitialState: ElasticFilter.IView = {
	loading: false,
	error: undefined,
	options: {
		adults: OCCUPANCY_CONFIG.defaultOccupancy.adults,
		children: OCCUPANCY_CONFIG.defaultOccupancy.children,
		juniorChildren: OCCUPANCY_CONFIG.defaultOccupancy.juniorChildren,
		infants: OCCUPANCY_CONFIG.defaultOccupancy.infants,
		area: [],
		embkPort: [],
		departureMonth: [],
		ship: [],
		cabinCategory: [],
		duration: [],
		departureList: [],
		departureFrom: undefined,
		departureTo: undefined,
		rates: [],
		priceRange: { min: 0, max: 20000 },
	},
	highlighted: {
		area: highlightedMockArea,
		embkPort: highlightedMockDepPort,
		ship: highlightedMockShip,
		cabinCategory: highlightedMockCabin,
	},
}

// New interface for labels
export interface IElasticFilterLabels {
	cabin: {
		oceanView: string
		balcony: string
		interior: string
		yacht: string
		suite: string
	}
	duration: {
		anyDur: string
		nights: string
	}
	rates: {
		CHK_AIR: string
		CHK_VOYAGES: string
		CHK_EXCLUSIVES: string
		CHK_INCLUDED: string
		CHK_SPECIAL: string
		'PRICE_TYPE-A_NEW': string
		'PRICE_TYPE-B_NEW': string
		'PRICE_TYPE-C_NEW': string
	}
	ratesToShow: string[]
	price: {
		step: number
		from: number
		to: number
	}
}

const manipulateServiceResponse = (fetchedResults: ElasticFilter.Service.IResponse['results'], additionalData?: AdditionalFilterData) => {
	if (!additionalData) return fetchedResults
	fetchedResults.areaList = aggregateAreas(fetchedResults.areaList, additionalData.areaAggregations)
}

const aggregateAreas = (areas: IAttribute[], aggregations: string[]): IAttribute[] => {
	const agregationInserted: { [key: string]: boolean } = {}
	const result: IAttribute[] = []
	for (const area of areas) {
		const aggregationFound = aggregations.find((aggregation) => aggregation.match(`${area.attributeCode},|,${area.attributeCode}`))

		if (!aggregationFound) {
			result.push(area)
			continue
		}

		if (agregationInserted[aggregationFound]) continue

		agregationInserted[aggregationFound] = true
		result.push({ attributeValue: area.attributeValue, attributeCode: aggregationFound })
	}
	return result
}
