import { Promotion } from '@/features/sitecore/actions/promotions'
import 'swiper/css'
import { PromotionsSectionTemplate } from '../uiComponents'

interface PromotionsSectionProps {
	promotions: Promotion[]
}

const PromotionsSection = (props: PromotionsSectionProps) => {
	return <PromotionsSectionTemplate promotionData={props.promotions} />
}

export { PromotionsSection }
