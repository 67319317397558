'use client'

import React, { useState } from 'react'
import { FaBars } from 'react-icons/fa6'
import { CountryFlag, MobileNavMenu } from '../uiComponents'
import { usePersistentHeaderMenu } from '@/features/header-menu/hooks/usePersistentHeaderMenu'

export interface ResponseProps {
	countryFlag: React.ReactElement<typeof CountryFlag>
}

const MobileHeader = (props: ResponseProps) => {
	const [menuActive, setMenuActive] = useState(false)
	const menu = usePersistentHeaderMenu()
	const handleClickOpen = () => setMenuActive(true)
	const handleClickClose = () => setMenuActive(false)

	if (!menu) return null

	return (
		<>
			{!menuActive && (
				<div className={`fixed flex desktop-min-1200px:hidden justify-between px-6 z-51`}>
					<button onClick={handleClickOpen}>
						<FaBars size={24} />
					</button>
				</div>
			)}
			{menuActive && <MobileNavMenu handleClickClose={handleClickClose} menu={menu} countryFlag={props.countryFlag} />}
		</>
	)
}

export { MobileHeader }
