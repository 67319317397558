import React, { ReactNode } from 'react'

interface NavDropdownBackgroundProps {
	children: ReactNode
}

function NavDropdownBackground({ children }: NavDropdownBackgroundProps) {
	return <div className="fixed left-0 mt-6 bg-white border z-10 w-screen z-20">{children}</div>
}

export { NavDropdownBackground }
