'use client'

import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React, { useEffect, useState } from 'react'

interface RangeStepsProp {
	min: number
	max: number
	step: number
}
export interface RangeSliderProps {
	range: RangeStepsProp
	shouldResetPriceRange: boolean
	setShouldResetPriceRange: (value: boolean) => void
	handleRangeChange: (min: number, max: number) => void
}

export const RangeSlider = (props: RangeSliderProps) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const { min, max, step } = props.range
	const [minValue, setMinValue] = useState(min)
	const [maxValue, setMaxValue] = useState(max)

	const { shouldResetPriceRange } = props
	useEffect(() => {
		if (props.shouldResetPriceRange) {
			setMinValue(min)
			setMaxValue(max)
			props.setShouldResetPriceRange(false)
		}
	}, [shouldResetPriceRange])

	const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Math.min(Number(event.target.value), maxValue - step)
		setMinValue(value)
		props.handleRangeChange(value, maxValue)
	}

	const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Math.max(Number(event.target.value), minValue + step)
		setMaxValue(value)
		props.handleRangeChange(minValue, value)
	}

	// Handle user input in the text input fields
	const handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value.replace(/[^\d]/g, '')) // Remove non-digit characters
		setMinValue(value)
	}

	const handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value.replace(/[^\d]/g, '')) // Remove non-digit characters
		setMaxValue(value)
	}

	// Handle blurring of the input fields, correcting any values outside the range or step
	const handleMinInputBlur = () => {
		let value = minValue

		if (value < min) value = min
		if (value > maxValue - step) value = maxValue - step
		// value = roundToStep(value)

		setMinValue(value)
		props.handleRangeChange(value, maxValue)
	}

	const handleMaxInputBlur = () => {
		let value = maxValue

		if (value > max) value = max
		if (value < minValue + step) value = minValue + step
		// value = roundToStep(value)

		setMaxValue(value)
		props.handleRangeChange(minValue, value)
	}

	// Ensure that left and right positions are between 0% and 100%
	const calculateLeftPosition = (value: number) => {
		const position = ((value - min) / (max - min)) * 100
		return Math.max(0, Math.min(100, position)) // Ensures position is between 0 and 100
	}

	const calculateRightPosition = (value: number) => {
		const position = ((value - min) / (max - min)) * 100
		return Math.max(0, Math.min(100, position)) // Ensures position is between 0 and 100
	}
	const rangeInterval1 = min + (max - min) / 4
	const rangeInterval2 = min + (max - min) / 2
	const rangeInterval3 = min + ((max - min) * 3) / 4

	const minimumPrice = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_MIN_PRICE', 'Minimum Price')
	const maximumPrice = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_MAX_PRICE', 'Maximum Price')

	return (
		<div className="w-full pt-4">
			<div className="relative w-full">
				<input type="range" min={min} max={max} step={step} value={minValue} onChange={handleMinChange} className="range-input absolute z-20 h-2 appearance-none w-full bg-transparent pointer-events-none" aria-label="Prezzo minimo" />
				<input type="range" min={min} max={max} step={step} value={maxValue} onChange={handleMaxChange} className="range-input absolute z-20 h-2 appearance-none w-full bg-transparent pointer-events-none" aria-label="Prezzo massimo" />

				<div className="relative h-1 w-full bg-gray-200 rounded">
					<div
						className="absolute h-full bg-msc-blue rounded"
						style={{
							left: `${calculateLeftPosition(minValue)}%`,
							right: `${100 - calculateRightPosition(maxValue)}%`,
						}}
					></div>
				</div>
			</div>

			<div className="flex justify-between mt-2" aria-hidden="true">
				{/* Conditionally apply color based on whether the value is within the range */}
				<span className={`flex-1 min-w-16 text-sm ${minValue <= min ? 'text-secondary' : 'text-gray-500'}`}>€{min}</span>
				<span className={`flex-2 text-center text-sm ${minValue <= rangeInterval1 && rangeInterval1 <= maxValue ? 'text-secondary' : 'text-gray-500'}`}>€{Math.round(rangeInterval1)}</span>
				<span className={`flex-2 text-center text-sm ${minValue <= rangeInterval2 && rangeInterval2 <= maxValue ? 'text-secondary' : 'text-gray-500'}`}>€{Math.round(rangeInterval2)}</span>
				<span className={`flex-2 text-center text-sm ${minValue <= rangeInterval3 && rangeInterval3 <= maxValue ? 'text-secondary' : 'text-gray-500'}`}>€{Math.round(rangeInterval3)}</span>
				<span className={`flex-1 text-right min-w-16 text-sm ${maxValue >= max ? 'text-secondary' : 'text-gray-500'}`}>€{max}+</span>
			</div>

			<div className="flex justify-between mt-4 gap-6">
				<div className="flex flex-col flex-1 gap-2">
					<label htmlFor="minPrice" className="text-sm">
						{minimumPrice}
					</label>
					<input id="minPrice" type="text" value={`€${minValue}`} onChange={handleMinInputChange} onBlur={handleMinInputBlur} className=" w-full border border-gray-400 rounded p-1 text-center" aria-label="Inserisci prezzo minimo" />
				</div>
				<div className="flex flex-col flex-1 gap-2">
					<label htmlFor="maxPrice" className="text-sm">
						{maximumPrice}
					</label>
					<input id="maxPrice" type="text" value={`€${maxValue}`} onChange={handleMaxInputChange} onBlur={handleMaxInputBlur} className=" w-full border border-gray-400 rounded p-1 text-center" aria-label="Inserisci prezzo massimo" />
				</div>
			</div>
		</div>
	)
}
