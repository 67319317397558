export const UPDATE_AGENT_EMAIL_COOKIE = {
	name: 'popupReminderMail',
	defaultValue: 'yes',
	maxAge: 24 * 60 * 60,
}

export const IMPORTANT_INFORMATION_COOKIE = {
	name: 'popupHomeShown',
	defaultValue: 'yes',
	maxAge: 24 * 60 * 60,
}

export const COOPERA_COOKIE = {
	name: 'popupHomeCoopera',
	defaultValue: 'yes',
	maxAge: 24 * 60 * 60,
}
