import { CurrentMarket } from '@/features/markets/models/market'

export function getAkamaiFilename(market: CurrentMarket): string {
	return `cached.translations.${market.language.path}`
}

export enum TranslationBundles {
	HOME = 'storetext_msc_IND_HomePage',
	WELCOME = 'storetext_msc_Login_WelcomePage',
	NOTIFICATIONS = 'storetext_msc_Notifications',
	CRUISE_RESULTS = 'storetext_msc_IND_CruiseResults',
	ENRICHMENT = 'storetext_msc_IND_Enrich',
	WHISHLIST = 'storetext_msc_WishList',
	ACCOUNT = 'storetext_msc_IND_Account',
	IMAGE_CROSS = 'storetext_msc_image_cross',
	FOOTER = 'storetext_msc_IND_Footer',
	HEADER = 'storetext_msc_IND_Header',
	CABIN_SELECTION = 'storetext_msc_IND_CabinSelection',
	CUSTOMER_SUPPORT = 'storetext_msc_IND_CustomerSupport',
	PROMOTION_SETTINGS = 'storetext_msc_promotion_settings',
	POPUP_REMINDER = 'storetext_msc_popupReminder',
	ORDER_MANAGEMENT_PASSENGER_DETAILS = 'storetext_msc_OM_OrderManagement_PassengerDetails',
	CHECKOUT = 'storetext_msc_IND_CheckOut',
	ORDER_MANAGEMENT_REVIEW = 'storetext_msc_OM_OrderManagement_Review',
	SIGNIN = 'storetext_msc_IND_SignIn_SignUp',
	CONFIRMATION = 'storetext_msc_IND_ConfirmationPage',
	REGISTRATION_LANGUAGES = 'storetext_msc_all_language',
	REGISTRATION_COUNTRIES = 'storetext_msc_all_country',
	AREA_AGGREGATIONS = 'storetext_msc_AreaCodeAggregation',
	SUPERUSER = 'storetext_msc_SuperUser',
	CONVERSION_RATE = 'storetext_msc_IND_exchange',
	TA_GROUP_REQUEST = 'storetext_msc_TA_GroupRequest',
}
