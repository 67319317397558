import React from 'react'

const AgentDetailsStep = () => {
	return (
		<div>
			<h2 className="text-sm font-bold uppercase">Upload your travel agency logo</h2>
		</div>
	)
}

export { AgentDetailsStep }
