'use client'

import FormFactory from '@/components/factory/formFactory'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { useForm, ValidationRule } from 'react-hook-form'
import { IoSearchSharp } from 'react-icons/io5'
import { Button } from '../uiComponents'

export interface SearchBookingDataProps {
	bookingId: string
}

const SearchBooking = () => {
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm<SearchBookingDataProps>({ mode: 'all' })

	const urlProvider = useUrlProvider()

	const handleSearchBooking = (data: SearchBookingDataProps) => {
		window.location.href = urlProvider('search-booking', {
			bookingID: data.bookingId ?? '', // if no bookingId is provided, redirect to the search-booking page without any Id
			tryToRetrive: true,
		})
	}
	const sitecoreStore = useSitecoreState((state) => state)
	const searchLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_SEARCH', 'Search')
	const bookingNumberLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_BOOKING_NUMBER', 'Booking number')
	const searchBookingLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-SEARCH_BOOKING', 'Search booking')
	const invalidBookingId = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-INVALID_BOOKING_ID', 'Invalid booking id')

	const validator: ValidationRule<RegExp> = {
		value: /^([0-9]*)$/,
		message: invalidBookingId,
	}

	return (
		<div className="flex relative flex-col bg-light-purple rounded-sm h-full py-9 px-8 z-10">
			<p className="text-white text-sm font-bold">{searchBookingLabel}</p>
			<form className="flex flex-col gap-4 mt-6" onSubmit={handleSubmit(handleSearchBooking)}>
				<FormFactory control={control} errors={errors} type="text" name="bookingId" label={bookingNumberLabel} variant="outlined" validator={validator} required={false} />
				<Button type="submit" variant="filled" icon={<IoSearchSharp />} className="mt-1" isSubmitting={isSubmitting}>
					{searchLabel}
				</Button>
			</form>
		</div>
	)
}

export { SearchBooking }
