import React from 'react'

const NotesStep = () => {
	return (
		<div>
			<h2 className="text-sm font-bold uppercase">Add additional notes</h2>
		</div>
	)
}

export { NotesStep }
