import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as HeaderMenuActions from './actions/header-menu'

const HeaderMenuService = createProxiedActions<typeof HeaderMenuActions>({
	modules: [
		HeaderMenuActions,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default HeaderMenuService
