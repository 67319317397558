import { useContext } from 'react'
import { useStore } from 'zustand'
import { ElasticFilterState } from '@/features/search-cruise/store/types'
import { ElasticFilterStoreContext } from '@/features/search-cruise/store/ElasticFilterProvider'

export function useElasticFilterState<T>(selector: (state: ElasticFilterState) => T): T {
	const store = useContext(ElasticFilterStoreContext)

	if (!store) throw new Error('Missing ElasticFilterProvider')
	return useStore(store, selector)
}
