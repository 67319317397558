'use client'

import React from 'react'
import { SitecoreKeysOverride } from '@/features/dev-tools/components/SitecoreKeysOverride'
import { HighlightLabels } from '@/features/dev-tools/components/HighlightLabels'
import { ShowBundleNames } from '@/features/dev-tools/components/ShowBundleNames'

export const DevToolsModalTemplate = () => {
	return (
		<div className="flex flex-col w-full pb-48">
			<SitecoreKeysOverride />
			<div className="mt-5" style={{ marginLeft: '-1rem' }}>
				<HighlightLabels />
				<ShowBundleNames />
			</div>
		</div>
	)
}
