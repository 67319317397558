/**
 * @file Defines functions for making WebSphere API calls using GET and POST methods.
 */

'use server'

import { getCurrentMarket } from '@/features/markets/actions/utils'
import { CurrentMarket } from '@/features/markets/models/market'
import { getEnvBoolean } from '@/utils/environment/env'
import { fetchWithTimeout } from '@/utils/lib/fetch-with-timeout'
import { Logger, Writer } from '@/utils/lib/logger-pino'
import { WEBSPHERE_ENDPOINT } from '../configs/endpoints'

const MAX_LOG_LENGTH = 200

const logger = new Logger('src/features/websphere/actions/fetch')

/**
 * Interface defining the structure of a response from a WebSphere API call.
 *
 * @property head The response headers.
 * @property body The parsed response body as type T.
 */
export type WebsphereResponse<T> = {
	head: Headers
	body: T
}

type Request = { [key: string]: any }

/**
 * Interface defining the options object used for WebSphere API calls.
 *
 * @property command The name of the WebSphere command to be invoked.
 * @property market (optional) The market information for the request, current means that it gonna use the actual market from locale
 * @property params (optional) An object containing additional parameters for the request.
 */
export interface FullFetchOptions {
	method: 'GET' | 'POST'
	command: string
	market?: 'current' | CurrentMarket
	request?: Request
	sendCookies?: string
	removeRequestParams?: boolean
}

/**
 * Fetches data from a WebSphere API endpoint using a GET request.
 *
 * @param options An object defining the command name, market (optional), and parameters.
 * @returns A Promise that resolves to a WebsphereResponse containing the response headers and parsed body.
 *
 * @throws Error if the response from WebSphere is not valid JSON.
 */
export async function fullFetch<T>({ method, command, market, request = {}, sendCookies, removeRequestParams = true }: FullFetchOptions): Promise<WebsphereResponse<T>> {
	if (!request) request = {}
	request.catalogId = '10001'
	let alogger: Writer = logger

	if (market) {
		const marketToSend = market === 'current' ? await getCurrentMarket() : market
		alogger = logger.of(marketToSend)
		request.storeId = String(marketToSend.storeId)
		request.langId = String(marketToSend.language.langId)
	}

	const headers: HeadersInit = sendCookies ? { Cookie: sendCookies } : {}
	const url = buildUrl(command, request)
	alogger.info(`Fetching data from websphere (method: ${method}, auth: ${sendCookies ? 'yes' : 'no'}): ${url.toString()}`)

	const fetchUrl = method === 'GET' ? url.toString() : url.origin + url.pathname
	alogger.debug(`Fetch url: ${fetchUrl}`)
	const response = await fetchWithTimeout(fetchUrl, {
		method,
		headers,
		credentials: 'include',
		body: method === 'POST' ? url.searchParams.toString() : undefined,
	})

	alogger.info(`Websphere command ${command}, response status: ${response.status}}`)
	const body = (await parseResponseBody(method, command, fetchUrl, request, response, removeRequestParams)) as T
	return { head: response.headers, body }
}

function buildUrl(command: string, request: { [key: string]: any }): URL {
	const url: URL = new URL(`${WEBSPHERE_ENDPOINT}/${command}`)
	Object.keys(request).forEach((name) => {
		if (request[name] instanceof Array) request[name].forEach((item) => url.searchParams.append(name, item))
		else url.searchParams.append(name, request[name])
	})
	return url
}

/**
 * Parses the response from a WebSphere API call.
 *
 * @param command The name of the WebSphere command.
 * @param url The URL of the request.
 * @param params The request parameters.
 * @param response The raw response string.
 * @returns A Promise that resolves to a WebsphereResponse containing the parsed headers and body.
 *
 * @throws Error if the response from WebSphere is not valid JSON.
 */
async function parseResponseBody<T>(method: 'GET' | 'POST', command: string, url: string, params: Request, response: Response, removeRequestParams: boolean): Promise<T> {
	const raw = (await response.text()).trim()
	let parsedBody: { [key: string]: any } = {}

	try {
		parsedBody = JSON.parse(raw.substring(2, raw.length - 2).trim())
		if (removeRequestParams) Object.keys(params).forEach((param) => delete parsedBody[param])
		return parsedBody as T
	} catch (e: any) {
		logger.error(`Invalid JSON response received from websphere: url=${url} response-length=${raw.length}. error: ${e.message}`)
		throw new Error(`Invalid JSON response received from websphere: url=${url} response-length=${raw.length}`, e)
	} finally {
		let body: any = parsedBody || raw
		if (!getEnvBoolean('PINO_LOG_JSON', false)) body = raw.length > MAX_LOG_LENGTH ? `${raw.substring(0, MAX_LOG_LENGTH)}...` : raw

		logger.service({
			url,
			method,
			request: params,
			response: { status: response.status, body },
		})
	}
}
