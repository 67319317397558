import React from 'react'

interface HelpIconInterface {
	color?: string
}

export const HelpIcon = ({ color = '#4892E0' }: HelpIconInterface) => {
	return (
		<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.5 20L9.25 17H9C6.63333 17 4.625 16.175 2.975 14.525C1.325 12.875 0.5 10.8667 0.5 8.5C0.5 6.13333 1.325 4.125 2.975 2.475C4.625 0.825 6.63333 0 9 0C10.1833 0 11.2877 0.220667 12.313 0.662C13.3383 1.10333 14.2383 1.71167 15.013 2.487C15.7877 3.26233 16.3957 4.16233 16.837 5.187C17.2783 6.21167 17.4993 7.316 17.5 8.5C17.5 9.75 17.2957 10.95 16.887 12.1C16.4783 13.25 15.92 14.3167 15.212 15.3C14.504 16.2833 13.6623 17.175 12.687 17.975C11.7117 18.775 10.6493 19.45 9.5 20ZM11.5 16.35C12.6833 15.35 13.646 14.179 14.388 12.837C15.13 11.495 15.5007 10.0493 15.5 8.5C15.5 6.68333 14.8707 5.146 13.612 3.888C12.3533 2.63 10.816 2.00067 9 2C7.18333 2 5.646 2.62933 4.388 3.888C3.13 5.14667 2.50067 6.684 2.5 8.5C2.5 10.3167 3.12933 11.854 4.388 13.112C5.64667 14.37 7.184 14.9993 9 15H11.5V16.35ZM8.975 13.975C9.25833 13.975 9.5 13.875 9.7 13.675C9.9 13.475 10 13.2333 10 12.95C10 12.6667 9.9 12.425 9.7 12.225C9.5 12.025 9.25833 11.925 8.975 11.925C8.69167 11.925 8.45 12.025 8.25 12.225C8.05 12.425 7.95 12.6667 7.95 12.95C7.95 13.2333 8.05 13.475 8.25 13.675C8.45 13.875 8.69167 13.975 8.975 13.975ZM8.25 10.8H9.75C9.75 10.3 9.8 9.95 9.9 9.75C10 9.55 10.3167 9.18333 10.85 8.65C11.15 8.35 11.4 8.025 11.6 7.675C11.8 7.325 11.9 6.95 11.9 6.55C11.9 5.7 11.6123 5.06233 11.037 4.637C10.4617 4.21167 9.78267 3.99933 9 4C8.26667 4 7.65 4.20433 7.15 4.613C6.65 5.02167 6.3 5.51733 6.1 6.1L7.5 6.65C7.58333 6.36667 7.74167 6.08733 7.975 5.812C8.20833 5.53667 8.55 5.39933 9 5.4C9.45 5.4 9.78733 5.525 10.012 5.775C10.2367 6.025 10.3493 6.3 10.35 6.6C10.35 6.88333 10.2667 7.13767 10.1 7.363C9.93333 7.58833 9.73333 7.81733 9.5 8.05C8.91667 8.55 8.56233 8.946 8.437 9.238C8.31167 9.53 8.24933 10.0507 8.25 10.8Z" fill={color} />
		</svg>
	)
}
