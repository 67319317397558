import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import clsx from 'clsx'
import Link from 'next/link'
import { PropsWithChildren } from 'react'

interface CardsWrapperProps {
	variant?: 'highlights'
	title: string
	viewAllLink?: string
}

export const CardsWrapper = (props: PropsWithChildren<CardsWrapperProps>) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const viewAll = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_VIEW_ALL', 'View all')
	const childrenStyleClasses = clsx('mt-6 flex flex-col gap-6', {
		'md:flex-row': props.variant === 'highlights',
	})
	return (
		<div>
			<div className="flex flex-row justify-between">
				<h2 className="font-extrabold text-msc-blue text-xl">{props.title}</h2>
				{props.viewAllLink && (
					<Link prefetch={false} className="cursor-pointer font-bold" href={props.viewAllLink}>
						{viewAll}
					</Link>
				)}{' '}
			</div>
			<div className={childrenStyleClasses}>{props.children}</div>
		</div>
	)
}
