import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { Info } from '@/features/sitecore/actions/infos'
import { MarketingTool } from '@/features/sitecore/actions/marketing-tools'
import { New } from '@/features/sitecore/actions/news'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import React from 'react'
import { CardInformative, CardsWrapper, MarketingTools } from '../uiComponents'

interface SidebarWrapperProps {
	infos: Info[]
	news: New[]
	marketingTools: MarketingTool[]
}

export const SidebarWrapper = (props: SidebarWrapperProps) => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const provideUrl = useUrlProvider()
	const staticParams = staticGA4ParamsFromUser(user, market)
	const viewAllNewsLink = provideUrl('all-news-page')
	const sitecoreStore = useSitecoreState((state) => state)

	const sendInfoOrNewsGA4Event = (labelClicked: string, event: string, eventName: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: event,
			eventName: eventName,
			...staticParams,
			label_clicked: labelClicked,
		})
	}

	const handleInfoCardClick = (event: any, info: Info) => {
		sendInfoOrNewsGA4Event(info.title, 'infohp', 'hp_info')
		if (info.logged && !user) {
			event.preventDefault()
			useModalState.getState().openLoginModal({ nextUrl: info.link })
		}
	}

	const handleNewsCardClick = (news: New) => {
		sendInfoOrNewsGA4Event(news.title, 'newshp', 'hp_news')
	}

	const marketingToolsLabel = sitecoreStore.getLabel(TranslationBundles.WELCOME, 'MSC-EXTERNAL-WELCOMEPAGE-MARKETING_TITLE', 'Marketing Tools')
	const newsLabel = sitecoreStore.getLabel(TranslationBundles.WELCOME, 'MSC-EXTERNAL-NEWS-GENERIC_TITLE', 'News')
	const infoLabel = sitecoreStore.getLabel(TranslationBundles.WELCOME, 'MSC-EXTERNAL-WELCOMEPAGE-INFO', 'Info')

	return (
		<>
			{props.marketingTools?.length > 0 && <MarketingTools title={marketingToolsLabel} marketingTools={props.marketingTools} />}
			{props.infos?.length > 0 && (
				<div className="mt-9 lg:mt-20">
					<CardsWrapper title={infoLabel}>
						{props.infos?.map((info, index) => (
							<React.Fragment key={index}>
								<CardInformative bundleName={info.bundleName} image={info.image} title={info.title} description={info.subtitle} link={info.link} onClick={(event) => handleInfoCardClick(event, info)} />
							</React.Fragment>
						))}
					</CardsWrapper>
				</div>
			)}
			{props.news?.length > 0 && (
				<div className="mt-9 lg:mt-20">
					<CardsWrapper title={newsLabel} viewAllLink={viewAllNewsLink}>
						{props.news?.map((news, index) => (
							<React.Fragment key={index}>
								<CardInformative bundleName={news.bundleName} image={news.image} date={news.date} title={news.title} link={news.link} onClick={() => handleNewsCardClick(news)} />
							</React.Fragment>
						))}
					</CardsWrapper>
				</div>
			)}
		</>
	)
}
