'use client'

import React from 'react'

interface TabPanelWrapperProps {
	children: React.ReactNode
}

export const TabPanelWrapper = ({ children }: TabPanelWrapperProps) => {
	return <div className="h-full">{children}</div>
}
