'use client'

import React, { forwardRef } from 'react'

interface VisualPaginationWrapperProps {
	children: React.ReactElement[]
}

const VisualPaginationWrapper = forwardRef<HTMLDivElement, VisualPaginationWrapperProps>(({ children }, ref) => {
	return (
		<div className="absolute z-30 bottom-6 left-0 right-0 container mx-auto flex gap-4" ref={ref}>
			{children.map((child, i) => {
				return (
					<div key={i} className={`flex-1 tab-${i}`}>
						{child}
					</div>
				)
			})}
		</div>
	)
})

VisualPaginationWrapper.displayName = 'VisualPaginationWrapper'

export { VisualPaginationWrapper }
