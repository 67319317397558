import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import NextAuth, { NextAuthConfig } from 'next-auth'
import Credentials from 'next-auth/providers/credentials'
import { authorize } from '../actions/websphere-auth'

const authOptions: NextAuthConfig = {
	trustHost: true,
	pages: {},
	callbacks: {
		signIn: async () => {
			return true
		},
		async jwt(params) {
			if (params.user) params.token.user = params.user
			return params.token
		},
		async session(params) {
			params.session.user = params.token.user as any
			return params.session
		},
		async redirect({ url, baseUrl }) {
			// Allows relative callback URLs
			if (url.startsWith('/')) return `${baseUrl}${url}`
			// Allows callback URLs on the same origin
			else if (new URL(url).origin === baseUrl) return url
			return baseUrl
		},
	},

	providers: [
		Credentials({
			credentials: {
				username: {},
				password: {},
				locale: {},
			},

			// Not using authService because of cyclic dependency
			authorize: createLoggingProxy(createPerformanceProxy(authorize)),
		}),
	],
}

export const { handlers, signIn, signOut, auth } = NextAuth({
	...authOptions,
})
