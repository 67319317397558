import React from 'react'
import { PromoIcon, DestinationIcon, ItineraryIcon, AgentsDetailsIcon, NotesIcon, DownloadIcon } from '../uiComponents'

interface FlyerBuilderNavigationButtonProps {
	isActive: boolean
	isDisabled: boolean
	buttonId: string
	children: string
	onClick: () => void
}

const FlyerBuilderNavigationButton = ({ isActive, isDisabled, buttonId, children, onClick }: FlyerBuilderNavigationButtonProps) => {
	let icon
	if (buttonId === 'destination') {
		icon = <DestinationIcon color={isActive ? '#4892E0' : '#000033'} />
	} else if (buttonId === 'itinerary') {
		icon = <ItineraryIcon color={isActive ? '#4892E0' : '#000033'} />
	} else if (buttonId === 'promotions') {
		icon = <PromoIcon color={isActive ? '#4892E0' : '#000033'} />
	} else if (buttonId === 'agentDetails') {
		icon = <AgentsDetailsIcon color={isActive ? '#4892E0' : '#000033'} />
	} else if (buttonId === 'notes') {
		icon = <NotesIcon color={isActive ? '#4892E0' : '#000033'} />
	} else if (buttonId === 'flyer') {
		icon = <DownloadIcon color={isActive ? '#4892E0' : '#000033'} />
	}
	return (
		<div className={`flex w-fit max-w-28 gap-1 ${!isActive ? '' : 'opacity-100'} ${isDisabled ? 'opacity-30 cursor-not-allowed' : 'cursor-pointer opacity-100 hover:opacity-100'} transition-opacity`} onClick={!isDisabled ? onClick : undefined}>
			<div className="w-6">{icon}</div>
			<p className={`${isActive ? 'text-secondary' : ''} text-xs`}>{children}</p>
		</div>
	)
}

export { FlyerBuilderNavigationButton }
