'use client'

import { DevTools, DevToolsStyling } from '@/features/dev-tools/configs/devToolsConfigs'
import { useDevToolsState } from '@/features/dev-tools/store/useDevToolsState'
import { Promotion } from '@/features/sitecore/actions/promotions'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React, { Suspense } from 'react'
import { Button, Countdown, CustomVideo, DynamicPicture, SwiperWrapper } from '../uiComponents'

interface HomePageVisualProps {
	promotions: Promotion[]
}

const HomePageVisual = ({ promotions }: HomePageVisualProps) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const detailsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_DETAILS_PROMOTIONS', 'Details')
	const devToolsState = useDevToolsState()

	return (
		<section className="w-full relative min-h-450 h-70dvh lg:h-auto">
			<SwiperWrapper type="autoplay" slidesToShow={{ base: 1, sm: 1, md: 1, lg: 1 }} promotions={promotions}>
				{promotions.map((promotion, index) => (
					<React.Fragment key={index}>
						{promotion.video ? (
							<Suspense fallback={<p>Loading video...</p>}>
								<CustomVideo src={promotion.video} srcTab={promotion?.videoTablet} srcMob={promotion?.videoMobile} />
							</Suspense>
						) : (
							<DynamicPicture image={promotion.bigImage} alt="Promotion image" className="absolute inset-0 object-cover object-center" fill />
						)}
						<div className="relative z-20 container flex flex-col items-start justify-center mx-auto min-h-450 md:min-h-600 h-70dvh lg:h-auto px-6" data-bundle-name={promotion.bundleName}>
							{devToolsState.showBundleNames && <span className={DevToolsStyling[DevTools.SHOW_BUNDLE_NAMES]}>{promotion.bundleName}</span>}
							<h1 className="text-3xl font-bold text-neutral-50 max-w-80">{promotion.subtitle}</h1>
							<p className={`md:hidden absolute bottom-10 text-white text-lg font-bold`}>{promotion.title}</p>
							<Countdown date={promotion.expireDate} isBoxedDate={true} />
							<Button className="mt-4" link={promotion.link}>
								{detailsLabel}
							</Button>
						</div>
						<div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-gradient-to-t from-dark-50"></div>
					</React.Fragment>
				))}
			</SwiperWrapper>
		</section>
	)
}

export { HomePageVisual }
