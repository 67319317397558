'use client'
import { Notification } from '@/features/notification/model/notification'
import NotificationService from '@/features/notification/notification-service'
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect'
import React, { useEffect, useRef, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import { NotificationLabel, NotificationsOverlay } from '../uiComponents'

export interface NotificationButtonProps {
	sendHeaderButtonsGA4Event: (labelClicked: string, event: string, eventName: string) => void
}

export const NotificationButton = ({ sendHeaderButtonsGA4Event }: NotificationButtonProps) => {
	const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
	const overlayRef = useRef<HTMLDivElement>(null)
	const [notificationsList, setNotificationsList] = useState<Notification[]>([])

	useAsyncEffect(async () => {
		const notificationRes = await NotificationService.retrieve({ pageSize: 10, pageNumber: 0 })
		setNotificationsList(notificationRes)
	}, [])

	const toggleNotificationsOverlay = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
		e.stopPropagation()
		setIsOverlayOpen(!isOverlayOpen)
	}

	const closeOverlay = () => {
		setIsOverlayOpen(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			toggleNotificationsOverlay(event)
		} else if (event.key === 'Escape') {
			closeOverlay()
		}
	}

	const handleClickOutside = (event: MouseEvent) => {
		if (overlayRef.current && !overlayRef.current.contains(event.target as Node)) {
			closeOverlay()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	const visibleNotificationsCount = notificationsList.filter((notification) => !notification.isRemoved).length

	return (
		<div
			className="md:relative flex justify-center py-4 px-2 cursor-pointer"
			ref={overlayRef}
			tabIndex={0}
			role="button"
			aria-pressed={isOverlayOpen}
			onClick={(e) => {
				toggleNotificationsOverlay(e)
				sendHeaderButtonsGA4Event('Bell', 'privatemenu', 'private_right_menu')
			}}
			onKeyDown={handleKeyDown}
		>
			<div className="relative">
				{notificationsList.length > 0 && <NotificationLabel variant="over">{visibleNotificationsCount}</NotificationLabel>}
				<FaBell />
			</div>
			{isOverlayOpen && <NotificationsOverlay notificationsList={notificationsList} />}
		</div>
	)
}
