'use client'

import { Input, SelectWrapper, Textarea } from '@/components/uiComponents'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React, { useState } from 'react'
import { Controller, ControllerRenderProps, FieldValues, RegisterOptions, ValidationRule } from 'react-hook-form'
import { AutoComplete } from '../atoms/Input'

export interface OptionsProps {
	value: string
	label: string
}

interface FormFactoryProps {
	control: any
	errors: any
	type: 'text' | 'email' | 'password' | 'confirm_password' | 'textarea' | 'select'
	name?: string
	label?: string
	required?: any
	handleChange?: (value: any, label?: any) => void
	setValue?: (value: any) => void
	value?: string
	reset?: any
	disabled?: boolean
	variant?: 'filled' | 'outlined'
	autoComplete?: AutoComplete
	options?: OptionsProps[]
	placeholder?: string
	validator?: ValidationRule<RegExp>
	defaultValue?: { label: string; value: string }
}

export type SchemaItem = Omit<FormFactoryProps, 'control' | 'errors'>

const FormFactory = ({ control, errors, type, name = '', label = '', required = true, variant, autoComplete, options = [], placeholder = '', validator, defaultValue }: FormFactoryProps) => {
	const sitecoreStore = useSitecoreState((state) => state)

	const passwordDoNotMatchLabel = sitecoreStore.getLabel(TranslationBundles.ACCOUNT, 'MSC-ACCOUNT_MANAGE_REPRESENTATIVES-PASSWORD_DIFFERENT_FROM_RETYPE_PASSWORD', 'Password do not match')
	const passwordNotCompliantLabel = sitecoreStore.getLabel(TranslationBundles.ACCOUNT, 'MSC-ACCOUNT_AGENCY_INFORMATION-PASSWORD_NOT_COMPLIANT', 'Password not compliant')
	const passwordPolicyLabel = sitecoreStore.getLabel(TranslationBundles.ACCOUNT, 'MSC-ACCOUNT-PASSWORD_NEW_POLICY', 'Password policy')
	const fieldCannotBeEmptyLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-MISSING_DATA', 'The field cannot be empty')

	const invalidEmail = sitecoreStore.getLabel(TranslationBundles.ORDER_MANAGEMENT_PASSENGER_DETAILS, 'MSC-ORDERMANAGEMENT-PASSENGERDETAILS-INVALID_EMAIL', 'Invalid email')
	const newPassword = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_NEW_PASSWORD', 'New Password')
	const confirmNewPassword = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_CONFIRM_NEW_PASSWORD', 'Confirm New Password')

	type RuleType = Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined
	const rules: RuleType = required ? { required: fieldCannotBeEmptyLabel } : {}
	if (validator) rules.pattern = validator

	const componentMap: Record<string, any> = {
		text: () => {
			return (
				<Controller
					control={control}
					name={name}
					rules={rules}
					render={({ field }) => {
						return <Input type={type} name={field.name} label={label} required={required} variant={variant} value={field.value} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} errorMessage={errors[name]?.message || ''} autoComplete={autoComplete} />
					}}
				/>
			)
		},
		email: () => {
			return (
				<Controller
					control={control}
					name={name}
					rules={
						required && {
							required: fieldCannotBeEmptyLabel,
							pattern: {
								value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
								message: invalidEmail,
							},
						}
					}
					render={({ field }) => {
						return <Input type={type} name={field.name} label={label} required={required} variant={variant} value={field.value} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} errorMessage={errors[name]?.message || ''} autoComplete={autoComplete} />
					}}
				/>
			)
		},
		password: () => {
			return (
				<Controller
					control={control}
					name={name}
					rules={
						required && {
							required: fieldCannotBeEmptyLabel,
						}
					}
					render={({ field }) => {
						return (
							<>
								<Input type={type} name={field.name} label={label} required={required} variant={variant} value={field.value} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} errorMessage={errors[name]?.message || ''} autoComplete="current-password" />
							</>
						)
					}}
				/>
			)
		},
		confirm_password: () => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const [newPasswordValue, setNewPasswordValue] = useState<string>('')

			const onNewPasswordChanged = (field: ControllerRenderProps<FieldValues, string>, e: React.ChangeEvent<HTMLInputElement>) => {
				setNewPasswordValue(e.currentTarget.value)
				field.onChange(e)
			}

			return (
				<>
					<Controller
						control={control}
						name={name}
						rules={
							required && {
								required: fieldCannotBeEmptyLabel,
								pattern: {
									value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[A-Za-z\d])[\w!@#$%^&*.,;]{10,}$/,
									message: passwordNotCompliantLabel,
								},
							}
						}
						render={({ field }) => {
							return (
								<>
									<Input name={field.name} label={newPassword} type={'password'} required={required} variant={variant} value={field.value} onChange={(e) => onNewPasswordChanged(field, e)} onBlur={field.onBlur} ref={field.ref} errorMessage={errors[field.name]?.message || ''} autoComplete="new-password" />
								</>
							)
						}}
					/>
					<div className="mt-6">
						<Controller
							control={control}
							name="confirmPassword"
							rules={{
								required: fieldCannotBeEmptyLabel,
								validate: (value) => value === newPasswordValue || passwordDoNotMatchLabel,
							}}
							render={({ field }) => <Input label={confirmNewPassword} type={'password'} required={required} variant={variant} value={field.value} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} errorMessage={errors[field.name]?.message || ''} autoComplete="new-password" />}
						/>
					</div>
					<div className="flex flex-col text-xs text-msc-blue gap-3 mt-6" dangerouslySetInnerHTML={{ __html: passwordPolicyLabel }} />
				</>
			)
		},
		textarea: () => {
			return (
				<Controller
					control={control}
					name={name}
					rules={
						required && {
							required: fieldCannotBeEmptyLabel,
						}
					}
					render={({ field }) => {
						return <Textarea name={field.name} label={label} required={required} value={field.value} onChange={field.onChange} onBlur={field.onBlur} inputRef={field.ref} />
					}}
				/>
			)
		},
		select: () => {
			return (
				<Controller
					control={control}
					name={name}
					rules={
						required && {
							required: fieldCannotBeEmptyLabel,
						}
					}
					defaultValue={defaultValue?.value || null}
					render={({ field }) => {
						return <SelectWrapper options={options} placeholder={placeholder} required={required} field={field} />
					}}
				/>
			)
		},
	}

	const Component = componentMap[type]

	return Component ? Component() : null
}

export default FormFactory
