import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { Highlight } from '@/features/sitecore/actions/highlights'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useModalState } from '@/utils/hooks/useModalState'
import React from 'react'
import { CardInformative, CardsWrapper } from '../uiComponents'

interface HighlightSectionProps {
	title: string
	highlights: Highlight[]
}

const HighlightSection = ({ title, highlights }: HighlightSectionProps) => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	// GA4
	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendHighlightedLinkGA4Event = (labelClicked: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'highlightedlink',
			eventName: 'highlighted_link',
			...staticParams,
			label_clicked: labelClicked,
		})
	}

	const sendClickPromotionsGA4Event = (index: number) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({ ecommerce: null })
		window.dataLayer.push({
			event: 'select_promotion',
			eventName: 'select_promotion',
			user_id: user?.agency || '',
			network_id_u: user?.consortiumCode || '',
			agency_code_u: user?.agency || '',
			ecommerce: {
				items: [
					{
						promotion_id: highlights[index].bundleName?.split('_').pop(),
						promotion_name: highlights[index].title,
						creative_name: highlights[index].title,
						creative_slot: index + 1,
						location_id: 'Body',
						time_left: '',
						section: user ? 'Private' : 'Public',
					},
				],
			},
		})
	}

	const handleCardClick = (event: any, highlight: Highlight, index: number) => {
		sendHighlightedLinkGA4Event(highlight.title)
		sendClickPromotionsGA4Event(index)
		if (highlight.logged && !user) {
			event.preventDefault()
			useModalState.getState().openLoginModal({ nextUrl: highlight.link })
		}
	}

	return (
		<CardsWrapper title={title} variant="highlights">
			{highlights.map((highlight, index) => (
				<React.Fragment key={index}>
					<CardInformative variant="highlights" bundleName={highlight.bundleName} image={highlight.image} title={highlight.title} description={highlight.subtitle} link={highlight.link} onClick={(event) => handleCardClick(event, highlight, index)} />
				</React.Fragment>
			))}
		</CardsWrapper>
	)
}

export { HighlightSection }
