'use client'
import { Checkbox } from '@/components/uiComponents'
import { DevTools } from '../configs/devToolsConfigs'
import { useDevToolsState } from '../store/useDevToolsState'

export const HighlightLabels = () => {
	const store = useDevToolsState()

	const handleChangeValue = async () => {
		store.set(DevTools.HIGHLIGHT_LABELS, !store.highlightLabels)
	}

	return <Checkbox name="highlight-labels" label="Highlight sitecore labels" checked={store.highlightLabels} onChange={handleChangeValue} />
}
