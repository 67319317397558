'use client'

import FormFactory, { SchemaItem } from '@/components/factory/formFactory'
import AgentService from '@/features/agent/agent-service'
import { RegisterAgencyParams } from '@/features/agent/model/registration-form'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useModalState } from '@/utils/hooks/useModalState'
import Link from 'next/link'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from '../uiComponents'

export type RegisterYourAgencyFormInputs = RegisterAgencyParams

export const RegisterYourAgencyModalTemplate = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const state = useModalState()
	const market = useCurrentMarket()

	const countries = sitecoreStore.bundles[TranslationBundles.REGISTRATION_COUNTRIES]
	const languages = sitecoreStore.bundles[TranslationBundles.REGISTRATION_LANGUAGES]
	const countriesOptions = Object.entries(countries)
		.map(([key, value]) => ({
			label: value,
			value: key,
		}))
		.filter((value) => value.value && value.label)
		.sort((a, b) => a.label.localeCompare(b.label))
	const countriesOptionsDefault = { label: market.name, value: market.code }
	const languagesOptions = Object.entries(languages)
		.map(([key, value]) => ({
			label: value,
			value: key,
		}))
		.filter((value) => value.value && value.label)
		.sort((a, b) => a.label.localeCompare(b.label))
	const languageDefault = { label: market.language.name, value: market.code }

	const registerYourAgencyModalTopInfo = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-SUBTITLE', 'Info')
	const sendRequestLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-SEND_BUTTON', 'Send request')
	const goBackLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-CLOSE_BUTTON', 'Close')
	const agencyNameLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-AGENCY_NAME', 'Agency name')
	const firstAdressLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-ADDRESS1', 'Adress 1')
	const secondAdressLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-ADDRESS2', 'Adress 2')
	const cityLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-CITY', 'City')
	const zipLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-ZIP', 'Zip code')
	const stateLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-STATE', 'State')
	const countryLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-COUNTRY', 'Country')
	const firtsNameLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-FIRSTNAME', 'First name')
	const lastNameLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-LASTNAME', 'Last name')
	const firstPhoneLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-PHONE1', 'Phone 1')
	const secondPhoneLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-PHONE2', 'Phone 2')
	const emailLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-EMAIL', 'Email')
	const AbtaLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-ABTA', 'VAT')
	const languageLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-LANGUAGE', 'Language')
	const agencyCodeLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-AGENCY_CODE', 'Agency code')
	const agentIdLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-AGENT_ID', 'Agent Id')
	const readPrivacyStatementLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-READ_CONTRACT_PRIVACY', 'Read privacy statement')
	const mandatoryItemsLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-CONTRACT_REQUIRED_FIELD', '*Mandatory items')
	const privacyStatementLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-CONTRACT_PRIVACY', 'Privacy statement')
	const privacyStatementLinkLabel = sitecoreStore.getLabel(TranslationBundles.IMAGE_CROSS, 'MSC-SIGNIN_SIGNUP_REGISTER-CONTRACT_PRIVACY_PDF', '#')

	const form = useForm<RegisterYourAgencyFormInputs>()

	const registerYourAgencySchema: SchemaItem[] = [
		{ type: 'text', name: 'AgencyName', label: agencyNameLabel, required: true },
		{ type: 'text', name: 'Address1', label: firstAdressLabel, required: true },
		{ type: 'text', name: 'Address2', label: secondAdressLabel, required: true },
		{ type: 'text', name: 'City', label: cityLabel, required: true },
		{ type: 'text', name: 'ZIP', label: zipLabel, required: true },
		{ type: 'text', name: 'State', label: stateLabel, required: true },
		{ type: 'select', name: 'Country', placeholder: countryLabel, required: true, options: countriesOptions, defaultValue: countriesOptionsDefault },
		{ type: 'text', name: 'FirstName', label: firtsNameLabel, required: true },
		{ type: 'text', name: 'LastName', label: lastNameLabel, required: true },
		{ type: 'text', name: 'Phonenumber1', label: firstPhoneLabel, required: true },
		{ type: 'text', name: 'Phonenumber2', label: secondPhoneLabel, required: false },
		{ type: 'email', name: 'EmailAddress', label: emailLabel, required: true },
		{ type: 'text', name: 'ABTA', label: AbtaLabel, required: false },
		{ type: 'select', name: 'Language', placeholder: languageLabel, required: true, options: languagesOptions, defaultValue: languageDefault },
		{ type: 'text', name: 'UserId', label: agentIdLabel, required: false },
		{ type: 'text', name: 'AgencyCode', label: agencyCodeLabel, required: false },
	]

	const handleRegisterAgency: SubmitHandler<RegisterYourAgencyFormInputs> = async (data) => {
		await AgentService.registerAgency(data)
		useModalState.getState().openSuccessfulRequestModalTemplate()
	}

	return (
		<div className="flex flex-col gap-6 w-full">
			<div className="pb-6" dangerouslySetInnerHTML={{ __html: registerYourAgencyModalTopInfo }} />
			<form noValidate onSubmit={form.handleSubmit(handleRegisterAgency)}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					{registerYourAgencySchema.map((schemaItem, index) => (
						<div key={index}>
							<FormFactory control={form.control} errors={form.formState.errors} {...schemaItem} />
						</div>
					))}
				</div>
				<div className="flex mt-4">
					<p className="flex gap-2">
						{readPrivacyStatementLabel}
						<Link prefetch={false} className="text-blue-600" target="_blank" href={`/${privacyStatementLinkLabel}`}>
							{privacyStatementLabel}
						</Link>
					</p>
				</div>
				<p className="py-4">{mandatoryItemsLabel}</p>
				<div className="flex justify-between w-full">
					<Button type="button" variant="outlined" className="normal-case" onClick={state.close}>
						{goBackLabel}
					</Button>
					<Button type="submit" className="normal-case" isSubmitting={form.formState.isSubmitting}>
						{sendRequestLabel}
					</Button>
				</div>
			</form>
		</div>
	)
}
