import AuthService from '@/features/auth/auth-service'
import HeaderMenuService from '@/features/header-menu/header-menu-service'
import MarketService from '@/features/markets/markets-service'
import PricesB2BService from '@/features/pricesb2b/service'
import { PricesB2BParams } from '@/features/pricesb2b/types'

export function createConfigForCurrentMarket() {
	return {
		queryKey: ['market'],
		queryFn: () => MarketService.getCurrentMarket(),
	}
}

export function createConfigForCurrentMarketLanguages() {
	return {
		queryKey: ['market-languages'],
		queryFn: () => MarketService.getCurrentMarketLanguages(),
		staleTime: 90000,
	}
}

export function createConfigForAuthSession() {
	return {
		queryKey: ['auth'],
		queryFn: () => AuthService.session(),
	}
}

export function createConfigForHeaderMenu(options: { cache: boolean }) {
	return {
		queryKey: ['header-menu'],
		queryFn: () => HeaderMenuService.getHeaderMenu({ cache: options.cache }),
	}
}

export function createConfigForSearchCruiseMinPrice(params: PricesB2BParams) {
	return {
		queryKey: ['pricesb2b', params],
		queryFn: () => PricesB2BService.searchMinPrice(params),
	}
}
