'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { getDidomi } from '@/utils/lib/didomi'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { DidomiSDK } from '@didomi/react'
import Link from 'next/link'
import { useEffect, useState } from 'react'

const FooterLinks = () => {
	const market = useCurrentMarket()
	const user = useLoggedUser()
	const provideUrl = useUrlProvider()
	const sitecoreStore = useSitecoreState((state) => state)

	const [isClient, setIsClient] = useState(false)
	const [didomiLoaded, setDidomiLoaded] = useState(false)
	const didomi = getDidomi()

	useEffect(() => {
		setIsClient(true)
	}, [])

	useEffect(() => {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.text = didomi.script
		document.body.appendChild(script)
		return () => {
			document.body.removeChild(script)
		}
	}, [didomi.script])

	const allRightsReservedLabel = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-RIGHTS', '© 2024 MSC Cruises. All rights reserved')
	const privacyPolicyLabel = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-PRIVACY', 'Privacy policy')
	const termsAndConditionsLabel = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-TERMS_E_CONDITIONS', 'Terms and conditions')
	const contactLabel = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-CONTACTS', 'Contact')
	const consentLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-FOOTER-CONSENT-TEXT', 'Consent choices')

	// Footer links
	const defaultPrivaciPolicy = 'PN007Y24%20V3%20MSC%20Cruises%20Privacy%20Notice%2008042024_CLEAN_bg_BG.pdf' //Pdf in English
	const privacyPolicyLink = `${useEnvironmentState().getStringOrThrow('WEBSPHERE_IMAGES_ENDPOINT')}/${sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-PRIVACY_PDF', defaultPrivaciPolicy)}`
	const termsAndConditionsLink = provideUrl('terms-and-conditions')
	const contactLink = provideUrl('contact-us')

	type FooterLinksData = { label: string; href: string; onClick?: () => void }
	const footerLinksData: FooterLinksData[] = [
		{ label: privacyPolicyLabel, href: privacyPolicyLink },
		{ label: termsAndConditionsLabel, href: termsAndConditionsLink },
		{ label: contactLabel, href: contactLink },
		{ label: consentLabel, href: '#', onClick: () => handleConsentDidomiClick() },
	]

	const handleConsentDidomiClick = () => {
		if (didomiLoaded && window.Didomi) {
			if (market.code === 'USA') {
				window.location.href = didomi.redirectLink
			} else {
				window.Didomi.preferences.show()
			}
		} else {
			console.error('Didomi SDK not loaded')
		}
	}

	// GA4
	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendFooterLinkGA4Event = (labelClicked: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'footerLink',
			eventName: 'footer_Link',
			...staticParams,
			label_clicked: labelClicked,
		})
	}

	const handleFooterItemClick = (footerItem: FooterLinksData) => {
		sendFooterLinkGA4Event(footerItem.label)
		if (footerItem.onClick) footerItem.onClick()
	}

	return (
		<div className="gap-2 pb-6">
			{isClient && (
				<DidomiSDK
					gdprAppliesGlobally={true}
					onReady={() => {
						setDidomiLoaded(true)
					}}
				/>
			)}
			<p className="text-center">{allRightsReservedLabel}</p>
			<ul className="flex flex-col items-center underline  h-fit lg:no-underline lg:flex-row">
				{footerLinksData.map((footerItem, index) => (
					<li key={index} className="pr-2 lg:after:content-['|'] after:right-0 after:pl-2 last:after:content-none">
						<Link prefetch={false} className="underline" onClick={() => handleFooterItemClick(footerItem)} href={footerItem.href}>
							{footerItem.label}
						</Link>
					</li>
				))}
			</ul>
		</div>
	)
}

export { FooterLinks }
