'use client'

import AgentService from '@/features/agent/agent-service'
import { Sitecore } from '@/features/sitecore/components/Sitecore'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { useRouter } from 'next/router'
import { Button } from '../uiComponents'

export const NewConsentModalTemplate = () => {
	const router = useRouter()
	const provideUrl = useUrlProvider()
	const userAccountUrl = provideUrl('user-account', { openConsent: true })

	const handleRedirectButton = () => {
		router.push(userAccountUrl)
	}

	const handleAcceptConsent = async () => {
		await AgentService.acceptConsent()
		useModalState.getState().close()
	}

	return (
		<div className="flex flex-col gap-6">
			<Sitecore bundle={TranslationBundles.WELCOME} keyName="MSC-EXTERNAL-WELCOMEPAGE-COOKIE-CONSENT-MESSAGE">
				By continuing to browse our site you agree to our Terms of Service.
			</Sitecore>
			<div className="flex gap-6">
				<Button variant="outlined" className="w-full" onClick={handleAcceptConsent}>
					<Sitecore bundle={TranslationBundles.WELCOME} keyName="MSC-EXTERNAL-WELCOMEPAGE-COOKIE-POLICY-OK">
						Accept consent
					</Sitecore>
				</Button>
				<Button variant="filled" className="w-full" onClick={handleRedirectButton}>
					<Sitecore bundle={TranslationBundles.WELCOME} keyName="MSC-EXTERNAL-WELCOMEPAGE-COOKIE-PDF-LINK-LABEL">
						Go to your personal area section
					</Sitecore>
				</Button>
			</div>
		</div>
	)
}
