import React from 'react'

interface TextareaProps {
	name?: string
	label: string
	required: boolean
	value: string
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void
	inputRef: React.Ref<HTMLTextAreaElement>
	customClasses?: string
	variant?: 'filled' | 'outlined'
	autoComplete?: 'username' | 'current-password' | 'new-password'
}

const Textarea = ({ name, label, required, value, inputRef, onChange, onBlur }: TextareaProps) => {
	const textareaClasses = 'w-full pt-4 pb-3 px-5 text-sm border focus:outline-none rounded-sm placeholder-transparent peer focus:ring-3 focus:ring-focus-blue text-light-purple bg-white border-light-grey'

	const labelClasses = 'absolute left-5 top-2 transform -translate-y-0 text-xxs transition-all duration-200 ease-in-out text-dark peer-placeholder-shown:top-0 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-focus:top-2 peer-focus:transform peer-focus:-translate-y-0 peer-focus:text-xxs peer-focus:text-dark'

	return (
		<div className="w-full relative">
			<textarea className={`${textareaClasses} peer`} name={name} required={required} placeholder=" " value={value} onChange={onChange} onBlur={onBlur} ref={inputRef} rows={5} />
			<label className={labelClasses}>
				{label}
				{required ? '*' : ''}
			</label>
		</div>
	)
}

export { Textarea }
