'use client'

import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { createConfigForCurrentMarketLanguages } from '@/features/caching/lib/tanstack'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import Select, { MultiValue, SingleValue, StylesConfig } from 'react-select'

export interface LanguageOption {
	value: string
	label: string
}

export interface ChangeLanguageProps {
	className?: string
}

export const ChangeLanguage: React.FC<ChangeLanguageProps> = (props) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const market = useCurrentMarket()
	const queryClient = useQueryClient()

	const currentMarketLanguages = useQuery(createConfigForCurrentMarketLanguages()).data || []
	const languages = currentMarketLanguages.map((language) => ({ label: language.name, value: language.path }))
	const selected = languages.find((language) => language.value === market.language.path)!

	const changeLanguageLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-LANGUAGE', 'Change Language')

	const handleLanguageChange = async (newValue: SingleValue<LanguageOption> | MultiValue<LanguageOption>) => {
		const selectedOption = newValue as SingleValue<LanguageOption>
		if (selectedOption) {
			queryClient.invalidateQueries({ queryKey: ['market'] })
			window.location.href = `/${selectedOption.value}/welcome`
		}
	}

	const customStyles: StylesConfig<LanguageOption> = {
		control: (provided: any) => ({
			...provided,
			backgroundColor: 'transparent',
			border: 'none',
			boxShadow: 'none',
			cursor: 'pointer',
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? 'white' : 'transparent',
			color: 'black',
			cursor: 'pointer',
		}),
		singleValue: (provided) => ({
			...provided,
			color: 'white',
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: 'white',
			padding: '0px',
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
	}

	return (
		<>
			{languages.length > 1 && (
				<div className={props.className}>
					<div className="flex items-center gap-px text-xs desktop-min-1200px:text-sm">
						{`${changeLanguageLabel}:`}
						<Select styles={customStyles} options={languages} value={selected} onChange={handleLanguageChange} />
					</div>
				</div>
			)}
		</>
	)
}
