'use client'

import React from 'react'

export interface CheckboxProps {
	name: string
	label?: string
	onChange: () => void
	onMouseDown?: (e: React.MouseEvent) => void
	checked?: boolean
	disabled?: boolean
}

const Checkbox = ({ name, label, onChange, onMouseDown, checked, disabled }: CheckboxProps) => {
	return (
		<div className="px-4 py-2">
			<div className="flex items-center">
				<input type="checkbox" id={`checkbox-${name}`} value={name} checked={checked} onChange={disabled ? undefined : onChange} disabled={disabled} className={`selectComponentCheckbox h-4 w-4  focus:ring-indigo-500 border-gray-300 rounded ${disabled ? 'cursor-not-allowed text-indigo-300' : 'cursor-pointer text-indigo-600'}`} onMouseDown={onMouseDown && onMouseDown} />
				<label htmlFor={`checkbox-${name}`} className={`ml-2 block text-nowrap text-sm  ${disabled ? 'text-gray-500' : 'cursor-pointer text-gray-900'}`} onMouseDown={onMouseDown && onMouseDown}>
					{label}
				</label>
			</div>
		</div>
	)
}

export { Checkbox }
