import { Logger } from '@/utils/lib/logger-pino'

const logger = new Logger('src/utils/services/actions/proxies/logging-proxy')

export function createLoggingProxy<T extends object>(targetObject: T): T {
	return new Proxy(targetObject, {
		get: (target, prop) => {
			const originalMethod = target[prop as keyof T]

			if (typeof originalMethod === 'function') {
				return async (...args: any[]) => {
					logger.debug(`Calling method: ${String(prop)} with params ${JSON.stringify(args)}`)
					// eslint-disable-next-line @typescript-eslint/ban-types
					const result = await (originalMethod as Function).apply(target, args)
					return result
				}
			}

			return originalMethod
		},
	})
}
