'use client'

import { AdditionalFilterData, ArrayFilterType, ElasticFilterState, FILTER_TYPE, IOption, IOptionGroup, OccupancyFilterType } from '@/features/search-cruise/store/types'
import { QueryClient } from '@tanstack/query-core'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { OCCUPANCY_CONFIG } from '../configs/occupancy'
import { isIOption, isIOptionArray, isIOptionGroupArray } from '../lib/utils'
import { ElasticFilter } from '../model/elastic-filter'
import { fetchQueryElasticFilter } from '../tanstack/useQueryElasticFilter'
import { canModifyOccupancy, elasticFilterInitialState, formatDate, IElasticFilterLabels, processUpdateFilters } from './elastic-filter-store-utils'
import { mapAnyCabinClassToLetter } from './utils/cabin-utils'
import { getCheckedOptionsValueConcatenated, noChecks } from './utils/option-utils'

export interface ElasticFilterStoreProps {
	additionalData?: AdditionalFilterData
	labels: IElasticFilterLabels
	queryClient: QueryClient
	initialState: {
		options: ElasticFilter.ISelectedFilter
		highlighted: ElasticFilter.IHightlighted
		labels?: IElasticFilterLabels
		additionalData?: {
			areaAggregations: string[]
		}
	}
}

export const createElasticFilterStore = (props: ElasticFilterStoreProps) =>
	create<ElasticFilterState, [['zustand/immer', never]]>(
		immer((set, get) => ({
			...elasticFilterInitialState,
			options: props.initialState.options,
			highlighted: props.initialState.highlighted,
			labels: props.labels,
			additionalData: props.additionalData,
			queryClient: props.queryClient,

			init: async () => {
				await get().fetchUpdatedFilters()
			},

			setOccupancy: (type: OccupancyFilterType, count: number) => {
				set((state) => {
					if (canModifyOccupancy(state.options, type, count, OCCUPANCY_CONFIG.occupancyRules)) {
						state.options[type] += count
					}
				})
				get().fetchUpdatedFilters(type)
			},

			setPriceRange: (min: number, max: number) => {
				set((state) => {
					state.options.priceRange.min = min
					state.options.priceRange.max = max
				})
			},

			toggleArrayFilter: (typeChanged: ArrayFilterType, value: IOption | IOptionGroup) => {
				let removed = false

				set((state) => {
					const array = state.options[typeChanged]

					if (!Array.isArray(array)) {
						throw new Error(`Filter type '${typeChanged}' is not an array. Current value: ${array}`)
					}

					if (isIOptionArray(array) && isIOption(value)) {
						const option = array.find((item) => item.value === value.value)
						if (option) {
							if (option.checked) removed = true
							if (typeChanged === FILTER_TYPE.DURATION) {
								array.forEach((item) => (item.checked = false))
							}
							option.checked = !option.checked
						}
					} else if (isIOptionGroupArray(array) && isIOption(value)) {
						const group = array.flatMap((item) => item.options).find((item) => item.value === value.value)
						if (group) {
							if (group.checked) removed = true
							group.checked = !group.checked
						}
					} else {
						throw new Error('Mismatched types')
					}
				})

				if (removed && noChecks(get().options[typeChanged])) {
					get().fetchUpdatedFilters()
				} else {
					get().fetchUpdatedFilters(typeChanged)
				}
			},

			resetDuration() {
				set((state) => {
					state.options.duration.forEach((option, index) => {
						index === 0 ? (option.checked = true) : (option.checked = false)
					})
				})
				get().fetchUpdatedFilters()
			},

			resetAllFilters() {
				set((state) => {
					state.options.duration.forEach((option, index) => {
						index === 0 ? (option.checked = true) : (option.checked = false)
					})
					state.options.ship.forEach((option) => (option.checked = false))
					state.options.area.forEach((option) => (option.checked = false))
					state.options.embkPort.forEach((group) => group.options.forEach((option) => (option.checked = false)))
					state.options.rates.forEach((option) => (option.checked = false))
					state.options.cabinCategory.forEach((option) => (option.checked = false))
				})
				get().fetchUpdatedFilters()
			},

			setDateFilter: (dateFrom: Date | undefined, dateTo: Date | undefined) => {
				set((state) => {
					state.options.departureFrom = formatDate(dateFrom)
					state.options.departureTo = formatDate(dateTo)
				})
				get().fetchUpdatedFilters(FILTER_TYPE.DEPARTURE_LIST)
			},

			fetchUpdatedFilters: async (typeChanged?: FILTER_TYPE) => {
				set({ error: undefined, loading: true })
				const currentState = get()

				try {
					const fetchedResults = await fetchQueryElasticFilter(currentState.queryClient!, currentState.options)

					const newState = processUpdateFilters(fetchedResults, currentState, currentState.labels, currentState.additionalData, typeChanged)

					set((state) => {
						state.loading = false
						state.options = newState
					})
				} catch {
					set((state) => {
						state.loading = false
						state.error = 'An error occurred while fetching updated filters'
					})
				}
			},

			getCanUpdateOccupancy: (type: OccupancyFilterType) => ({
				add: canModifyOccupancy(get().options, type, 1, OCCUPANCY_CONFIG.occupancyRules),
				remove: canModifyOccupancy(get().options, type, -1, OCCUPANCY_CONFIG.occupancyRules),
			}),

			getUrl: () => {
				const state = get()
				const [departuretimefrom1, departuretimeto1] = getDepartureTime(state)

				return {
					noSearch: 'false',
					itinerary1: 'any',
					specialProduct: 'false',
					section: 'resultspage',
					duration1: getCheckedOptionsValueConcatenated(state.options.duration),
					ship: getCheckedOptionsValueConcatenated(state.options.ship),
					departuretimefrom1,
					departuretimeto1,
					destination2: getCheckedOptionsValueConcatenated(state.options.embkPort),
					destination1: getCheckedOptionsValueConcatenated(state.options.area),
					adultOccupancy: state.options.adults.toString(),
					childOccupancy: state.options.children.toString(),
					juniorOccupancy: state.options.juniorChildren.toString(),
					infantOccupancy: state.options.infants.toString(),
					cabinType: getCheckedOptionsValueConcatenated(getCabinCategories(state)),
					attributes: getCheckedOptionsValueConcatenated(state.options.rates),
					priceRange: getPriceRangeString(state.options.priceRange),
				}
			},
		}))
	)

// Helper functions
function getDepartureTime(state: ElasticFilterState): [string, string] {
	const from = state.options.departureFrom || ''
	const to = state.options.departureTo || from
	return [from, to]
}

function getCabinCategories(state: ElasticFilterState) {
	return state.options.cabinCategory.map((cab) => ({
		...cab,
		value: mapAnyCabinClassToLetter(cab.value[0]),
	}))
}

function getPriceRangeString(priceRange: { min: number; max: number }): string {
	if (priceRange.min === 0 && priceRange.max === 20000) return ''
	return `${priceRange.min || '0'}|${priceRange.max !== 20000 ? priceRange.max : '20000'}`
}
