import { QueryClient } from '@tanstack/react-query'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

// Create a client-side only persister
export const persister =
	typeof window !== 'undefined'
		? createSyncStoragePersister({
				storage: window.localStorage,
				throttleTime: 1000,
			})
		: null

function makeQueryClient() {
	return new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 1000 * 60 * 60, // 1 hour
				gcTime: 1000 * 60 * 60 * 24, // 24 hours
				refetchOnWindowFocus: false, // Prevent unnecessary refetches
			},
		},
	})
}

// Singleton pattern for client-side query client
let browserQueryClient: QueryClient | undefined = undefined

export function getQueryClient() {
	if (typeof window === 'undefined') {
		return makeQueryClient()
	}

	if (!browserQueryClient) {
		browserQueryClient = makeQueryClient()
	}
	return browserQueryClient
}
