'use client'
import React from 'react'
import { FaRegPauseCircle, FaRegPlayCircle } from 'react-icons/fa'

interface VisualPaginationTabProps {
	variant?: 'active'
	tabTitle?: string
	hasVideo?: boolean
	isPaused?: boolean
	shouldShowProgrssBar?: boolean
	onClick: () => void
	playVideo: () => void
	pauseVideo: () => void
}

const VisualPaginationTab = ({ variant, tabTitle, hasVideo, isPaused, shouldShowProgrssBar, onClick, playVideo, pauseVideo }: VisualPaginationTabProps) => {
	const handleVideoPlay = (e: React.MouseEvent<SVGElement>) => {
		e.stopPropagation()
		playVideo()
	}

	const handleVideoPause = (e: React.MouseEvent<SVGElement>) => {
		e.stopPropagation()
		pauseVideo()
	}

	return (
		<div className={`relative pagination-box pb-4 h-full flex flex-col justify-end pr-10 gap-1 lg:pr-24 ${variant === 'active' ? 'opacity-100' : 'opacity-50'} cursor-pointer transition-opacity`} onClick={onClick}>
			<p className={`hidden md:block text-white ${variant === 'active' ? 'text-lg' : ''} font-bold`}>{tabTitle}</p>
			{/* <p className={`absolute md:relative w-noPadding-screen text-white ${variant === 'active' ? 'text-lg opacity-100' : 'opacity-0 md:opacity-100'} font-bold`}>{tabTitle}</p> */}
			{hasVideo && <p className="opacity-0 md:opacity-100">{isPaused ? <FaRegPlayCircle color="white" onClick={(e) => handleVideoPlay(e)} /> : <FaRegPauseCircle color="white" onClick={(e) => handleVideoPause(e)} />}</p>}
			{shouldShowProgrssBar && (
				<div className="absolute bottom-0 left-0 right-0 h-0.5 bg-white-50">
					<div className={`progress h-0.5 bg-white ${variant === 'active' ? 'opacity-100' : 'opacity-0'} transition-opacity`}></div>
				</div>
			)}
		</div>
	)
}

export { VisualPaginationTab }
