import Link from 'next/link'
import React from 'react'
import { ExpandCircleRightIcon } from '../uiComponents'

interface TextButtonProps {
	link: string
	children: string
	onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const TextButton = ({ link, children, onClick }: TextButtonProps) => {
	return (
		<Link prefetch={false} href={link} onClick={onClick} className="flex gap-2 items-center">
			<span className="font-bold text-sm">{children}</span> <ExpandCircleRightIcon />
		</Link>
	)
}

export { TextButton }
