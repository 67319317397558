import React from 'react'

interface AngleDownIconInterface {
	color?: string
}

export const AngleDownIcon = ({ color = '#000033' }: AngleDownIconInterface) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={color} aria-hidden="true">
			<path fillRule="evenodd" d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
		</svg>
	)
}
