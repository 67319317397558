'use client'

import { Notification } from '@/features/notification/model/notification'
import NotificationService from '@/features/notification/notification-service'
import { useModalState } from '@/utils/hooks/useModalState'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { RiDeleteBin7Fill } from 'react-icons/ri'

export interface NotificationItemProps {
	key: number
	notification: Notification
	tabLabel: string
	sendPrivateNotificationGA4Event: (labelClicked: string, ctaClicked: string, section: string) => void
}

export const NotificationItem = (props: NotificationItemProps) => {
	const modalState = useModalState()

	const downloadNotificationEl = async () => {
		try {
			const pdf = await NotificationService.download({ notificationId: props.notification.id })
			modalState.openNotificationAttachmentModal({ attachmentUrl: `data:application/pdf;base64,${pdf}` })
		} catch {
			return
		}
		await NotificationService.read({ notificationId: props.notification.id })
	}

	const cancelNotificationEl = async () => {
		await NotificationService.cancel({ notificationId: props.notification.id })
	}

	return (
		<li className="relative flex border border-background rounded-lg gap-2 w-full py-2 px-3">
			<div className="absolute left-0 top-0 bottom-0 w-1 bg-msc-blue rounded-tl-lg rounded-bl-lg"></div>
			<div className={`${props.notification.isRead ? '' : ''}`}>
				<p className="text-msc-blue text-sm font-bold">{props.notification.title}</p>
				<p className="text-msc-blue text-sm">{props.notification.description}</p>
				<p className="text-msc-blue text-xs">{props.notification.timestamp}</p>
			</div>
			<div className="flex flex-col ml-auto gap-4 justify-content-around">
				<div className="cursor-pointer">
					{!props.notification.documentFilepath && (
						<FaExternalLinkAlt
							color="#000033"
							onClick={() => {
								downloadNotificationEl()
								props.sendPrivateNotificationGA4Event(props.notification.title, 'Go to', props.tabLabel)
							}}
						/>
					)}
				</div>

				<div
					className="cursor-pointer"
					onClick={() => {
						cancelNotificationEl()
						props.sendPrivateNotificationGA4Event(props.notification.title, 'Erase', props.tabLabel)
					}}
				>
					<RiDeleteBin7Fill color="#000033" />
				</div>
			</div>
		</li>
	)
}
