import flagsImage from '@public/images/flags.png'
import clsx from 'clsx'
import styles from './CountryFlag.module.css'

export interface CountryFlagProps {
	countryCode?: string
}

export async function CountryFlag(props: CountryFlagProps) {
	return (
		<div className="flex align-center">
			<span style={{ background: `url(${flagsImage.src}) no-repeat` }} className={clsx(`ico-span`, styles.flag, styles[`${props.countryCode}`])} />
		</div>
	)
}
