import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as Fetch from './actions/fetch'
import * as SimplifiedFetch from './actions/simplified-fetch'
import * as Database from './db2_dump/_exporter'
import * as Property from './properties/_exporter'

// prettier-ignore
// eslint-disable-next-line prettier/prettier
const WebsphereService = createProxiedActions<
	typeof Fetch &
	typeof SimplifiedFetch &
	typeof Database &
	typeof Property
>({
	modules: [
		Fetch,
		SimplifiedFetch,
		Database,
		Property,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default WebsphereService
