'use client'

import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState, RefObject } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DeparturesIcon, ReturnIcon } from '../uiComponents'
import { useViewportType } from '../ui-hooks/useViewportType'

import { IOption } from '@/features/search-cruise/store/types'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useElasticFilterState } from '@/features/search-cruise/store/useElasticFilterState'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { parse, isValid } from 'date-fns'

// Import all required locales
import { enGB } from 'date-fns/locale/en-GB'
import { es } from 'date-fns/locale/es'
import { de } from 'date-fns/locale/de'
import { fr } from 'date-fns/locale/fr'
import { nl } from 'date-fns/locale/nl'
import { pt } from 'date-fns/locale/pt'
import { it } from 'date-fns/locale/it'
import { tr } from 'date-fns/locale/tr'
import { startOfToday } from 'date-fns/startOfToday'

// Register all required locales
registerLocale('en-GB', enGB)
registerLocale('es', es)
registerLocale('de', de)
registerLocale('fr', fr)
registerLocale('nl', nl)
registerLocale('pt', pt)
registerLocale('it', it)
registerLocale('tr', tr)

const localesMap = {
	'en-GB': enGB,
	es: es,
	de: de,
	fr: fr,
	nl: nl,
	pt: pt,
	it: it,
	tr: tr,
}
type LocaleKey = keyof typeof localesMap

// Market code to locale mapping
const marketLocaleMap: { [key: string]: string } = {
	// Americas
	ARG: 'es',
	BOL: 'es',
	BRA: 'pt',
	CAN: 'en-GB',
	CHL: 'es',
	COL: 'es',
	CRI: 'es',
	ECU: 'es',
	MEX: 'es',
	NIC: 'es',
	PAN: 'es',
	PRY: 'es',
	PER: 'es',
	URY: 'es',
	USA: 'en-GB',
	VEN: 'es',

	// Europe
	AUT: 'de',
	BEL: 'fr',
	BGR: 'en-GB',
	CZE: 'en-GB',
	CRO: 'en-GB',
	CYP: 'en-GB',
	DNK: 'en-GB',
	FIN: 'en-GB',
	FRA: 'fr',
	GER: 'de',
	GBR: 'en-GB',
	GRE: 'en-GB',
	HUN: 'en-GB',
	IRL: 'en-GB',
	ITA: 'it',
	LVA: 'en-GB',
	LTU: 'en-GB',
	HOL: 'nl',
	NOR: 'en-GB',
	POL: 'en-GB',
	POR: 'pt',
	ROM: 'en-GB',
	RUS: 'en-GB',
	SPA: 'es',
	SWE: 'en-GB',
	SWI: 'de',
	TUR: 'tr',
	UKR: 'en-GB',

	// Asia Pacific
	AUS: 'en-GB',
	BGD: 'en-GB',
	CHI: 'en-GB',
	IND: 'en-GB',
	IDN: 'en-GB',
	JPN: 'en-GB',
	KOR: 'en-GB',
	MYS: 'en-GB',
	NZL: 'en-GB',
	SGP: 'en-GB',
	LKA: 'en-GB',
	THA: 'en-GB',

	// Middle East & Africa
	EGY: 'en-GB',
	ISR: 'en-GB',
	JOR: 'en-GB',
	LBN: 'en-GB',
	MAR: 'en-GB',
	QAT: 'en-GB',
	ZAF: 'en-GB',
	TUN: 'en-GB',
	ARE: 'en-GB',
}

const getLocaleByMarket = (marketCode: string): string => {
	return marketLocaleMap[marketCode] || 'en-GB'
}

const convertDateString = (dateString: string) => {
	const [day, month, year] = dateString.split('/')
	return `${year}-${month}-${day}`
}

// Custom hook to memoize converted dates
// const useConvertedDates = (departureList: IOption[]) => {
// 	return useMemo(() => {
// 		return departureList.map((el) => {
// 			const dateParts = el.label.split('/')
// 			return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
// 		})
// 	}, [departureList])
// }

interface DatePickerWrapperProps {
	departureList: IOption[]
	pickersWrapperRef?: HTMLDivElement | null
}

const DatePickerWrapper = ({ departureList, pickersWrapperRef }: DatePickerWrapperProps) => {
	const viewport = useViewportType()
	const today = startOfToday()

	const { departureTo, departureFrom, setDateFilter } = useElasticFilterState((state) => ({
		departureTo: state.options.departureTo,
		departureFrom: state.options.departureFrom,
		setDateFilter: state.setDateFilter,
	}))

	const market = useCurrentMarket()
	const sitecoreStore = useSitecoreState((state) => state)

	const departureFromDate = departureFrom && new Date(convertDateString(departureFrom))
	const departureToDate = departureTo && new Date(convertDateString(departureTo))
	const currentYear = new Date().getFullYear()

	const [monthsToShow, setMonthsToShow] = useState(3)
	const [includeDates, setIncludeDates] = useState<Date[]>([])
	const [startDate, setStartDate] = useState<Date | undefined>(undefined)
	const [endDate, setEndDate] = useState<Date | undefined>(undefined)
	const [isCalendarOpen, setIsCalendarOpen] = useState(false)
	const [locale, setLocale] = useState<string>(getLocaleByMarket(market.code) || 'en-GB')

	const calendarRef = useRef<HTMLDivElement>(null)
	const departureInputRef = useRef<HTMLInputElement>(null)
	const returnInputRef = useRef<HTMLInputElement>(null)
	const calendarLabelRef = useRef<HTMLParagraphElement>(null)

	const dayLangOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}

	const handleBothDateChange = (dateFrom: Date | undefined, dateTo: Date | undefined) => {
		setDateFilter(dateFrom, dateTo)
	}

	useEffect(() => {
		if (departureFromDate) {
			setStartDate(departureFromDate)
		}
		if (departureToDate) {
			setEndDate(departureToDate)
		}
		const marketLocale = getLocaleByMarket(market.code)
		setLocale(marketLocale)
	}, [])

	const isCalendarMultiple = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_MULTIPLE_VIEW', 'false')

	useEffect(() => {
		if (viewport === 'isMobile') {
			setMonthsToShow(2)
		} else if (viewport === 'isTablet') {
			setMonthsToShow(2)
		} else if (viewport === 'isDesktop') {
			isCalendarMultiple === 'true' ? setMonthsToShow(3) : setMonthsToShow(2)
		} else if (viewport === 'isDesktopLarge') {
			isCalendarMultiple === 'true' ? setMonthsToShow(3) : setMonthsToShow(2)
		} else {
			isCalendarMultiple === 'true' ? setMonthsToShow(4) : setMonthsToShow(2)
		}
	}, [viewport])

	useEffect(() => {
		const convertedDates = departureList.map((el) => new Date(el.label))
		setIncludeDates(convertedDates)
		console.log('departureList: ', departureList)
	}, [departureList])

	const updateDates = useCallback(() => {
		if (departureInputRef.current !== document.activeElement) {
			if (departureInputRef.current) {
				departureInputRef.current.value = startDate?.toLocaleDateString(locale, dayLangOptions) || ''
			}
		}
		if (returnInputRef.current !== document.activeElement) {
			if (returnInputRef.current) {
				returnInputRef.current.value = endDate?.toLocaleDateString(locale, dayLangOptions) || ''
			}
		}
	}, [startDate, endDate])

	//called when click on date inside calendar
	const handleDateChange = useCallback((dates: [Date | null, Date | null]) => {
		const [start, end] = dates

		if (departureInputRef.current !== document.activeElement && returnInputRef.current !== document.activeElement) {
			setStartDate(start ? start : undefined)
			setEndDate(end ? end : undefined)
			start && end ? handleBothDateChange(start, end) : null
		}
	}, [])

	//called when writing to input textbox for dates
	const handleDateInputChange = (input: string, isStartDate: boolean) => {
		// Remove non-numeric characters
		const numericInput = input.replace(/\D/g, '')

		// Add slashes between day, month, and year
		let formattedInput = numericInput
		if (numericInput.length > 2) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2)}`
		}
		if (numericInput.length > 4) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4)}`
		}

		// Update the input field value
		if (isStartDate && departureInputRef.current) {
			departureInputRef.current.value = formattedInput
		} else if (returnInputRef.current) {
			returnInputRef.current.value = formattedInput
		}

		// Parse the date
		const localeObj = localesMap[locale as LocaleKey] || enGB
		const formats = ['dd/MM/yyyy', 'd MMMM yyyy']
		const inputWithYear = formattedInput.match(/\d{4}/) ? formattedInput : `${formattedInput} ${currentYear}`

		let parsedDate = null
		for (const format of formats) {
			parsedDate = parse(inputWithYear, format, new Date(), { locale: localeObj })
			if (isValid(parsedDate)) break
		}

		// Ensure the date is not before today
		if (parsedDate && parsedDate < today) {
			parsedDate = today
		}

		// Ensure the year is not lower than the current year
		if (parsedDate && parsedDate.getFullYear() < currentYear) {
			parsedDate.setFullYear(currentYear)
		}

		const date = isValid(parsedDate) ? parsedDate : undefined
		if (date) {
			const lastAvailableDate = startDate ? new Date(startDate) : undefined
			lastAvailableDate?.setMonth(lastAvailableDate.getMonth() + 4)

			if (startDate && date < startDate && !isStartDate) {
				date.setTime(startDate.getTime())
			} else if (lastAvailableDate && date > lastAvailableDate) {
				date.setTime(lastAvailableDate.getTime())
			}

			if (isStartDate) {
				setStartDate(date)
			} else {
				setEndDate(date)
				handleBothDateChange(startDate, date)
			}
		}
	}

	const handleBlur = (input: RefObject<HTMLInputElement>, date: Date | undefined) => {
		if (input.current) {
			input.current.value = date?.toLocaleDateString(locale, dayLangOptions) || ''
		}
	}

	const handleFocus = (input: RefObject<HTMLInputElement>) => {
		if (input.current) {
			input.current.value = ''
		}
	}

	const departureLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_DEPARTURE', 'Departure')
	const returnLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_RETURN', 'Return')
	const searchCalendarNoteLabel = sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-SENTENCE_BEFORE_CRUISE_RESULTS', 'The maximum search period is 4 months')

	const customInput = useMemo(
		() => (
			<div className="flex flex-1 justify-around py-2 px-5 text-sm text-light-purple border-msc-blue border focus:outline-none rounded-sm placeholder-transparent peer focus:ring-3 focus:ring-focus-blue">
				<div className="flex flex-1 items-center py-1 cursor-pointer">
					<div className="absolute pointer-events-none">
						<DeparturesIcon />
					</div>
					<input ref={departureInputRef} className="text-sm w-full text-msc-blue placeholder-msc-blue border-0 shadow-none pl-6 cursor-pointer" placeholder={departureLabel} defaultValue={startDate?.toLocaleDateString(locale, dayLangOptions) || ''} onFocus={() => handleFocus(departureInputRef)} onBlur={() => handleBlur(departureInputRef, startDate)} type="text" onChange={(e) => handleDateInputChange(e.target.value, true)} />
				</div>
				<div className="h-7 w-px bg-darker-grey mx-4"></div>
				<div className="flex flex-1 items-center py-1">
					<div className="absolute pointer-events-none">
						<ReturnIcon />
					</div>
					<input ref={returnInputRef} className="text-sm w-full text-msc-blue placeholder-msc-blue border-0 shadow-none pl-6 cursor-pointer" placeholder={returnLabel} defaultValue={endDate?.toLocaleDateString(locale, dayLangOptions) || ''} onFocus={() => handleFocus(returnInputRef)} onBlur={() => handleBlur(returnInputRef, endDate)} type="text" onChange={(e) => handleDateInputChange(e.target.value, false)} />
				</div>
			</div>
		),
		[startDate, endDate, locale, departureLabel, returnLabel]
	)

	useEffect(() => {
		if (isCalendarOpen) {
			const datePickerWrapper = calendarRef.current?.querySelector('.datePickerPopperWrapper')
			if (datePickerWrapper) {
				const computedStyle = window.getComputedStyle(datePickerWrapper)
				const transformMatrix = computedStyle.transform

				if (transformMatrix && transformMatrix !== 'none') {
					const matrixValues = transformMatrix.match(/matrix.*\((.+)\)/)
					if (matrixValues && matrixValues[1]) {
						matrixValues[1].split(', ')
					}
				}
			}
		}
	}, [isCalendarOpen])

	const CalendarContainer = ({ className, children }: { className: string; children: ReactElement }) => {
		return (
			<div
				className={`relative ${className}`}
				style={{
					maxWidth: monthsToShow === 2 && pickersWrapperRef?.clientWidth ? `${pickersWrapperRef?.clientWidth + 32}px` : 'auto',
				}}
			>
				{children}
				{isCalendarOpen && <p ref={calendarLabelRef} className={`absolute left-0 bottom-0 translate-y-full w-full text-xs bg-white px-6 pb-5 text-right`} dangerouslySetInnerHTML={{ __html: searchCalendarNoteLabel }} />}
			</div>
		)
	}

	const enableOnlyFourMonthsAfterSelectedDate = (date: Date) => {
		const startingDate = startDate
		if (!startingDate) return true

		const fourMonthsAfter = new Date(startingDate)
		fourMonthsAfter.setMonth(fourMonthsAfter.getMonth() + 4)

		return date >= new Date() && date <= fourMonthsAfter
	}

	const popperModifiers = [
		{
			name: 'offset',
			fn(state: { y: number; placement: string }) {
				if (state.placement === 'top-start' && calendarLabelRef.current) {
					state.y = state.y - calendarLabelRef.current.clientHeight
				}

				return state
			},
		},
	]

	return (
		<div ref={calendarRef} className="flex-1 w-full z-50">
			<DatePicker
				selected={startDate}
				shouldCloseOnSelect={true}
				onCalendarOpen={() => setIsCalendarOpen(true)}
				onCalendarClose={() => {
					setIsCalendarOpen(false)
					updateDates()
				}}
				onChange={handleDateChange}
				startDate={startDate}
				calendarContainer={CalendarContainer}
				popperClassName="w-full datePickerPopperWrapper"
				includeDates={includeDates}
				popperPlacement="bottom-start"
				wrapperClassName="w-full"
				customInput={customInput}
				dateFormat="dd/MM/yyyy"
				filterDate={enableOnlyFourMonthsAfterSelectedDate}
				endDate={endDate}
				monthsShown={monthsToShow}
				selectsRange={true}
				locale={locale}
				placeholderText="Departure from"
				popperModifiers={popperModifiers}
			/>
		</div>
	)
}

export { DatePickerWrapper }
