import React from 'react'

export const MscLogoIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" data-name="Livello 1" version="1.1" height="26" viewBox="13.94 12.76 268.04 56.69">
			<g>
				<path className="fill-white stroke-0" d="M168.18,33.17c-.99-.14-2.08-.17-3.47-.11-1.56.12-3,.47-4.24,1.04v-9.95c0-1.17-.95-2.12-2.12-2.12h-.73c-1.17,0-2.12.95-2.12,2.12v33.69c0,1.18.95,2.13,2.12,2.13h.73c.76,0,1.42-.4,1.8-1,.58.28,1.21.52,1.89.7,1.27.35,2.59.52,3.93.52s2.66-.17,3.95-.52c4.14-1.17,6.52-3.85,6.52-7.38v-11.36c0-4.02-3.16-7-8.25-7.75M163.36,54.83c-.87-.23-2.89-.94-2.89-2.55v-11.36c0-1.28,1.32-2.04,2.44-2.46.71-.22,1.47-.36,2.16-.39.04,0,.07,0,.11,0,.68-.07,1.46-.04,2.33.08.4.05,3.96.61,3.96,2.78v11.36c0,1.62-2.01,2.33-2.9,2.56-1.61.44-3.55.45-5.19,0" />
				<path className="fill-white stroke-0" d="M196.35,33.14c-6.14,0-10.42,3.24-10.42,7.87v11.31c0,5.31,5.31,7.8,10.26,7.87h.18c5.01,0,10.4-2.46,10.4-7.87v-11.31c0-4.63-4.28-7.87-10.42-7.87M201.8,52.31c0,.51-.14,1.28-1.32,1.99-1.03.61-2.53.97-4.11.97h0c-2.65,0-5.46-1.04-5.46-2.96v-11.31c0-1.87,2.81-2.89,5.46-2.89s5.43,1.01,5.43,2.89v11.31Z" />
				<path className="fill-white stroke-0" d="M267.27,22.35c-.35-.35-.95-.35-1.31,0l-14.69,14.69c-.17.17-.27.4-.27.65s.1.48.27.65c.17.18.4.27.65.27s.48-.09.65-.27l14.69-14.7c.36-.36.36-.94,0-1.3" />
				<path className="fill-white stroke-0" d="M256.46,26.14c-.35-.35-.96-.35-1.3,0l-7.64,7.64c-.36.36-.36.94,0,1.3.17.17.41.27.65.27s.48-.09.66-.27l7.64-7.64c.36-.36.36-.94,0-1.3" />
				<path className="fill-white stroke-0" d="M226.65,33.14c-6.14,0-10.42,3.24-10.42,7.87v11.31c0,5.31,5.32,7.8,10.25,7.87h.19c5.01,0,10.4-2.46,10.4-7.87v-11.31c0-4.63-4.29-7.87-10.42-7.87M232.1,52.31c0,.51-.14,1.28-1.33,1.99-1.03.61-2.52.97-4.1.97-2.65,0-5.47-1.04-5.47-2.96v-11.31c0-1.87,2.82-2.89,5.47-2.89s5.43,1.01,5.43,2.89v11.31Z" />
				<path className="fill-white stroke-0" d="M246.47,58.42c0,.96.78,1.75,1.75,1.75h1.82c.96,0,1.75-.78,1.75-1.75v-6.6l-5.31-5.31v11.92Z" />
				<path className="fill-white stroke-0" d="M266.44,59.53c.33.41.83.64,1.35.64h2.38c.67,0,1.29-.39,1.58-1,.28-.61.2-1.33-.23-1.85l-10.66-13.05-3.78,3.78,9.35,11.47Z" />
				<path className="fill-[#d0672f] stroke-0" d="M281.89,22.57c-.12-.29-.4-.48-.71-.48h-6.79c-.21,0-.4.08-.55.23l-7.95,7.95-9.11,9.11-4.22,4.23-7.58-7.58c-.29-.29-.81-.29-1.1,0l-3.39,3.39c-.3.3-.3.8,0,1.1l5.99,5.99,5.31,5.31.23.22c.14.14.34.23.55.23s.41-.09.55-.23l3.98-3.98,3.78-3.78,20.87-20.86c.22-.22.29-.56.16-.85" />
			</g>
			<g>
				<path className="fill-white stroke-0" d="M81.27,52.65h-.01c-.64-.07-1.3-.1-1.94-.13-.63-.03-1.29-.07-1.93-.13-.64.07-1.3.1-1.93.13-.64.03-1.29.07-1.94.13h-.08c-.66,0-1.37-.02-1.37-.67s.38-.82.99-1.09c.71-.32.79-.81.79-2.63v-10.53c0-.35-.03-.64-.07-.76-.12.1-.32.5-.53.93l-.14.29-6.44,13.46c-.33.66-.69,1-1.07,1-.46,0-.66-.41-.82-.73l-.02-.04-6.31-13.96c-.32-.76-.47-.84-.52-.84-.05,0-.12,0-.12.61v8.69c0,1.82.32,3.62,2.63,4.51.5.19.97.45.97,1.09,0,.67-.71.67-1.02.67-.57,0-1.15-.07-1.71-.13-.55-.06-1.12-.13-1.68-.13s-1.13.07-1.68.13c-.56.06-1.14.13-1.72.13-.31,0-.82,0-.82-.67,0-.57.39-.78.84-1.02l.11-.06c1.55-.85,2.03-1.68,2.03-3.56v-12.41c0-1.28-.39-2.98-1.51-3.52-.19-.09-.36-.17-.51-.24-.73-.34-1.09-.51-1.09-1.04,0-.48.16-.67.56-.67.49,0,.99.03,1.47.07.47.03.97.07,1.45.07s.97-.03,1.45-.07c.48-.03.98-.07,1.46-.07.65,0,.96.09,1.23.64l6.31,13.37c.18.37.32.68.44.93.2.43.35.75.43.87.09-.1.27-.44.41-.72.08-.15.16-.3.24-.46l7.08-13.79c.35-.68.51-.84.87-.84.49,0,.99.03,1.47.07.47.03.96.07,1.45.07s.97-.03,1.45-.07c.48-.03.98-.07,1.47-.07.11,0,.21.04.28.12.1.11.14.3.12.56,0,.58-.56.78-1.48,1.11l-.17.06c-1.07.36-1.59.62-1.59,2.53v11.68c0,2.7.13,4.4,2.59,5.36.66.26,1.06.45,1.06,1.09s-.76.67-1.44.67h0Z" />
				<path className="fill-white stroke-0" d="M94.23,52.9c-1.47,0-2.83-.34-4.14-.66-.58-.14-1.18-.29-1.79-.41-.04,0-.08-.02-.12-.02-.33-.06-.89-.16-1.05-.71l-1.14-4.52c-.02-.09-.05-.2-.08-.3-.07-.26-.15-.56-.15-.8,0-.17.07-.74.94-.74.69,0,.95.69,1.14,1.19.04.11.08.22.13.32,1.06,2.27,3.3,4.69,6.37,4.69,2.28,0,3.87-1.44,3.87-3.51s-2.18-2.9-4.71-3.8c-3.28-1.16-7.36-2.61-7.36-7.79,0-3.65,3.06-6.62,6.82-6.62,1.75,0,3.09.5,4.06.87.6.22,1.07.4,1.43.4.13,0,.42-.12.64-.22.28-.12.53-.23.72-.23.69,0,.78.47.78.87-.05,1.32.2,3.18.35,4.29.07.51.1.76.1.87,0,.6-.35.97-.91.97-.74,0-.93-.61-1.17-1.37l-.04-.14c-.73-2.31-3.41-4.33-5.74-4.33-1.71,0-3.45.94-3.45,2.73,0,2.24,2.21,3,4.77,3.87,3.42,1.16,7.29,2.48,7.29,7.69,0,3.57-2.89,7.43-7.57,7.43h0Z" />
				<path className="fill-white stroke-0" d="M116.97,52.9c-5.53,0-11.24-4.15-11.24-11.1,0-4.18,1.29-7.55,3.74-9.75,2.03-1.83,4.84-2.84,7.92-2.84,2.38,0,4.38.78,5.7,1.3.58.23,1.08.42,1.32.42s.29-.1.43-.35c.15-.28.36-.66.97-.66.35,0,.6.21.66.57l.48,4.43c.03.26.07.53.11.79.06.35.11.68.11.92,0,.51-.25,1.03-.73,1.03s-.82-.64-1.02-1.03c-.02-.04-.04-.08-.06-.11-1.92-3.45-4.49-5.35-7.24-5.35-4.53,0-6.73,2.99-6.73,9.16,0,5.21,3.2,9.45,7.14,9.45,2.33,0,4.76-1.11,6.65-3.04.07-.07.14-.15.21-.23.36-.39.81-.89,1.34-.89.18,0,.6.07.6.71,0,1.11-3.48,6.55-10.35,6.55h0Z" />
				<g>
					<path className="fill-white stroke-0" d="M32.44,26.03l-1.48,5.6s.65-.11,1.48-.11v-5.48Z" />
					<path className="fill-white stroke-0" d="M24.17,36.31l-2.89-1.69,2.26,3.03c.18-.48.38-.89.64-1.34Z" />
					<path className="fill-white stroke-0" d="M17.44,41.1l5.57,1.49c-.07-.48-.11-.99-.11-1.49,0,0-5.46,0-5.46,0Z" />
					<path className="fill-white stroke-0" d="M27.66,32.79l-1.68-2.91.46,3.75c.4-.33.77-.58,1.22-.84Z" />
					<path className="fill-white stroke-0" d="M25.99,52.34l3.02-2.29c-.48-.18-.89-.38-1.34-.64l-1.68,2.93Z" />
					<path className="fill-white stroke-0" d="M32.44,56.18l1.48-5.6c-.48.07-.98.12-1.48.12,0,0,0,5.48,0,5.48Z" />
					<path className="fill-white stroke-0" d="M21.28,47.59l3.73-.46c-.33-.4-.58-.78-.84-1.23l-2.89,1.69Z" />
					<path className="fill-white stroke-0" d="M41.34,44.56c-.18.48-.38.89-.64,1.34l2.89,1.69-2.26-3.03Z" />
					<path className="fill-white stroke-0" d="M47.43,41.1l-5.57-1.49c.07.48.11.99.11,1.49,0,0,5.46,0,5.46,0Z" />
					<path className="fill-white stroke-0" d="M37.21,49.41l1.68,2.93-.46-3.77c-.4.33-.77.58-1.22.84Z" />
					<path className="fill-white stroke-0" d="M38.89,29.88l-3.02,2.27c.48.18.89.38,1.33.64l1.68-2.91Z" />
					<path className="fill-white stroke-0" d="M43.6,34.62l-3.73.46c.33.4.57.77.84,1.23l2.89-1.69Z" />
					<path className="fill-white stroke-0" d="M41.67,43.59l9.25-2.49-9.25-2.49,3.76-5.06-6.23.75.74-6.28-5.04,3.79-2.47-9.3-2.48,9.3-5.02-3.78.74,6.27-6.24-.75,3.76,5.06-9.25,2.49,9.25,2.49-3.77,5.06,6.24-.75-.74,6.28,5.02-3.79,2.48,9.3,2.47-9.3,5.04,3.79-.75-6.27,6.25.75-3.77-5.06ZM38.81,48.24l.6,5.01-4.02-3.03c-.32.11-.64.19-.98.26l-1.98,7.44-1.97-7.44c-.35-.07-.67-.16-1-.27l-4.01,3.03.6-5.01c-.26-.23-.5-.47-.73-.73l-4.98.61,3.01-4.03c-.11-.33-.19-.65-.27-.99l-7.4-1.99,7.4-1.98c.08-.37.16-.67.27-.99l-3.01-4.04,4.98.6c.23-.26.47-.5.73-.73l-.6-5.01,4.01,3.03c.32-.11.65-.19.99-.26l1.98-7.44,1.96,7.44c.35.07.68.16,1,.27l4.01-3.03-.6,5.01c.26.23.49.47.72.73l4.98-.61-3.01,4.03c.11.33.19.63.27,1l7.4,1.98-7.4,1.98c-.08.36-.16.67-.27,1l3.01,4.03-4.98-.61c-.23.26-.47.5-.73.73Z" />
					<path className="fill-white stroke-0" d="M40.83,40.79c-.17,0-.31.14-.31.32s.14.32.31.32.32-.14.32-.32-.14-.32-.32-.32Z" />
					<path className="fill-white stroke-0" d="M36.48,34.07c.15.09.34.04.43-.12.09-.15.03-.34-.11-.43-.15-.09-.34-.04-.43.12-.09.15-.03.34.11.43Z" />
					<path className="fill-white stroke-0" d="M28.4,34.07c.15-.09.2-.28.12-.43-.09-.15-.28-.2-.43-.11-.15.09-.2.28-.11.43.09.15.28.2.43.12Z" />
					<path className="fill-white stroke-0" d="M24.05,40.79c-.17,0-.31.14-.31.32s.14.32.31.32.31-.14.31-.32-.14-.32-.31-.32Z" />
					<path className="fill-white stroke-0" d="M28.4,48.13c-.15-.09-.34-.04-.43.12-.09.15-.04.34.11.43.15.09.34.04.43-.12.09-.15.04-.34-.11-.43Z" />
					<path className="fill-white stroke-0" d="M32.44,31.9c-5.06,0-9.15,4.12-9.15,9.2s4.1,9.2,9.15,9.2,9.15-4.12,9.15-9.2-4.1-9.2-9.15-9.2ZM36.2,33.55c.14-.24.45-.32.69-.18.24.14.32.45.18.69-.14.24-.45.32-.69.18-.24-.14-.32-.45-.18-.69ZM27.99,33.36c.24-.14.55-.06.69.18.14.24.06.55-.18.69-.24.14-.55.06-.69-.18-.14-.24-.06-.55.18-.69ZM24.05,41.61c-.28,0-.5-.23-.5-.51s.22-.5.5-.5.5.23.5.5-.22.51-.5.51ZM28.68,48.66c-.14.24-.45.33-.69.19-.24-.14-.32-.45-.18-.69.14-.24.45-.32.69-.18.24.14.32.45.18.69ZM36.88,48.85c-.24.14-.55.06-.69-.18-.14-.24-.06-.55.18-.69.24-.14.55-.06.68.19.14.24.06.55-.18.69ZM32.44,48.78c-4.21,0-7.63-3.43-7.63-7.67s3.42-7.67,7.63-7.67,7.63,3.44,7.63,7.67-3.42,7.67-7.63,7.67ZM40.83,41.61c-.28,0-.5-.22-.5-.5s.23-.5.5-.5.5.23.5.5-.23.5-.5.5Z" />
					<path className="fill-white stroke-0" d="M36.47,48.13c-.15.09-.2.28-.11.43.09.15.28.2.43.12.15-.09.2-.28.12-.43-.09-.15-.28-.2-.43-.12Z" />
					<path className="fill-white stroke-0" d="M36.78,41.1v-3.44c-.06-1.15-1.03-2.04-2.17-1.98-.58.03-1.12.3-1.49.74-.71-.87-1.99-1-2.86-.28-.07.06-.14.12-.2.19v-.51h-1.97v5.28c-.4.18-.86.16-1.25-.06l-.31.54c.55.31,1.22.35,1.8.09.88-.36,1.87-.36,2.75,0,.43.17.89.26,1.35.25.46,0,.92-.08,1.35-.25.88-.36,1.87-.35,2.75,0,.58.25,1.25.22,1.8-.09l-.31-.54c-.38.21-.84.23-1.25.06ZM31.47,41.12c-.45-.18-.92-.29-1.39-.35v-2.92c.06-.33.34-.58.68-.58.39,0,.71.3.71.69v3.15ZM34.8,40.75c-.48.08-.94.21-1.39.39v-3.18c.01-.35.29-.64.63-.67.38-.04.72.25.76.63v2.82Z" />
					<path className="fill-white stroke-0" d="M34.32,43.2c.39-.26.84,0,1.02.57h1.22c-.01-.59-.32-1.13-.82-1.44-.72-.52-1.7-.47-2.36.13-.4.35-.64.85-.66,1.38-.07.53,0,1.08.18,1.58.21.47.61.83,1.1,1,.47.18,1,.15,1.44-.09.63-.3,1.06-.92,1.11-1.62h-1.21c-.08.26-.22.46-.39.57-.39.26-.84,0-1.02-.57-.17-.57,0-1.25.39-1.5Z" />
					<path className="fill-white stroke-0" d="M31.71,44.18c-.13-.09-.27-.15-.42-.18-.36-.12-.72-.2-1.09-.25-.19-.03-.37-.14-.46-.31-.06-.19.03-.39.21-.47.16-.08.34-.09.51-.05.2.03.35.17.39.37h1.32c0-.49-.32-.93-.78-1.09-.77-.32-1.64-.32-2.4.02-.63.28-.91,1.02-.63,1.65.05.11.11.2.18.29.31.27.69.44,1.09.5.07,0,.14.02.22.04.14.03.28.06.41.09.11.02.21.06.31.11.12.06.2.18.2.31,0,.14-.09.27-.23.32-.22.09-.46.09-.68,0-.13-.05-.22-.18-.23-.32h-1.3c0,.58.41,1.09.98,1.2.76.24,1.58.17,2.28-.19.58-.35.77-1.11.41-1.7-.08-.13-.18-.24-.3-.34Z" />
				</g>
			</g>
			<rect className="fill-white stroke-0" x="140.97" y="12.76" width="1.09" height="56.69" />
		</svg>
	)
}
