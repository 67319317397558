'use client'

import clsx from 'clsx'
import Link from 'next/link'
import React, { ReactNode } from 'react'

interface ButtonProps {
	children: string | ReactNode
	variant?: 'filled' | 'filledSecondary' | 'outlined' | 'outlinedSecondary' | 'outlinedTertiary' | 'incrementableInput' | 'zoomButton'
	bgColor?: 'primary' | 'secondary'
	link?: string
	icon?: React.ReactNode
	isIconRight?: boolean
	className?: string
	type?: 'button' | 'submit' | 'reset' | undefined
	disabled?: boolean
	isSubmitting?: boolean
	onClick?: () => any | Promise<any>
}

const Button = ({ children, variant = 'filled', bgColor = 'primary', link, icon, isIconRight = false, className, type = 'button', disabled = false, isSubmitting = false, onClick }: ButtonProps) => {
	const [isLoading, setIsLoading] = React.useState(false)
	const inProgress = isSubmitting || isLoading

	const baseClasses = `rounded uppercase font-semibold ${variant !== 'incrementableInput' && variant !== 'zoomButton' ? 'px-4 py-2' : ''} flex justify-center items-center gap-3 transition-all`

	const variantClasses = clsx({
		'bg-primary text-white': variant === 'filled' && bgColor === 'primary',
		'border-2 bg-msc-blue text-white': variant === 'filledSecondary',
		'bg-primary text-white opacity-50': variant === 'filled' && bgColor === 'primary' && (disabled || inProgress),
		'bg-secondary text-white': variant === 'filled' && bgColor === 'secondary',
		'border-2 border-msc-blue text-msc-blue': variant === 'outlined',
		'border-2 border-primary text-msc-blue': variant === 'outlinedSecondary',
		'border-2 border-customBlue text-customBlack': variant === 'outlinedTertiary',
		'border-2 border-msc-blue text-msc-blue !rounded-full py-0 px-0 w-8 h-8': variant === 'incrementableInput',
		'border-2 border-msc-blue text-msc-blue !rounded-full py-0 px-0 w-6 h-6': variant === 'zoomButton',
		'opacity-50 cursor-not-allowed': disabled,
		'opacity-50 cursor-wait': inProgress,
	})

	const combinedClasses = clsx(baseClasses, variantClasses, className)

	const content = (
		<>
			{!isIconRight && icon && <span className="icon">{icon}</span>}
			{children}
			{isIconRight && icon && <span className="icon">{icon}</span>}
		</>
	)

	const handleClick = async () => {
		if (!onClick || disabled || inProgress) return

		try {
			setIsLoading(true)
			await onClick()
		} finally {
			setIsLoading(false)
		}
	}

	const linkHtml = (
		<Link prefetch={false} href={link || ''} className={combinedClasses} onClick={onClick} aria-disabled={disabled || inProgress} tabIndex={disabled || inProgress ? -1 : undefined}>
			{content}
		</Link>
	)

	const buttonHtml = (
		<button type={type} className={combinedClasses} onClick={handleClick} disabled={disabled || inProgress} aria-disabled={disabled || inProgress}>
			{content}
		</button>
	)

	return link ? linkHtml : buttonHtml
}

export { Button }
