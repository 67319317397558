import React from 'react'

interface VoyagerIconInterface {
	color?: string
}

export const VoyagerIcon = ({ color = '#000033' }: VoyagerIconInterface) => {
	return (
		<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3 14V8V8.24375V5V14ZM13.425 15.875L10.8 13.25C10.6375 13.0875 10.5219 12.9125 10.4531 12.725C10.3844 12.5375 10.35 12.35 10.35 12.1625C10.35 11.7625 10.4937 11.4062 10.7812 11.0938C11.0688 10.7812 11.4375 10.625 11.8875 10.625C12.2375 10.625 12.5125 10.7062 12.7125 10.8687C12.9125 11.0312 13.15 11.25 13.425 11.525C13.675 11.275 13.9031 11.0625 14.1094 10.8875C14.3156 10.7125 14.6 10.625 14.9625 10.625C15.425 10.625 15.7969 10.7844 16.0781 11.1031C16.3594 11.4219 16.5 11.7812 16.5 12.1812C16.5 12.3687 16.4625 12.5562 16.3875 12.7437C16.3125 12.9312 16.2 13.1 16.05 13.25L13.425 15.875ZM3 6.5H15V5H3V6.5ZM8.68125 15.5H3C2.5875 15.5 2.23438 15.3531 1.94063 15.0594C1.64688 14.7656 1.5 14.4125 1.5 14V5C1.5 4.5875 1.64688 4.23438 1.94063 3.94063C2.23438 3.64688 2.5875 3.5 3 3.5H15C15.4125 3.5 15.7656 3.64688 16.0594 3.94063C16.3531 4.23438 16.5 4.5875 16.5 5V8.99375C16.0625 8.68125 15.5844 8.4375 15.0656 8.2625C14.5469 8.0875 14 8 13.425 8C12.7125 8 12.0406 8.13438 11.4094 8.40312C10.7781 8.67188 10.225 9.0375 9.75 9.5H3V14H8.23125C8.26875 14.2625 8.325 14.5188 8.4 14.7688C8.475 15.0188 8.56875 15.2625 8.68125 15.5Z" fill={color} />
		</svg>
	)
}
