import React, { memo } from 'react'
import { useCalcCountdown } from '../ui-hooks/useCalcCountdown'
import { Tag } from '../uiComponents'

interface CountdownProps {
	date: string
	isBoxedDate?: boolean
}

const Countdown = memo(({ date, isBoxedDate = false }: CountdownProps) => {
	const countdownCalc = useCalcCountdown(date, isBoxedDate)
	if (countdownCalc === 'countdownExpired') {
		return ''
	} else {
		if (isBoxedDate) {
			return <div className={`text-xl text-white font-semibold`}>{countdownCalc}</div>
		} else {
			return (
				<Tag variant="countDown">
					<span className={`text-xs text-white font-semibold`}>{countdownCalc}</span>
				</Tag>
			)
		}
	}
})

Countdown.displayName = 'Countdown'

export { Countdown }
