import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as NotificationActions from './actions/requests'

const NotificationService = createProxiedActions<typeof NotificationActions>({
	modules: [
		NotificationActions,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default NotificationService
