import Link from 'next/link'
import React from 'react'

interface NavMenuItemProps {
	navMenuItem: string
	href: string
}

const NavMenuItem = ({ navMenuItem, href }: NavMenuItemProps) => {
	return (
		<Link prefetch={false} className="hidden md:inline cursor-pointer pb-7 text-white md:text-base desktop-xsm:text-sm" href={href}>
			{navMenuItem}
		</Link>
	)
}

export { NavMenuItem }
