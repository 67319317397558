'use client'
import Link from 'next/link'
import React from 'react'

export interface NotificationAttachmentModalTemplateProps {
	attachmentUrl: string
}

export const NotificationAttachmentModalTemplate = ({ attachmentUrl }: NotificationAttachmentModalTemplateProps) => {
	return (
		<object data={attachmentUrl} id="notification-pdf-viewer" type="application/pdf" width="100%" height="500px">
			<p className="text-msc-blue">
				Cannot display a PDF file on this device.{' '}
				<Link prefetch={false} href={attachmentUrl} className="underline font-semibold">
					Download
				</Link>{' '}
				it instead.
			</p>
		</object>
	)
}
