import Link from 'next/link'
import React, { ReactElement } from 'react'

interface ActionLinkProps {
	link?: string
	onClick: (e: any) => void
	icon: ReactElement
	customStyles?: any
	customClasses?: string
	children: string | ReactElement
}

const ActionLink = ({ link = '#', onClick, icon, customStyles, customClasses, children }: ActionLinkProps) => {
	return (
		<Link prefetch={false} href={link} className={`flex items-center text-sm text-focus-blue gap-2 ${customClasses}`} onClick={onClick}>
			<span className="underline">{children}</span>
			<div className="transition-all" style={customStyles}>
				{icon}
			</div>
		</Link>
	)
}

export { ActionLink }
