import React from 'react'

interface RatesIconInterface {
	color?: string
}

export const RatesIcon = ({ color = '#000033' }: RatesIconInterface) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<path d="M13.2225 4.38C12.9525 3.9975 12.5025 3.75 12 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V12.75C2.25 13.1478 2.40804 13.5294 2.68934 13.8107C2.97064 14.092 3.35218 14.25 3.75 14.25H12C12.5025 14.25 12.9525 13.995 13.2225 13.6125L16.5 9L13.2225 4.38Z" fill={color}></path>
		</svg>
	)
}
