import WebsphereService from '@/features/websphere/websphere-service'
import { Logger } from '@/utils/lib/logger-pino'

const logger = new Logger('src/features/agent/actions/forgot-password')

export async function forgotPassword(username: string) {
	await checkUserFieldPwd(username)
	await invokeAjaxPersonChangeServicePasswordReset(username)
}

interface AjaxCheckUserFieldPwdCmdResponseType {
	usersField1: 'NOUSER' | 'NOPWD' | 'YES'
}

async function checkUserFieldPwd(username: string): Promise<void> {
	const response: AjaxCheckUserFieldPwdCmdResponseType = await WebsphereService.get({
		command: 'AjaxCheckUserFieldPwdCmd',
		market: 'current',
		request: {
			usersId: username,
		},
	})

	if (response.usersField1 === 'NOUSER') {
		logger.error(`User ${username} doesn't exist on commerce side`)
		throw new Error()
	}
}

interface AjaxPersonChangeServicePasswordResetResponseType {
	userId: string
}

async function invokeAjaxPersonChangeServicePasswordReset(username: string): Promise<void> {
	const response: AjaxPersonChangeServicePasswordResetResponseType = await WebsphereService.get({
		command: 'AjaxPersonChangeServicePasswordReset',
		market: 'current',
		request: {
			challengeAnswer: '-',
			state: 'passwdconfirm',
			logonId: username,
		},
	})

	if (typeof response.userId === 'number') {
		logger.error(`An error occured during the execution of the command AjaxPersonChangeServicePasswordReset for user ${username}`)
		throw new Error(`An error occured during the execution of the command AjaxPersonChangeServicePasswordReset for user ${username}`)
	}
}
