'use client'

import { Menu } from '@/features/header-menu/model/menu'
import React, { useState } from 'react'
import { MdClose, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { CountryFlag, DynamicPicture, MobileNavMenuButtons, MobileNavMenuLinks } from '../uiComponents'

interface MobileNavMenuProps {
	menu: Menu
	countryFlag: React.ReactElement<typeof CountryFlag>
	handleClickClose: () => void
}

const MobileNavMenu = (props: MobileNavMenuProps) => {
	const [linksVisible, setLinksVisible] = useState<number | null>(null)

	const handleShowLinks = (index: number | null) => {
		setLinksVisible(linksVisible === index ? null : index)
	}

	return (
		<div className="bg-msc-blue inset-0 h-dvh w-dvw overflow-y-auto fixed">
			<div className="h-full px-3 flex flex-col justify-between">
				<div className="pt-2 px-6">
					<div className="py-7">
						<DynamicPicture image="pages/sdl/img/B2B_TA_Logo_neg_header.svg" basepath="websphere-base" alt="MSCBook logo" width={126} height={42} />
					</div>
					<div className="flex pb-7">
						<MdClose onClick={props.handleClickClose} color="white" size={28} />
						<p className="text-white pl-24 md:text-xl">Menu</p>
					</div>
					<ul className="flex flex-col gap-9 pb-28">
						{props.menu.subMenu.length > 0 &&
							props.menu.subMenu.map((data: any, index: any) => {
								return (
									<React.Fragment key={data.label || index}>
										{linksVisible !== index && (
											<li className="flex items-center gap-1 text-white" key={data.label} onClick={() => handleShowLinks(index)}>
												<span className="md:text-xl">{data.label}</span>
												<MdOutlineKeyboardArrowRight size={22} />
											</li>
										)}
										{linksVisible === index && <MobileNavMenuLinks headerLinks={data.subMenu} label={data.label} handleHideLinks={() => setLinksVisible(null)} />}
									</React.Fragment>
								)
							})}
					</ul>
				</div>
				{<MobileNavMenuButtons countryFlag={props.countryFlag} />}
			</div>
		</div>
	)
}

export { MobileNavMenu }
