'use client'

import { TranslationBundles } from '../configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'

export interface SitecoreProps {
	bundle: TranslationBundles
	keyName: string
	replacements?: string[]
	children: string
}

/**
 * @property {TranslationBundles} bundle - Bundle of sitecore
 * @property {string} keyName - Key of sitecore
 * @property {string[]} replacements - Array of value to substitute to the placeholder $index.
 * @property {string} children - Default value if the label is missing
 * @returns the label from sitecore after the replacement or a value accordingly to the sitecore override config (from devTools)
 */
export const Sitecore = (props: SitecoreProps) => {
	const sitecoreStore = useSitecoreState((state) => state)
	return sitecoreStore.getSpan(props.bundle, props.keyName, props.children, props.replacements)
}
