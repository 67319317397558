'use client'

import { createContext, useContext, useRef } from 'react'
import { createDevToolsStore, DevToolsState, DevToolsStoreInitialState } from './dev-tools-state'
import { StoreApi, useStore } from 'zustand'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'

interface DevToolsProviderProps {
	children: React.ReactNode
	initialState: DevToolsStoreInitialState
}

const DevToolsStoreContext = createContext<StoreApi<DevToolsState> | null>(null)

export function DevToolsProvider(props: DevToolsProviderProps) {
	const storeRef = useRef<StoreApi<DevToolsState> | null>(null)
	const environmentState = useEnvironmentState()

	if (!storeRef.current) {
		storeRef.current = createDevToolsStore({ ...props.initialState, environmentState })
	}

	return <DevToolsStoreContext.Provider value={storeRef.current}>{props.children}</DevToolsStoreContext.Provider>
}

export function useDevToolsState() {
	const store = useContext(DevToolsStoreContext)

	if (!store) throw new Error('useDevToolsState must be used within DevToolsProvider')

	return useStore(store)
}
