import React, { ReactNode } from 'react'

interface SeparatorTextProps {
	children: ReactNode
	color?: string
}

const SeparatorText = ({ children, color = 'white' }: SeparatorTextProps) => {
	return (
		<div className="flex items-center pb-3 w-full">
			<hr className="flex-1 flex-grow my-5 border-1 lg:ml-20" />
			<p className={`px-2 text-${color}`}>{children}</p>
			<hr className="flex-1 flex-grow my-5 border-1 lg:mr-20" />
		</div>
	)
}

export { SeparatorText }
