import DevToolsService from '@/features/dev-tools/dev-tools-service'
import { KeysOverride } from '@/features/sitecore/models/keys-override'
import { EnvironmentState } from '@/utils/environment/store/environment-state'
import { create } from 'zustand'
import { DevTools } from '../configs/devToolsConfigs'

export interface DevToolsState {
	enabled: boolean
	sitecoreKeysOverride: KeysOverride
	highlightLabels: boolean
	showBundleNames: boolean

	set: (key: DevTools, def: any) => void
}

export interface DevToolsStoreInitialState {
	sitecoreKeysOverride: KeysOverride
	highlightLabels: boolean
	showBundleNames: boolean
}

export const createDevToolsStore = (props: { highlightLabels: boolean; sitecoreKeysOverride: KeysOverride; showBundleNames: boolean; environmentState: EnvironmentState }) => {
	return create<DevToolsState>((set) => ({
		enabled: props.environmentState.getBoolean('ENABLE_DEV_TOOLS', false),
		...props,

		set: async (key, val) => {
			const success = await DevToolsService.set(key, val)
			if (!success) return
			set({ [key]: val })
		},
	}))
}
