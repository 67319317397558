import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as PricesB2BActions from './actions/pricesb2b-actions'

const PricesB2BService = createProxiedActions<typeof PricesB2BActions>({
	modules: [PricesB2BActions],
	proxies: [createLoggingProxy, createPerformanceProxy],
})

export default PricesB2BService
