import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { IOption } from '@/features/search-cruise/store/types'

// Step interfaces
interface DestinationStep {
	destination: IOption | null
	image: string
	isPolicyAccepted: boolean
	additionalText: string
}

interface ItineraryStep {
	itineraries: {
		ship: IOption | null
		season: IOption | null
		embarkationPort: IOption | null
		dateRange: string
		includedPackages: IOption[]
		manualPrice: number
	}[]
}

interface PromotionsStep {
	promotion: IOption | null
	manualPrice: number
	replaceWithPromoImage: boolean
}

interface AgentDetailsStep {
	travelAgencyLogo: Int8Array | null
	name: string
	email: string
	phoneNumber: string
	saveAgencyContactData: boolean
}

interface NotesStep {
	additionalNote: string
	helperText: string
}

interface Steps {
	destination: DestinationStep
	itinerary: ItineraryStep
	promotions: PromotionsStep
	agentDetails: AgentDetailsStep
	notes: NotesStep
}

// Actions interface
interface FlyerBuilderActions {
	setActiveStep: (step: number) => void
	setStepData: <K extends keyof Steps>(step: K, data: Partial<Steps[K]>) => void
	addItinerary: () => void
	updateItinerary: (index: number, data: Partial<Steps['itinerary']['itineraries'][0]>) => void
	removeItinerary: (index: number) => void
}

// Main state interface
export interface FlyerBuilderState extends FlyerBuilderActions {
	activeStep: number
	steps: Steps
}

// Initial state
export const INITIAL_STATE: Omit<FlyerBuilderState, keyof FlyerBuilderActions> = {
	activeStep: 1,
	steps: {
		destination: {
			destination: null,
			image: '',
			isPolicyAccepted: false,
			additionalText: '',
		},
		itinerary: {
			itineraries: [],
		},
		promotions: {
			promotion: null,
			manualPrice: 0,
			replaceWithPromoImage: false,
		},
		agentDetails: {
			travelAgencyLogo: null,
			name: '',
			email: '',
			phoneNumber: '',
			saveAgencyContactData: false,
		},
		notes: {
			additionalNote: '',
			helperText: '',
		},
	},
} as const

// Store implementation
export const useFlyerBuilderState = create<FlyerBuilderState>()(
	immer((set) => ({
		...INITIAL_STATE,

		setActiveStep: (step: number) =>
			set((state) => {
				state.activeStep = step
			}),

		setStepData: (step, data) =>
			set((state) => {
				state.steps[step] = {
					...state.steps[step],
					...data,
				}
			}),

		addItinerary: () =>
			set((state) => {
				state.steps.itinerary.itineraries.push({
					ship: null,
					season: null,
					embarkationPort: null,
					dateRange: '',
					includedPackages: [],
					manualPrice: 0,
				})
			}),

		updateItinerary: (index, data) =>
			set((state) => {
				state.steps.itinerary.itineraries[index] = {
					...state.steps.itinerary.itineraries[index],
					...data,
				}
			}),

		removeItinerary: (index) =>
			set((state) => {
				state.steps.itinerary.itineraries.splice(index, 1)
			}),
	}))
)
