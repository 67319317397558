'use client'
import { HydrationBoundary } from '@tanstack/react-query'
import React from 'react'

interface HydrationWrapperProps {
	children: React.ReactNode
	state: any
}

export default function HydrationWrapper(props: HydrationWrapperProps) {
	const [isClient, setIsClient] = React.useState(false)

	React.useEffect(() => {
		setIsClient(true)
	}, [])

	return <HydrationBoundary state={props.state}>{isClient ? props.children : null}</HydrationBoundary>
}
