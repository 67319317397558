'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import Link from 'next/link'

export const SuperUserBar = () => {
	const sitecoreState = useSitecoreState((state) => state)
	const provideUrl = useUrlProvider()
	const user = useLoggedUser()

	// YOU ARE A SUPERUSER (<link>back to agency selection</link>)
	const link = sitecoreState.getLabel(TranslationBundles.SUPERUSER, 'MSC-SUPERUSER-LABEL-HEADER', 'YOU ARE A SUPERUSER')

	// [ 'YOU ARE A SUPERUSER (', 'back to agency selection', ')' ]
	const textParts = link.replace('</link>', '<link>').split('<link>')
	const url = provideUrl('superuser-back-to-agency-selection', {
		user: user?.name,
		authenticationMethod: 'AZURE',
		flags: 'commerceSignout',
	})

	return (
		<div className="bg-primary text-center">
			<p className="text-white">
				{textParts[0]}
				<Link prefetch={false} href={url} className="underline">
					{textParts[1]}
				</Link>
				{textParts[2]}
			</p>
		</div>
	)
}
