import React from 'react'
import { Tag } from '../uiComponents'

import { IOption } from '@/features/search-cruise/store/types'

interface TagsGroupProps {
	values: IOption[]
	onChange?: (option: IOption) => void
}

export const TagsGroup = ({ values, onChange }: TagsGroupProps) => {
	const handleChange = (value: IOption) => {
		onChange?.(value)
	}

	return (
		<div className="flex flex-wrap gap-2 pt-2">
			{values.map((el: IOption, index) => {
				return (
					<React.Fragment key={index}>
						<Tag disabled={el.disabled} active={el.checked} onClick={() => handleChange(el)}>
							{el.label}
						</Tag>
					</React.Fragment>
				)
			})}
		</div>
	)
}
