import React from 'react'

const PromotionsStep = () => {
	return (
		<div>
			<h2 className="text-sm font-bold uppercase">Apply a promotion</h2>
		</div>
	)
}

export { PromotionsStep }
