import React from 'react'

interface ReturnIconInterface {
	color?: string
}

export const ReturnIcon = ({ color = '#000033' }: ReturnIconInterface) => {
	return (
		<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.75 14.25H2.25V6H12.75M10.5 0.75V2.25H4.5V0.75H3V2.25H2.25C1.4175 2.25 0.75 2.9175 0.75 3.75V14.25C0.75 14.6478 0.908035 15.0294 1.18934 15.3107C1.47064 15.592 1.85218 15.75 2.25 15.75H12.75C13.1478 15.75 13.5294 15.592 13.8107 15.3107C14.092 15.0294 14.25 14.6478 14.25 14.25V3.75C14.25 2.9175 13.575 2.25 12.75 2.25H12V0.75M11.25 9H7.5V12.75H11.25V9Z" fill={color} />
		</svg>
	)
}
