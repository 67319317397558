'use client'
import React, { useEffect, useRef, useState } from 'react'
import { Button, FlyerBuildetPagination } from '../uiComponents'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { BsChevronDoubleUp, BsChevronDoubleDown } from 'react-icons/bs'

import mockedFlyerTemplate from '../../__mock_data/mockedFlyerTemplate'

const emptyFlyerTemplate = `<main style="display: flex; flex-direction: column; width: 100%"></main>`

const FlyerBuilderPreview = () => {
	const previewRef = useRef<HTMLDivElement>(null)
	const firstSheetRef = useRef<HTMLDivElement>(null)
	const secondSheetRef = useRef<HTMLDivElement>(null)
	const thirdSheetRef = useRef<HTMLDivElement>(null)
	const [activeSheet, setActiveSheet] = useState<HTMLDivElement | null>(null)
	const [sheetsWithSections, setSheetsWithSections] = useState<number>(1)
	const sheetsContentRef = useRef<{ firstSheet: string; secondSheet: string; thirdSheet: string }>({
		firstSheet: mockedFlyerTemplate,
		secondSheet: emptyFlyerTemplate,
		thirdSheet: emptyFlyerTemplate,
	})
	const isFirstItemActive = activeSheet?.getAttribute('data-value') === '1'
	const isLastItemActive = activeSheet ? activeSheet.getAttribute('data-value')! >= sheetsWithSections.toString() : false

	const getSheets = () => ({
		firstSheet: firstSheetRef.current,
		secondSheet: secondSheetRef.current,
		thirdSheet: thirdSheetRef.current,
	})

	const applyScale = (scale: number) => {
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!firstSheet || !secondSheet || !thirdSheet) return
		firstSheet.style.scale = scale.toString()
		secondSheet.style.scale = scale.toString()
		thirdSheet.style.scale = scale.toString()
		if (activeSheet?.getAttribute('id') !== 'first-flyer-page') firstSheet.style.transform = 'translateY(150%)'
		if (activeSheet?.getAttribute('id') !== 'second-flyer-page') secondSheet.style.transform = 'translateY(150%)'
		if (activeSheet?.getAttribute('id') !== 'third-flyer-page') thirdSheet.style.transform = 'translateY(150%)'
		if (activeSheet) activeSheet.style.transform = 'translateY(0%)'
	}

	const calculateScale = () => {
		const preview = previewRef.current
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!preview || !firstSheet || !secondSheet || !thirdSheet) return

		const sheetWidth = firstSheet.offsetWidth
		const sheetHeight = firstSheet.offsetHeight
		const previewWidth = preview.offsetWidth
		const previewHeight = preview.offsetHeight - 96

		const scale = Math.min(previewWidth / sheetWidth, previewHeight / sheetHeight)
		applyScale(scale)
	}

	const handleFlyerPages = () => {
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!firstSheet || !secondSheet || !thirdSheet) return

		const sheetBodyHeight = firstSheet.offsetHeight - 40
		let firstPageSectionsHeight = 0
		let secondPageSectionsHeight = 0
		const firstSheetSections = firstSheet.querySelectorAll('section')
		firstSheetSections.forEach((section) => {
			if (section.style.display === 'none') return
			const sectionHeight = section.offsetHeight + parseFloat(window.getComputedStyle(section).marginTop)
			if (firstPageSectionsHeight + sectionHeight > sheetBodyHeight) {
				secondSheet.querySelector('main')?.appendChild(section)
				secondPageSectionsHeight += sectionHeight
				return
			}
			firstPageSectionsHeight += sectionHeight
		})
		const secondSheetSections = secondSheet.querySelectorAll('section')

		secondSheetSections.forEach((section) => {
			if (section.style.display === 'none') return
			const sectionHeight = section.offsetHeight + parseFloat(window.getComputedStyle(section).marginTop)
			if (firstPageSectionsHeight + sectionHeight <= sheetBodyHeight && sheetsWithSections === 2) {
				firstSheet.querySelector('main')?.appendChild(section)
				firstPageSectionsHeight += sectionHeight
				return
			}
			if (secondPageSectionsHeight + sectionHeight > sheetBodyHeight) {
				thirdSheet.querySelector('main')?.appendChild(section)
				return
			}
			secondPageSectionsHeight += sectionHeight
		})
		const thirdSheetSections = thirdSheet.querySelectorAll('section')
		thirdSheetSections.forEach((section) => {
			if (section.style.display === 'none') return
			const sectionHeight = section.offsetHeight + parseFloat(window.getComputedStyle(section).marginTop)
			if (secondPageSectionsHeight + sectionHeight <= sheetBodyHeight && sheetsWithSections === 3) {
				secondSheet.querySelector('main')?.appendChild(section)
				secondPageSectionsHeight += sectionHeight
				return
			}
		})

		const sheetsCount = [firstSheet, secondSheet, thirdSheet].filter((sheet) => sheet.querySelectorAll('section').length > 0).length
		if (sheetsCount !== sheetsWithSections) {
			setSheetsWithSections(sheetsCount)
		}

		sheetsContentRef.current = {
			firstSheet: firstSheet.innerHTML,
			secondSheet: secondSheet.innerHTML,
			thirdSheet: thirdSheet.innerHTML,
		}
	}

	const addElement = () => {
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!firstSheet) return

		const newElement = document.createElement('section')
		newElement.style.backgroundColor = '#f00'
		newElement.style.height = '100px'
		newElement.style.width = '100%'
		newElement.style.marginTop = '16px'
		newElement.style.borderRadius = '8px'
		const pageToHandle = sheetsWithSections === 1 ? firstSheet : sheetsWithSections === 2 ? secondSheet : thirdSheet
		pageToHandle?.querySelector('main')?.appendChild(newElement)
		handleFlyerPages()
	}

	const removeElement = () => {
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!firstSheet) return

		const pageToHandle = sheetsWithSections === 1 ? firstSheet : sheetsWithSections === 2 ? secondSheet : thirdSheet
		const pageSections = pageToHandle?.querySelectorAll('section')
		const lastSection = pageSections?.[pageSections.length - 1]
		lastSection?.remove()
		handleFlyerPages()
	}

	const handleFlyerDrag = (event: React.MouseEvent) => {
		if (!activeSheet) return

		const startY = event.clientY
		const initialTransform = activeSheet.style.transform
		const initialTranslateY = initialTransform.includes('translateY') ? parseFloat(initialTransform.split('translateY(')[1].split('%')[0]) : 0

		const onMouseMove = (moveEvent: MouseEvent) => {
			const deltaY = moveEvent.clientY - startY
			const sheetScale = activeSheet.style.scale
			const sheetHeight = activeSheet.offsetHeight * parseFloat(sheetScale)
			const sheetParentHeight = activeSheet.parentNode ? (activeSheet.parentNode as HTMLElement).offsetHeight : 0

			if (sheetParentHeight && sheetHeight > sheetParentHeight) {
				const sheetOverflowHeight = (sheetHeight - sheetParentHeight + 80) / 2
				let newTranslateY = initialTranslateY + (deltaY / activeSheet.offsetHeight) * 100
				const maxTranslate = (sheetOverflowHeight / sheetHeight) * 100
				const minTranslate = -((sheetOverflowHeight / sheetHeight) * 100)
				newTranslateY = Math.max(minTranslate, Math.min(maxTranslate, newTranslateY))
				activeSheet.style.transform = `translateY(${newTranslateY}%)`
			}
		}

		const onMouseUp = () => {
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('mouseup', onMouseUp)
		}

		window.addEventListener('mousemove', onMouseMove)
		window.addEventListener('mouseup', onMouseUp)
	}

	const handleFlyerZoom = (handler: string) => {
		if (!activeSheet) return

		const currentScale = activeSheet.style.scale
		const currentScaleValue = currentScale ? parseFloat(currentScale) : 1
		const newScaleValue = handler === 'plus' ? currentScaleValue + 0.1 : currentScaleValue - 0.1

		applyScale(newScaleValue)
		handleFlyerPages()
	}

	const changeActiveSheet = (direction: string) => {
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!firstSheet || !secondSheet || !thirdSheet) return

		if (direction === 'next') {
			if (activeSheet?.getAttribute('id') === 'first-flyer-page') {
				setActiveSheet(secondSheet)
				return
			}
			if (activeSheet?.getAttribute('id') === 'second-flyer-page') {
				setActiveSheet(thirdSheet)
				return
			}
		}
		if (direction === 'prev') {
			if (activeSheet?.getAttribute('id') === 'second-flyer-page') {
				setActiveSheet(firstSheet)
				return
			}
			if (activeSheet?.getAttribute('id') === 'third-flyer-page') {
				setActiveSheet(secondSheet)
				return
			}
		}
	}

	useEffect(() => {
		const { firstSheet, secondSheet, thirdSheet } = getSheets()
		if (!firstSheet || !secondSheet || !thirdSheet) return
		setActiveSheet(firstSheet)
		calculateScale()
		handleFlyerPages()
		window.addEventListener('resize', calculateScale)
		return () => {
			window.removeEventListener('resize', calculateScale)
			if (activeSheet) {
				activeSheet.style.transform = 'translateY(0)'
				activeSheet.style.transition = 'all .250s ease-in-out'
				setTimeout(() => {
					activeSheet.style.transition = ''
				}, 300)
			}
			secondSheet.style.transform = 'translateY(150%)'
			thirdSheet.style.transform = 'translateY(150%)'
		}
	}, [])

	useEffect(() => {
		if (activeSheet) {
			activeSheet.style.transform = 'translateY(0)'
			activeSheet.style.transition = 'all .250s ease-in-out'
			const { firstSheet, secondSheet, thirdSheet } = getSheets()
			if (!firstSheet || !secondSheet || !thirdSheet) return
			if (activeSheet?.getAttribute('id') === 'first-flyer-page') {
				secondSheet.style.transform = 'translateY(150%)'
				secondSheet.style.transition = 'all .250s ease-in-out'
				thirdSheet.style.transform = 'translateY(150%)'
				thirdSheet.style.transition = 'all .250s ease-in-out'
			}
			if (activeSheet?.getAttribute('id') === 'second-flyer-page') {
				firstSheet.style.transform = 'translateY(-150%)'
				firstSheet.style.transition = 'all .250s ease-in-out'
				thirdSheet.style.transform = 'translateY(150%)'
				thirdSheet.style.transition = 'all .250s ease-in-out'
			}
			if (activeSheet?.getAttribute('id') === 'third-flyer-page') {
				firstSheet.style.transform = 'translateY(-150%)'
				firstSheet.style.transition = 'all .250s ease-in-out'
				secondSheet.style.transform = 'translateY(-150%)'
				secondSheet.style.transition = 'all .250s ease-in-out'
			}
			setTimeout(() => {
				firstSheet.style.transition = ''
				secondSheet.style.transition = ''
				thirdSheet.style.transition = ''
			}, 300)
		}
	}, [activeSheet])

	useEffect(() => {
		if (activeSheet ? activeSheet.getAttribute('data-value')! >= sheetsWithSections.toString() : false) {
			changeActiveSheet('prev')
			return
		}
	}, [sheetsWithSections])

	return (
		<div ref={previewRef} id="flyer-wrapper" className="relative flex items-center justify-center flex-1 bg-grey border border-darker-grey overflow-hidden" onMouseDown={handleFlyerDrag}>
			<div className="absolute top-8 right-6 flex flex-col gap-4">
				<Button variant="zoomButton" onClick={() => handleFlyerZoom('plus')}>
					<FaPlus color="#000033" size={12} />
				</Button>
				<Button variant="zoomButton" onClick={() => handleFlyerZoom('minus')}>
					<FaMinus color="#000033" size={12} />
				</Button>
			</div>
			<div ref={firstSheetRef} data-value="1" id="first-flyer-page" className="absolute w-printing-sheet h-printing-sheet bg-white p-5 transition-scale" dangerouslySetInnerHTML={{ __html: sheetsContentRef.current.firstSheet }}></div>
			<div ref={secondSheetRef} data-value="2" id="second-flyer-page" className="absolute w-printing-sheet h-printing-sheet bg-white p-5 transition-scale" dangerouslySetInnerHTML={{ __html: sheetsContentRef.current.secondSheet }}></div>
			<div ref={thirdSheetRef} data-value="3" id="third-flyer-page" className="absolute w-printing-sheet h-printing-sheet bg-white p-5 transition-scale" dangerouslySetInnerHTML={{ __html: sheetsContentRef.current.thirdSheet }}></div>

			<div className="absolute right-0 mb-8" onClick={() => addElement()}>
				ADD {/* Needs sitecore key */}
			</div>
			<div className="absolute right-0 mt-8" onClick={() => removeElement()}>
				REMOVE {/* Needs sitecore key */}
			</div>

			{activeSheet !== null && (
				<div className="absolute bottom-8 right-6 flex flex-col items-center gap-4">
					<BsChevronDoubleUp className={`${isFirstItemActive ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} color="#000033" size={16} onClick={() => !isFirstItemActive && changeActiveSheet('prev')} />
					<div className="flex flex-col gap-2">
						<FlyerBuildetPagination isActive={activeSheet?.getAttribute('data-value') === '1'} onClick={() => setActiveSheet(firstSheetRef.current)}>
							1
						</FlyerBuildetPagination>
						{sheetsWithSections >= 2 && (
							<FlyerBuildetPagination isActive={activeSheet?.getAttribute('data-value') === '2'} onClick={() => setActiveSheet(secondSheetRef.current)}>
								2
							</FlyerBuildetPagination>
						)}
						{sheetsWithSections >= 3 && (
							<FlyerBuildetPagination isActive={activeSheet?.getAttribute('data-value') === '3'} onClick={() => setActiveSheet(thirdSheetRef.current)}>
								3
							</FlyerBuildetPagination>
						)}
					</div>
					<BsChevronDoubleDown className={`${isLastItemActive ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} color="#000033" size={16} onClick={() => !isLastItemActive && changeActiveSheet('next')} />
				</div>
			)}
		</div>
	)
}

export { FlyerBuilderPreview }
