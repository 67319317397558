export function logMethodCall(message: string, args?: any[]): void {
	if (onClient()) return
	console.debug(message, args)
}

export function logMethodDuration(methodName: string, duration: number): void {
	if (onClient()) return
	const alarm = duration > 150
	const message = `Method ${methodName} took ${duration.toFixed(2)} milliseconds to run`
	if (alarm) return console.warn(message)
}

function onClient() {
	return typeof window !== 'undefined'
}
