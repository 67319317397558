'use client'

import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import FallbackImage from '@public/images/alert.svg'
import Image from 'next/image'
import React, { ComponentProps } from 'react'

export type PictureProps = Omit<ComponentProps<typeof Image>, 'src'> & { image: string; basepath?: 'websphere-base' | 'websphere-images' }

/**
 * Load an image using the filename from the directory "public/images"
 * @param props next image props and image: the image name with extension as string (i.e. "alert.svg")
 */
export const DynamicPicture: React.FC<PictureProps> = (props) => {
	const environmentState = useEnvironmentState()
	let image = props.image

	const isValidUrl = (url: string) => {
		try {
			const validUrl = new URL(url)
			return validUrl.toString().length > 0
		} catch {
			return false
		}
	}

	if (props.basepath) {
		const base = props.basepath === 'websphere-base' ? 'WEBSPHERE_BASE_ENDPOINT' : 'WEBSPHERE_IMAGES_ENDPOINT'
		image = `${environmentState.getString(base, '')}/${props.image}`
	}

	try {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		if (!isValidUrl(image)) image = require(`@public/images/${props.image}`)?.default?.src
	} catch (e: any) {
		console.error(`Error loading image ${props.image}, ${e.message}`)
	}

	if (!image) {
		return (
			<div className="bg-gray-400 flex justify-center items-center text-white h-full">
				<Image unoptimized src={FallbackImage} {...props} className="opacity-60" alt="Alert image displyed instead of the correct one" />
			</div>
		)
	}

	return <Image unoptimized src={image} {...props} alt={props.alt} />
}
