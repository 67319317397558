'use client'

import { FaPlus } from 'react-icons/fa6'
import { Checkbox } from './Checkbox'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isIOption } from '@/features/search-cruise/lib/utils'
import { IOption, IOptionGroup } from '@/features/search-cruise/store/types'

export interface DropdownOptionProp {
	label?: string
	checkbox?: boolean
	id?: string
	name?: string
	parent?: string
}

interface DropdownFilterProps {
	label?: string
	leftIcon?: ReactElement
	dropdownItems?: IOption[] | IOptionGroup[]
	hasInput?: boolean
	negative?: boolean
	withActiveStatus?: boolean
	onClick?: () => void
	onChange?: (option: IOption) => void
}

export const DropdownFilter: React.FC<DropdownFilterProps> = ({ label = '', leftIcon, dropdownItems = [], hasInput = false, negative = false, withActiveStatus = true, onClick, onChange }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<string>('')
	const [selectedValuesLength, setSelectedValuesLength] = useState<number>(0)
	const containerRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (isOpen && inputRef.current) {
			inputRef.current.focus()
		}
	}, [isOpen])

	const handleCheckboxChange = useCallback(
		(option: IOption) => {
			onChange?.(option)
		},
		[onChange]
	)

	const openDropdown = useCallback(() => setIsOpen(true), [])

	const closeDropdown = useCallback((e: (EventTarget & Element) | null) => {
		const target = e
		if (containerRef.current && !containerRef.current.contains(target as Node)) {
			setIsOpen(false)
			setInputValue('')
		} else {
			inputRef?.current?.focus()
		}
	}, [])

	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		e.preventDefault()
	}, [])

	const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value)
	}, [])

	const renderDropdownItems = useMemo(() => {
		let checkedOptionIndex = 0
		const matchesSearch = (text: string | undefined): boolean => text?.toLowerCase().includes(inputValue.toLowerCase()) ?? false

		const renderCheckbox = (option: IOption): JSX.Element => {
			if (option.checked) checkedOptionIndex++
			setSelectedValuesLength(checkedOptionIndex)
			return <Checkbox key={option.value} name={option.value} label={option.label} checked={option.checked} onChange={() => handleCheckboxChange(option)} onMouseDown={handleMouseDown} disabled={option.disabled} />
		}

		const isVisibleOption = (option: IOption): boolean => !option.hidden && matchesSearch(option.label)

		const isVisibleOptionGroup = (group: IOptionGroup): boolean => group.options.some((subOption) => !subOption.hidden)

		const getMatchingSubOptions = (group: IOptionGroup): IOption[] => {
			const groupMatchesSearch = matchesSearch(group.groupName)
			return group.options.filter((subOption) => !subOption.hidden && (groupMatchesSearch || matchesSearch(subOption.label)))
		}

		const renderOptionGroup = (group: IOptionGroup): JSX.Element | null => {
			const matchingSubOptions = getMatchingSubOptions(group)

			if (matchingSubOptions.length === 0) return null

			return (
				<React.Fragment key={group.groupName}>
					<span className="text-msc-blue font-semibold px-4">{group.groupName}</span>
					{matchingSubOptions.map(renderCheckbox)}
				</React.Fragment>
			)
		}

		const renderOption = (option: IOption | IOptionGroup): JSX.Element | null => (isIOption(option) ? renderCheckbox(option) : renderOptionGroup(option))

		const visibleItems = dropdownItems.filter((option) => (isIOption(option) ? isVisibleOption(option) : isVisibleOptionGroup(option)))

		return visibleItems.map(renderOption)
	}, [dropdownItems, handleCheckboxChange, handleMouseDown, inputValue])

	return (
		<div className={`flex flex-col min-w-fit h-auto items-center relative ${onClick ? 'cursor-pointer' : ''}`} ref={containerRef} onClick={onClick}>
			<div className="w-full h-full">
				<div className={`flex gap-2 h-full rounded-sm border border-msc-blue py-1 px-2 ${negative ? 'bg-msc-blue' : 'bg-white'}`}>
					<div className={`flex justify-between items-center w-full text-sm font-medium focus:outline-none`} onBlur={dropdownItems.length ? (e) => closeDropdown(e.relatedTarget as Element | null) : undefined} onClick={(e) => (dropdownItems.length && !isOpen ? openDropdown() : closeDropdown(e.relatedTarget as Element | null))}>
						<div className="flex flex-1 items-center gap-2">
							<div className={`absolute pointer-events-none ${selectedValuesLength !== 0 ? 'text-secondary' : 'text-msc-blue'}`}>{leftIcon}</div>
							<div className="relative flex-1">
								<p className={`flex-1 text-sm min-h-5 ${negative ? 'text-white' : selectedValuesLength !== 0 ? 'text-secondary' : 'text-msc-blue'} ${isOpen && hasInput ? 'opacity-0' : ''} pl-5 transition-opacity`}>
									{label} {selectedValuesLength !== 0 && withActiveStatus && `(${selectedValuesLength})`}
								</p>
								{/* {!onClick && <input ref={inputRef} className={`absolute left-0 top-0 w-full text-gray-800 pl-5 bg-transparent appearance-none outline-none z-10 ${hasInput ? 'cursor-text' : 'cursor-pointer'}`} readOnly={!hasInput} value={inputValue} onChange={handleInputChange} />} */}
								{!onClick && <input ref={inputRef} className={`absolute left-0 top-0 w-full text-gray-800 pl-5 bg-transparent appearance-none outline-none z-10 cursor-pointer`} readOnly={!hasInput} value={inputValue} onChange={handleInputChange} />}
							</div>
						</div>
					</div>
					{dropdownItems.length > 0 && (
						<div className={`text-gray-300 flex items-center cursor-pointer ${isOpen ? 'rotate-45 pointer-events-none' : 'rotate-0'} transition-transform`} onClick={dropdownItems.length ? openDropdown : undefined}>
							<FaPlus className={`${negative ? 'text-white' : 'text-msc-blue'}`} />
						</div>
					)}
				</div>
			</div>
			{dropdownItems.length > 0 && isOpen && <div className="absolute overflow-y-scroll overflow-x-hidden left-0 top-100 bg-white shadow-lg min-w-56 max-h-96 z-30">{renderDropdownItems}</div>}
		</div>
	)
}
