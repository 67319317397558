'use client'

import { COOPERA_COOKIE } from '@/features/agent/model/cookies'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import CookieService from '@/utils/cookies/cookie-service'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { useRouter } from 'next/navigation'
import { Button } from '../uiComponents'

export const CooperaModalTemplate = () => {
	const modal = useModalState()
	const sitecoreStore = useSitecoreState((state) => state)
	const router = useRouter()
	const provideUrl = useUrlProvider()

	const body = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_COOPERA_CONTRACT', 'Proceed to Contract section for more details by clicking the button below.')
	const confirmButton = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_PROCEED_COOPERA', 'Proceed to Contract')
	const cancelButton = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-GENERIC-POPUP_CANCEL', 'Cancel')

	const handleConfirm = async () => {
		await CookieService.setTextCookie(COOPERA_COOKIE.name, COOPERA_COOKIE.defaultValue)
		router.push(provideUrl('ta-portal-view', { taportalIframeKey: 'MENU_COOPERA' }))
	}

	const handleCancel = async () => {
		await CookieService.setTextCookie(COOPERA_COOKIE.name, COOPERA_COOKIE.defaultValue)
		modal.close()
	}

	return (
		<div className="flex flex-col w-full gap-6">
			<p>{body}</p>
			<div className="flex gap-6">
				<Button className="w-full" variant="outlined" onClick={handleConfirm}>
					{confirmButton}
				</Button>
				<Button className="w-full" onClick={handleCancel}>
					{cancelButton}
				</Button>
			</div>
		</div>
	)
}
