import React from 'react'

interface FiltersIconInterface {
	color?: string
}

export const FiltersIcon = ({ color = '#FFFFFF' }: FiltersIconInterface) => {
	return (
		<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5 7.75C3.605 7.75 2.4425 8.71 2.105 10H0.5V11.5H2.105C2.4425 12.79 3.605 13.75 5 13.75C6.395 13.75 7.5575 12.79 7.895 11.5H15.5V10H7.895C7.5575 8.71 6.395 7.75 5 7.75ZM5 12.25C4.175 12.25 3.5 11.575 3.5 10.75C3.5 9.925 4.175 9.25 5 9.25C5.825 9.25 6.5 9.925 6.5 10.75C6.5 11.575 5.825 12.25 5 12.25ZM13.895 2.5C13.5575 1.21 12.395 0.25 11 0.25C9.605 0.25 8.4425 1.21 8.105 2.5H0.5V4H8.105C8.4425 5.29 9.605 6.25 11 6.25C12.395 6.25 13.5575 5.29 13.895 4H15.5V2.5H13.895ZM11 4.75C10.175 4.75 9.5 4.075 9.5 3.25C9.5 2.425 10.175 1.75 11 1.75C11.825 1.75 12.5 2.425 12.5 3.25C12.5 4.075 11.825 4.75 11 4.75Z" fill={color} />
		</svg>
	)
}
