'use client'

import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { useEffect, useRef } from 'react'

export interface FormProps {
	method?: 'GET' | 'POST'
	action: string
	sendMarket?: boolean
	params?: { [key: string]: any }
}

export const Form = ({ method = 'POST', action, sendMarket = false, params = {} }: FormProps) => {
	const ref = useRef<HTMLFormElement>(null)
	const market = useCurrentMarket()

	useEffect(() => {
		if (params && Object.keys(params).length > 0) ref.current?.submit()
	}, [params])

	return (
		<form className="hidden" method={method} action={action} ref={ref}>
			{Object.keys(params).map((key, index) => params && <input key={index} name={key} value={params[key]} readOnly />)}
			{sendMarket && (
				<div>
					<input name="storeId" value={market.storeId} readOnly />
					<input name="langId" value={market.language.langId} readOnly />
				</div>
			)}
		</form>
	)
}
