'use client'

import React, { useState } from 'react'
import { NavDropdown, NavDropdownBackground, NavMenuItem } from '@/components/atoms/_exporter'

interface NavItemsWrapperProps {
	navMenuItem: string
	headerLinks: []
	navMenuItemId?: any
}

const NavItemsWrapper = ({ navMenuItem, headerLinks, navMenuItemId }: NavItemsWrapperProps) => {
	const [isHovered, setIsHovered] = useState(false)

	const handleMouseEnter = () => setIsHovered(true)
	const handleMouseLeave = () => setIsHovered(false)

	return (
		<div className="relative inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<NavMenuItem navMenuItem={navMenuItem} href="#" />

			{isHovered && (
				<NavDropdownBackground>
					<NavDropdown headerLinks={headerLinks as any} navMenuItem={navMenuItem} navMenuItemId={navMenuItemId as any}></NavDropdown>
				</NavDropdownBackground>
			)}
		</div>
	)
}

export { NavItemsWrapper }
